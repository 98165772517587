import React, { Fragment, useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './menu.css'
import './menu.sass'

const Menu = ({ menuSections, history }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [actualsection, setactualsection] = useState(window.innerWidth)

  useEffect(() => {
    setactualsection(
      window.location.pathname
        .split('/')
        .slice(0, -1)
        .join('/') + '/'
    )

    var mql = window.matchMedia("(max-width: 768px)")
    mql.addListener(function(m) {
      if (m.matches) {
        window.location.reload()
      }
    })

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(
    () =>
      history.listen(() => {
        setactualsection(
          window.location.pathname
            .split('/')
            .slice(0, -1)
            .join('/') + '/'
        )
      }),
    []
  )

  const handleResize = () => setWidth(window.innerWidth)

  let positionSticky = width > 576 ? 'sticky' : '-webkit-sticky'

  window.location.pathname === '/menuforblog' &&
    document.getElementsByTagName('body')[0].classList.add('menuForBlog')

  window.location.pathname === '/menuforblog' &&
    document.getElementsByTagName('html')[0].classList.add('menuForBlog2')

  return (
    actualsection !== '/resource/library/' &&
    window.location.pathname != '/footerforblog' && (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
            crossorigin="anonymous"
          />
        </Helmet>
        {width > 768 ? (
          <nav
            id="desktopNav"
            className="d-flex align-items-center pl-5 pr-5 navbar-expand-lg"
            style={{
              height: '3.56vw',
              marginLeft: '10%',
              marginRight: '10%',
              background:
                'linear-gradient(to right, rgba(247,246,245,.8) 20%, white, rgba(247,246,245,.8) 80%)',
              left: 0,
              right: 0,
              position: 'absolute',
              zIndex: '99999999'
            }}
          >
            <Link
              to="/"
              target={
                window.location.pathname === '/menuforblog' ? '_blank' : ''
              }
            >
              <img
                className="desktopLogoHome"
                src={require('../../assets/img/logo-02.png')}
                alt="neuroelectrics-logo"
                style={{ width: '6vw' }}
              />
            </Link>
            <div className="navbar-collapse h-100">
              <ul className="navbar-nav w-100 h-100 flex-row">
                {menuSections.map((item, index) => {
                  return (
                    <li key={index} className="nav-item">
                      {item.path ? (
                        <Link
                          className="nav-link"
                          to={item.path}
                          target={
                            window.location.pathname === '/menuforblog'
                              ? '_blank'
                              : ''
                          }
                        >
                          <div className="navLinkFirstLevelNoDropBorder" />
                          <p className="m-0" style={{ fontSize: '100%' }}>
                            {item.title}
                          </p>{' '}
                          {/* FIRST LEVEL */}
                        </Link>
                      ) : (
                        <div
                          style={{ cursor: 'default' }}
                          className="nav-item dropdown"
                          key={index}
                          target={
                            window.location.pathname === '/menuforblog'
                              ? '_blank'
                              : ''
                          }
                        >
                          <div className="nav-link navLinkFirstLevel">
                            <p
                              className="m-0"
                              style={{
                                fontSize: '100%'
                              }}
                            >
                              {item.title}
                            </p>
                          </div>
                          {/* FIRST LEVEL */}
                          <div
                            className="dropdown-menu dropdownMenuFirstLevel"
                            id="desktopNav1Drop"
                          >
                            <div className="dropdownMenuFirstLevelBorder" />
                            {item.submenu.map((item, index) => {
                              return (
                                <li key={index}>
                                  {!item.submenu ? (
                                    <Link
                                      className="dropdown-item dropDownItemSecondLevel"
                                      to={item.path}
                                      to={{
                                        pathname: item.path,
                                        param: item.param && {
                                          fnirsmri: true
                                        }
                                      }}
                                      target={
                                        window.location.pathname ===
                                        '/menuforblog'
                                          ? '_blank'
                                          : ''
                                      }
                                    >
                                      <div className="dropDownItemSecondLevelChild dropDownItemSecondLevelChild1">
                                        <p
                                          className="m-0"
                                          style={{
                                            fontSize: '100%',
                                            fontWeight: 600
                                          }}
                                        >
                                          {item.title}
                                        </p>{' '}
                                        {/* SECOND LEVEL */}
                                      </div>
                                    </Link>
                                  ) : (
                                    <div className="dropdown-item dropDownItemSecondLevel">
                                      <div
                                        style={{
                                          cursor: 'default'
                                        }}
                                        id={`dropdown-${index}`}
                                        className="dropdown dropDownItemSecondLevelChild dropDownItemSecondLevelChild2 w-100 h-100"
                                        key={index}
                                        target={
                                          window.location.pathname ===
                                          '/menuforblog'
                                            ? '_blank'
                                            : ''
                                        }
                                      >
                                        <div className="secondLevelNavLink">
                                          <p
                                            className="m-0"
                                            style={{
                                              fontSize: '100%',
                                              fontWeight: 600
                                            }}
                                          >
                                            {item.title}
                                          </p>{' '}
                                          {/* SECOND LEVEL */}
                                        </div>
                                        <div className="dropdown-menu dropDownThirdLevel">
                                          {item.submenu &&
                                            item.submenu.map((item, index) => {
                                              return (
                                                <Link
                                                  to={item.path}
                                                  key={index}
                                                  className="thirdLevelItem dropDownItemThirdLevelChild"
                                                  target={
                                                    window.location.pathname ===
                                                    '/menuforblog'
                                                      ? '_blank'
                                                      : ''
                                                  }
                                                >
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      fontSize: '100%',
                                                      fontWeight: 600
                                                    }}
                                                  >
                                                    {item.title}
                                                  </p>
                                                </Link>
                                              )
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </nav>
        ) : (
          <div
            style={{
              left: 0,
              top: 0,
              width: '100%',
              padding: '3%',
              display: 'flex',
              position: 'sticky',
              zIndex: 99999,
              background:
                'linear-gradient(to right bottom, rgba(247,246,245,.99) , white)',
              boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.4)'
            }}
          >
            <div
              id="dl-menu"
              className="dl-menuwrapper d-flex align-items-center"
              style={{ zIndex: 9999 }}
            >
              <button className="dl-trigger">Open Menu</button>
              <ul
                className="dl-menu"
                style={
                  width.innerHeight < 700
                    ? {
                        top: '12vw',
                        zIndex: 9999,
                        overflow: 'scroll',
                        maxHeight: '80vh'
                      }
                    : { top: '12vw' }
                }
              >
                <div className="dropdownMenuFirstLevelBorder w-100"></div>

                {menuSections.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={!item.submenu ? item.path : '#'}
                      className="dl-title-link"
                      target={
                        window.location.pathname === '/menuforblog'
                          ? '_blank'
                          : ''
                      }
                    >
                      {item.title}
                    </Link>

                    {item.submenu && (
                      <ul className="dl-submenu">
                        {item.submenu.map((item, index) => (
                          <li key={index}>
                            <Link
                              to={!item.submenu ? item.path : '#'}
                              className="dl-title-link"
                              target={
                                window.location.pathname === '/menuforblog'
                                  ? '_blank'
                                  : ''
                              }
                            >
                              {item.title}
                            </Link>
                            {item.submenu && (
                              <ul className="dl-submenu">
                                {item.submenu.map((item, index) => (
                                  <li>
                                    <Link
                                      to={!item.submenu ? item.path : '#'}
                                      className="dl-title-link"
                                      target={
                                        window.location.pathname ===
                                        '/menuforblog'
                                          ? '_blank'
                                          : ''
                                      }
                                    >
                                      {item.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <Link
              to="/"
              className="d-flex align-items-center justify-content-end w-100"
              target={
                window.location.pathname === '/menuforblog' ? '_blank' : ''
              }
            >
              <img
                src={require('../../assets/img/logo-02.png')}
                alt="logo"
                style={
                  width < 575
                    ? { width: '50%' }
                    : width > 574 && width < 769
                    ? { width: '30%' }
                    : {}
                }
              />
            </Link>
          </div>
        )}
      </Fragment>
    )
  )
}

export default withRouter(Menu)
