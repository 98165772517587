import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isWebpSupported } from "react-image-webp/dist/utils";

import data from "../../../contents/manuals_menu.json";
import { downloadManuals } from "../../../api/api";

import styled from "styled-components";
import Loading from "../../../components/Loading";
import ActualPath from "../../../components/ActualPath";

const Button = styled.button`
  font-family: Open Sans;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  padding: 3%;
  margin: 1%;
  border-bottom: 1px solid grey;
  border-left: none;
  border-top: none;
  border-right: none;
  transition: ease-in-out opacity 0.2s;

  &:hover {
    opacity: 0.4;
  }

  &:focus {
    opacity: 0.6;
    outline: none;
  }
`;

const Manuals = ({ location }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => setWidth(window.innerWidth);

  const title =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "8vw",
        lineHeight: "initial",
        marginTop: 0,
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "4vw",
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            lineHeight: "initial",
            fontSize: "3vw",
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              lineHeight: "initial",
              fontSize: "2.3vw",
            }
            : {
              fontWeight: 500,
              lineHeight: "initial",
              fontSize: "2vw",
            };

  const subtitle =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "4.7vw",
        lineHeight: "initial",
        marginTop: 0,
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial",
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial",
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1.5vw",
              lineHeight: "initial",
            }
            : {
              fontWeight: 300,
              fontSize: "1.1vw",
              lineHeight: "initial",
            };

  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "initial",
        marginTop: 0,
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial",
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "1.3vw",
            lineHeight: "initial",
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial",
            }
            : {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial",
            };

  const smallbold =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "16px",
        textDecoration: "none",
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw",
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "1.5vw",
            textDecoration: "none",
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "1.2vw",
              textDecoration: "none",
              lineHeight: "initial",
            }
            : {
              fontSize: "1.2vw",
              fontWeight: 500,
              textDecoration: "none",
              lineHeight: "initial",
            };

  if (data !== undefined) {
    return (
      <div className={width < 994 ? "marginTop0" : "pt60px"}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>{data.title}</title>
            <meta name="twitter:title" content={data.title} />
            <meta property="og:title" content={data.title} />

            <meta name="description" content={data.description} />
            <meta name="twitter:description" content={data.description} />
            <meta property="og:description" content={data.description} />
            <link
              rel="canonical"
              href="https://neuroelectrics.com/resources/manuals"
            />
          </Helmet>
        </>

        <ActualPath
          resources
          levelOne={{ text: "Home", url: "/" }}
          levelTwo={{ text: "Resources", url: "/resources" }}
          actual={{ text: "Manuals" }}
        />

        <div
          style={
            width > 575 ? { padding: "4%", paddingTop: "0" } : { padding: "4%" }
          }
        >
          <Container
            fluid
            style={
              width < 575
                ? { padding: 0 }
                : { paddingLeft: "12vw", paddingRight: "12vw" }
            }
          >
            <h1
              style={title}
              className={width < 575 ? "m-0 pb-3" : "m-0 pb-5 pt-5"}
            >
              <span style={width < 575 ? { fontSize: "80%" } : {}}>
                {data.h1}
              </span>
            </h1>

            <h2
              style={{
                fontSize: "1.2rem",
                fontWeight: 200,
                margin: "-20px 0 30px 0",
              }}
            >
              {data.subtitle}
            </h2>

            {width > 575 && (
              <Container className="p-0" fluid style={{ position: "relative" }}>
                <img
                  style={{
                    height: "20px",
                  }}
                  className="d-flex w-100"
                  src={
                    isWebpSupported()
                      ? require("../../../assets/img/neurocience_.webp")
                      : require("../../../assets/img/neurocience_.png")
                  }
                  alt="neuroelectrics-asset-bar"
                />
              </Container>
            )}
            {data.manuals.map((item, index) => (

              <div
                key={index}
                style={{
                  background: "#fff",
                  padding: width < 575 ? "3%" : "3%",
                  position: "relative",
                  marginTop: index > 0 && width < 575 ? "5%" : "",
                  borderTop: width > 575 && "2px solid #f7f6f5",
                }}
              >
                <div
                  className={`d-flex ${width < 575 && "justify-content-between"
                    }`}
                >
                  <h2
                    style={{
                      color: "#333",
                      fontWeight: "600",
                      fontSize: width > 575 ? "2.1em" : "1.5em",
                      paddingBottom: "1%",
                      paddingTop: width < 575 && "2%",
                    }}
                    id={item.title}
                  >
                    {item.title}
                  </h2>
                </div>

                <Row className={width > 575 ? "" : ""}>
                  {item.items.map((item, index) => (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={width < 575 ? { padding: 0 } : { padding: 0 }}
                    >
                      {
                        `${item.type}` == "path"
                          ?
                          <Link
                            to={{
                              pathname: `manuals/${item.path}`,
                            }}
                          >
                            <Button
                              style={
                                width < 575
                                  ? {
                                    width: "100%",
                                    borderTop: "2px solid #f7f6f5",
                                    borderBottom: "none",
                                  }
                                  : {
                                    width: "80%",
                                    borderBottom: "none",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                  }
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/ico_files.svg").default
                                }
                                alt="resources-user-manuals"
                                style={{
                                  padding: "5%",
                                  width: width < 575 ? "20%" : "80px",
                                  height: width < 575 ? "20%" : "80px",
                                }}
                              />
                              <div className="d-flex flex-column align-items-center justify-content-start text-start">
                                <p
                                  style={width < 575 ? smallbold : subtitle}
                                  className="w-100 text-left m-0 pl-2"
                                >
                                  {item.title}
                                </p>
                                <p
                                  style={small}
                                  className="w-100 text-left m-0 pl-2"
                                >
                                  {width > 575 ? (
                                    <b>Neuroelectrics®</b>
                                  ) : (
                                    "Neuroelectrics®"
                                  )}
                                </p>
                              </div>

                            </Button>
                          </Link>
                          :
                          <Button
                            onClick={() => downloadManuals(item.path)}
                            style={
                              width < 575
                                ? {
                                  width: "100%",
                                  borderTop: "2px solid #f7f6f5",
                                  borderBottom: "none",
                                }
                                : {
                                  width: "80%",
                                  borderBottom: "none",
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/ico_download.svg").default
                              }
                              alt="resources-user-manuals"
                              style={{
                                padding: "1%",
                                width: width < 575 ? "20%" : "90px",
                                height: width < 575 ? "20%" : "90px",
                              }}
                            />
                            <div className="d-flex flex-column align-items-center justify-content-start text-start">
                              <p
                                style={width < 575 ? smallbold : subtitle}
                                className="w-100 text-left m-0 pl-2"
                              >
                                {item.title}
                              </p>
                              <p
                                style={small}
                                className="w-100 text-left m-0 pl-2"
                              >
                                {width > 575 ? (
                                  <b>Neuroelectrics®</b>
                                ) : (
                                  "Neuroelectrics®"
                                )}
                              </p>
                            </div>

                          </Button>

                      }

                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Container>
        </div>

      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Manuals;
