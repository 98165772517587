import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getWhoWeAre } from "../../../api/api";

import neuroelectrics_whoweare from "../../../contents/neuroelectrics_whoweare";

import ActualPath from "../../../components/ActualPath";
import Loading from "../../../components/Loading";

import "./whoweare.css";

const Whoweare = () => {

  const data = neuroelectrics_whoweare;
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.scroll(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const about = await getWhoWeAre();
    //     setData(about);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    // setData(neuroelectrics_whoweare);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  
  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  let responsiveHeight = "500px"
  width < 575 && (responsiveHeight = "300px")

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>Who we are, vision & mission | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Who we are, vision & mission | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Who we are, vision & mission | Neuroelectrics"
          />

          <meta
            name="description"
            content="Neuroelectrics® is a creative, high tech company offering an all-in-one, best in class non-invasive and high definition electrical brain stimulation technology for personalized brain therapy..."
          />
          <meta
            name="twitter:description"
            content="Neuroelectrics® is a creative, high tech company offering an all-in-one, best in class non-invasive and high definition electrical brain stimulation technology for personalized brain therapy..."
          />
          <meta
            property="og:description"
            content="Neuroelectrics® is a creative, high tech company offering an all-in-one, best in class non-invasive and high definition electrical brain stimulation technology for personalized brain therapy..."
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/neuroelectrics/whoweare"
          />
        </Helmet>
      </>

      {/* Video */}
      <>
        <Container fluid className="position-absolute viedoWrapperParent">
          <Row className="d-flex justify-content-center align-content-center flex-column position-relative videoWrapper">
            {width < 575 ? (
              <iframe
                src="https://www.youtube.com/embed/4Ci1o0Z6OPE?autoplay=1&controls=0&loop=1&playlist=4Ci1o0Z6OPE&rel=0&mute=1"
                frameborder="0"
                allow="autoplay"
                allowfullscreen
              ></iframe>
            ) : (
              <iframe
                src="https://www.youtube.com/embed/4Ci1o0Z6OPE?autoplay=1&controls=0&loop=1&playlist=4Ci1o0Z6OPE&rel=0&mute=1"
                frameborder="0"
                allow="autoplay"
                allowfullscreen
              ></iframe>
            )}
          </Row>
        </Container>
      </>

      <div
        style={{
          paddingTop: width < 575 ? "50%" : "30%",
          position: "relative",
          width: "100%"
        }}
      >
        {/* Actual Path */}
        <>
          <ActualPath
            levelOne={{ text: "Home", url: "/" }}
            actual={{ text: "Neuroelectrics / Who we are" }}
          />
        </>

        {/* About neuroelectrics */}
        <>
          <div
            className={width > 575 ? "p-5" : "p-3"}
            style={{ background: "#f7f6f5" }}
          >
            <Container>
              <h5 style={title}>{data.concept.title}</h5>
              <p style={subtitle}>{data.concept.subtitle}</p>
              <Row className="pt-2">
                <Col
                  className="d-flex flex-column align-items-center"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  <img
                    src={isWebpSupported() ? `/img/${newImg(data.concept.image.path)}` : `/img/${data.concept.image.path}`}
                    alt={data.concept.image.title}
                    className="w-100"
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  className="d-flex flex-column justify-content-center"
                  style={
                    width < 575 ? { paddingTop: "3%", paddingBottom: "3%" } : {}
                  }
                >
                  {data.concept.application.map(item => (
                    <div>
                      <h5 style={subtitle} className="pt-2 text-center">
                        <b>{item.title}</b>
                      </h5>
                      <p style={subtitle} className="text-center">
                        {item.content}
                      </p>
                    </div>
                  ))}
                </Col>
                <Col
                  className="d-flex flex-column align-items-center"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  <img
                    className="w-100"
                    src={isWebpSupported() ? `/img/${newImg(data.concept2.image.path)}` : `/img/${data.concept2.image.path}`}
                    alt={data.concept2.image.title}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>
        </>

        <div
          className={width > 575 ? "p-5 pt-0" : "p-3 pt-5"}
          style={{ background: "#fff" }}
        >
          <Container>
            <Row
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Col xs={12} sm={12} md={12} lg={6}>
                <h5 className="responsiveSmallTitle">
                  {data.science_and_technology.title}
                </h5>
                <h5 className="responsiveSubtitle">
                  {data.science_and_technology.subtitle}
                </h5>
                <h5
                  className="responsiveSmallSubtitle pt-5 pb-5"
                  style={{ fontSize: "120%" }}
                >
                  {data.final_text}
                </h5>
              </Col>

              <Col className="text-right h-100">
                <h4 className="responsiveSubtitle">
                  {data.science_and_technology.quote}
                </h4>
                <p style={small}>{data.science_and_technology.quote_author}</p>
              </Col>
            </Row>
            <Row className={width > 768 && "justify-content-around"}>
              {data.science_and_technology.items.map(item => (
                <Col
                  sm={3}
                  md={4}
                  lg={2}
                  xs={6}
                  className={width > 768 ? "p-0" : "p-3"}
                >
                  <div style={{ background: "white", height: "100%" }}>
                    <img
                      className="w-100"
                      src={isWebpSupported() ? `/img/${newImg(item.image)}` : `/img/${item.image}`}
                      alt={item.title}
                    />
                    <div className="p-3">
                      <p style={small}>{item.pretext}</p>
                      <h4 style={smallbold}>{item.text}</h4>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default Whoweare
