import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getSoftware } from "../../../api/api";

import resources from "../../../contents/resources";

import "./resources.css";

import Loading from "../../../components/Loading";
import ActualPath from "../../../components/ActualPath";

const Button = styled.button`
  font-family: Open Sans;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  padding: 3%;
  margin: 1%;
  border-bottom: 1px solid grey;
  border-left: none;
  border-top: none;
  border-right: none;
  transition: ease-in-out opacity 0.2s;

  &:hover {
    opacity: 0.4;
  }

  &:focus {
    opacity: 0.6;
    outline: none;
  }
`

const LicenseBox = styled.div`
  margin-top: 20px;
  height: 400px;
  overflow: auto;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
`

const Cancel = styled.button`
  color: white;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 1.333em !important;
  border-radius: 25px;
  background-image: linear-gradient(to bottom right, #525258, #666666);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19);
  border: none;
  outline: none;
  padding: 0px 25px;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  height: 50px;

  &:hover {
    text-decoration: none;
    background-image: linear-gradient(to bottom right, #525258, #666666);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19);
    opacity: 0.9;
  }
`

const Ok = styled.button`
  color: white;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 1.333em !important;
  border-radius: 25px;
  background-image: linear-gradient(to bottom right, #7a92a4, #769cbf);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19);
  border: none;
  outline: none;
  padding: 0px 25px;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  height: 50px;

  &:hover {
    text-decoration: none;
    background-image: linear-gradient(to bottom right, #7a92a4, #769cbf);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19);
    opacity: 0.9;
  }
`

const Software = () => {

  const data = resources;
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    
    // async function fetchMyAPI() {
    //   try {
    //     const resources = await getSoftware();
    //     setData(resources);             
    //   }  catch (error) {
    //     console.log(error);
    //   }        
    // }    
    // fetchMyAPI();


    // setData(resources);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  
  const handleResize = () => setWidth(window.innerWidth)

  const [stepOne, setStepOne] = useState()
  const [stepTwo, setStepTwo] = useState("boxx hide")
  const [stepTwoTitle, setStepTwoTitle] = useState()
  const [stepThree, setStepThree] = useState("hide boxx")
  const [stepForm, setStepForm] = useState("hide boxx")

  const downloadAgreement = (item, index) => {
    setStepTwoTitle(item.file)
    if (item.agreement){
      index === 0 && (setStepOne("boxx hide") || setStepTwo("boxx"))
      index === 1 && downloadSoftware(item.name)
    }else{
      downloadSoftware(item.file)
    }
  }
  const downloadSoftware = (filename) => {
    window.open(`${process.env.REACT_APP_API_URL}/downloads/${filename}`)
  }

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  if (data !== undefined) {
    let responsiveWidth2 = "363px"
    let responsiveWidth = "750px"
    width < 575 && (responsiveWidth = "100%") && (responsiveWidth2 = "100%")

    return (
      <div className={width < 994 ? "marginTop0" : "pt60px"}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>Software Downloads | Resources | Neuroelectrics</title>
            <meta
              name="twitter:title"
              content="Software Downloads | Resources | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Software Downloads | Resources | Neuroelectrics"
            />

            <meta
              name="description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              name="twitter:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              property="og:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <link
              rel="canonical"
              href="https://neuroelectrics.com/resources/software"
            />
          </Helmet>
        </>

        <ActualPath
          resources
          levelOne={{ text: "Home", url: "/" }}
          levelTwo={{ text: "Resources", url: "/resources" }}
          actual={{ text: "Software" }}
        />

        <div
          style={
            width > 575 ? { padding: "4%", paddingTop: "0" } : { padding: "4%" }
          }
        >
          <Container
            fluid
            style={
              width < 575
                ? { padding: 0 }
                : { paddingLeft: "12vw", paddingRight: "12vw" }
            }
          >
            <ResearchAgreements
              className={stepTwo}
              title={stepTwoTitle}
              onClickOk={() => setStepThree("boxx") || setStepTwo("boxx hide")}
              onClickCancel={() =>
                setStepOne("boxx") || setStepTwo("boxx hide")
              }
            />

            <ClinicalAgreements
              className={stepThree}
              title={stepTwoTitle}
              onClickOk={() => {
                setStepTwo("boxx hide")
                setStepThree("boxx hide")
                setStepOne("boxx")
                downloadSoftware(stepTwoTitle)
              }}
              onClickCancel={() =>
                setStepTwo("boxx") || setStepThree("boxx hide")
              }
            />

            <ResourcesForm
              className={stepForm}
              onClickOk={() =>
                setStepOne("boxx hide") ||
                setStepTwo("boxx") ||
                setStepForm("boxx hide")
              }
              onClickCancel={() =>
                setStepOne("boxx") ||
                setStepTwo("boxx hide") ||
                setStepForm("boxx hide")
              }
            />
          </Container>

          <Container
            className={stepOne}
            style={width < 575 ? { padding: 0 } : {}}
          >
            <h1
              style={title}
              className={width < 575 ? "m-0 pb-3" : "m-0 pb-5 pt-5"}
            >
              <span style={width < 575 ? { fontSize: "80%" } : {}}>
                {data.software.title}
              </span>
            </h1>

            {width > 575 && (
              <Container className="p-0" fluid style={{ position: "relative" }}>
                <img
                  style={{
                    height: "20px"
                  }}
                  className="d-flex w-100"
                  src={isWebpSupported() ? require("../../../assets/img/neurocience_.webp") : require("../../../assets/img/neurocience_.png")}
                  alt="neuroelectrics-asset-bar"
                />
              </Container>
            )}
            {data.software.categories.map((item, index) => (
              <div
                key={index}
                style={{
                  background: "#fff",
                  padding: width < 575 ? "3%" : "3%",
                  position: "relative",
                  marginTop: index > 0 && width < 575 ? "5%" : "",
                  borderTop: width > 575 && "2px solid #f7f6f5"
                }}
              >
                {width < 575 && (
                  <Container
                    className="p-0"
                    fluid
                    style={{ position: "absolute", top: 0, left: 0, right: 0 }}
                  >
                    {width > 575 ? (
                      <img
                        style={{ height: "20px" }}
                        className="d-flex w-100"
                        src={isWebpSupported() ? require("../../../assets/img/neurocience_.webp") : require("../../../assets/img/neurocience_.png")}
                        alt="neuroelectrics-asset-bar"
                      />
                    ) : (
                      <div
                        style={{
                          borderBottom: "3px solid #555",
                          height: "1px"
                        }}
                      ></div>
                    )}
                  </Container>
                )}

                <h2
                  id={item.title}
                  style={{
                    color: "#333",
                    fontWeight: "600",
                    fontSize: width > 575 ? "2.1em" : "1.5em",
                    paddingBottom: "1%",
                    paddingTop: width < 575 && "2%"
                  }}
                >
                  {item.title}
                </h2>
                <Row className={width > 575 ? "" : ""}>
                  {item.items.map((item, i) => (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={width < 575 ? { padding: 0 } : { padding: 0 }}
                    >
                      <Button
                        style={
                          width < 575
                            ? {
                                width: "100%",
                                borderTop: "2px solid #f7f6f5",
                                borderBottom: "none"
                              }
                            : {
                                width: "80%",
                                borderBottom: "none",
                                paddingTop: 0,
                                paddingBottom: 0
                              }
                        }
                        
                        onClick={
                          () => downloadAgreement(item, 0)
                        }
                      >
                        <img
                          src={require("../../../assets/img/ico_download.svg").default}
                          alt="resources-software"
                          style={{
                            padding: "1%",
                            width: width < 575 ? "20%" : "90px",
                            height: width < 575 ? "20%" : "90px"
                          }}
                        />

                        <div className="d-flex flex-column align-items-center justify-content-start text-start">
                          <p
                            style={width < 575 ? smallbold : subtitle}
                            className="w-100 text-left m-0 pl-2"
                          >
                            {item.title}
                          </p>
                          <p style={small} className="w-100 text-left m-0 pl-2">
                            {width > 575 ? (
                              <b>Neuroelectrics ®</b>
                            ) : (
                              "Neuroelectrics ®"
                            )}
                          </p>
                        </div>
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Container>
        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

const ClinicalAgreements = ({
  onClickOk,
  onClickCancel,
  className,
  title,
  width
}) => {
  return (
    <div className={className} style={{ padding: "5%" }}>
      <h3 className="responsiveSubtitle">You are downloading:</h3>
      <p
        className="responsiveSmallSubtitle m-0"
        style={{ textDecoration: "underline" }}
      >
        <b>{title}</b>
      </p>
      <span style={{ textDecoration: "none" }}> Neuroelectrics®</span>
      <h2 className="responsiveSmallSubtitle mt-3">
        Accept the Terms &amp; Conditions of the NIC license for{" "}
        <u>Clinical Use</u>
      </h2>

      <LicenseBox className="softwareAgreements">
        <p>
          <strong>
            Neuroelectrics® Barcelona, SLU. Software License Agreement
          </strong>
        </p>

        <p>
          <strong>
            THIS IS A LEGAL AGREEMENT BETWEEN YOU AND NEUROELECTRICS® BARCELONA,
            SLU. CAREFULLY READ ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT
            BEFORE USING OR DOWNLOADING THIS SOFTWARE. BY DOWNLOADING,
            INSTALLING OR USING THIS SOFTWARE, YOU ACCEPT AND AGREE TO THE TERMS
            AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE WITH THESE
            TERMS AND CONDITIONS, DO NOT DOWNLOAD, INSTALL OR USE THIS SOFTWARE.
          </strong>
        </p>

        <p>
          <strong>1. Definitions</strong>: Capitalized terms not defined in
          context shall have the meaning assigned to them below:
        </p>

        <p>“Agreement” means this License Agreement.</p>

        <p>
          “License” means non-exclusive, non-transferable and non-sublicensable
          license to access and use the Software within the parameters of the
          terms and conditions applicable to the Product, granted by
          Neuroelectrics® to Licensee.
        </p>

        <p>
          “Licensee” or “You” means the person or entity who downloads or by any
          other mean obtains, installs or uses the Software.
        </p>

        <p>“Neuroelectrics®” means Neuroelectrics® Barcelona, SLU.</p>

        <p>
          “Open Source Software” means third party software, which may be
          available without charge for use, modification or distribution and
          generally licensed under the GNU GPL, LGP, BSD, MIT, Apache or other
          open source software license.
        </p>

        <p>
          “Product” means the device or devices designed, developed and
          manufactured by Neuroelectrics® to be used with the Software (e.g. EEG
          recording systems devices, transcranial current stimulators, cap
          systems, electrodes systems and others).
        </p>

        <p>
          “Software” means the object code version of the software that is
          provided or made available to the Licensee by downloading (or any
          other means) as well as any corrections, updates, enhancements and
          upgrades to such software that are made available to the Licensee from
          time to time pursuant to this Agreement, and all the copies of the
          foregoing.
        </p>

        <p>
          <strong>2. License</strong>: Subject to the terms of this Agreement,
          Neuroelectrics® grants to Licensee, and Licensee accepts from
          Neuroelectrics®, a non-exclusive, non-transferable and
          non-sublicensable license to access and use the Software within the
          parameters of the terms and conditions applicable to the Product (the
          “License”). Licensee shall only use the Software in relation to the
          Products.
        </p>

        <p>
          <strong>3. Restrictions</strong>: Licensee may not reverse engineer,
          decompile, dissemble, or attempt to discover or modify in any way the
          underlying source code of the Software, or any part thereof. In
          addition, Licensee may not: (i) modify, translate, localize, adapt,
          rent, lease, loan, create or prepare derivative works or products of,
          or create a patent based on the Software and/or the Product; (ii)
          resell, sublicense or distribute the Software or the Product; (iii)
          provide, make available to, or permit the use of the Software, in
          whole or in part, by any third party without Neuroelectrics® prior
          written consent; (iv) use the Software or the Product to create or
          enhance a competitive offering or for any other purpose which is
          competitive to Neuroelectrics®; (v) perform or fail to perform any act
          which could result in a misappropriation or infringement of
          Neuroelectric’s intellectual or industrial property rights in the
          Products or the Software. Each permitted copy of the Software made by
          the Licensee hereunder must contain all titles, trademarks, copyrights
          and restricted rights notices as in the original. Licensee may not use
          any license keys or other license access devices not provided by
          Neuroelectrics®, including but not limited to “pirate keys”, to
          install or access the Software.
        </p>

        <p>
          <strong>4. Copyright</strong>: Licensee acknowledges and agrees that:
          (i) the Products and the Software are protected by copyright,
          industrial property rights and other intellectual and industrial
          property regulations; (ii) Neuroelectrics® and/or its suppliers own
          the copyright, as well as other intellectual and industrial property
          rights in the Products and Software; (iii) the Software is licensed,
          and not sold; (iv) Neuroelectrics® reserves any and all rights,
          implied or otherwise, which are not expressly granted to Licensee in
          this Agreement.
        </p>

        <p>
          Licensee also acknowledges that some of the licensed Software includes
          Open Source Software. Licensee can access a complete list of these
          licenses for the Open Source Software provided with the
          Neuroelectrics®’ Software. It is the responsibility of the Licensee to
          review and adhere to all licenses for Open Source Software. By
          accepting this Agreement the Licensee is also accepting the terms and
          conditions of the licenses applicable to any third party software,
          including any Open Source Software, included with the Neuroelectrics®’
          Software. If the Software licensed by Neuroelectrics® includes certain
          software licensed under the GNU General Public License or other
          similar Open Source Software with a license that requires the licensor
          to make the source code publicly available (“GPL Software”) and the
          applicable source code was not included in the Software, then the
          Licensee may obtain a copy of the applicable source code for the GPL
          Software by either (a) requesting the open source code be mailed by
          Neuroelectrics® or (b) downloading the open source code by following
          the website link referred in this paragraph.
        </p>

        <p>
          <strong>5. Breach of Third Parties’ Rights</strong>: In case that, in
          the opinion of Neuroelectrics®, the Software could be breaching any
          kind of rights of third parties, Neuroelectrics® may, at its own
          discretion: (i) procure for the Licensee the right to use the
          Software; (ii) replace, without additional cost, the Software with
          other software that presents the same capabilities and
          functionalities; (iii) modify or correct the Software in the measure
          necessary in order to exclude the breach of the rights of third
          parties. If none of the aforementioned solutions is commercially,
          technically, functionally or economically feasible, Neuroelectrics®
          will be able to terminate the Agreement in its totality of with
          respect to the part of the Software breaching the rights. Repair,
          replacement, or termination of the Agreement (at the option of
          Neuroelectrics®) is the exclusive remedy, if there is a defect.
        </p>

        <p>
          <strong>
            6. Limited Warranty (Disclaimer and Limitation of Liability)
          </strong>
          : Neuroelectrics® does not warrant any object codes contained in the
          Software furnished to it by third parties. In the event the Licensee
          wishes further information about object codes contained in the
          Software that are not created by Neuroelectrics® but which are used by
          Neuroelectrics® with permission of third parties, please contact
          Neuroelectrics®.
        </p>

        <p>
          Due to the complex nature of the Software and the devices that
          interact with it, Neuroelectrics® does not warrant that the Software
          is completely error free, will operate without interruption, or is
          compatible with all equipment and software configurations. The
          Licensee expressly assumes all risk for use of the Software.
        </p>

        <p>
          **NEUROELECTRICS® MAKES NO OTHER WARRANTIES EXPRESSED OR IMPLIED,
          RELATED TO THE LICENSED SOFTWARE (INCLUDING ASSOCIATED WRITTEN
          MATERIALS) OR THEIR FITNESS FOR ANY PARTICULAR PURPOSE. **
        </p>

        <p>
          <strong>
            THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
            "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
            FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
            NEUROELECTRICS®, THE COPYRIGHT OWNER, AUTHORS OR CONTRIBUTORS BE
            LIABLE FOR ANY CLAIM, DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
            PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR
            PROFITS; BUSINESS INTERRUPTION; PERSONAL INJURY) HOWEVER CAUSED AND
            ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY,
            OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT
            OF THE USE OF THIS SOFTWARE OR OTHER DEALINGS IN THE SOFTWARE, EVEN
            IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. IN NO EVENT SHALL THE
            LIABILITY OF NEUROELECTRICS® EXCEED THE AMOUNT PAID FOR THE LICENSED
            SOFTWARE.
          </strong>
        </p>

        <p>
          <a href="" />
          <strong>
            NEUROELECTRICS® IS NOT LIABLE AND LICENSEE RELEASES NEUROELECTRICS®
            FROM ALL LIABILITY DERIVED FROM ANY KIND OF MANIPULATIONS AND/OR
            CHANGES INTRODUCED BY THE LICENSEES IN THE LICENSED SOFTWARE.
          </strong>
        </p>

        <p>
          <strong>7. Term and Termination</strong>: This Agreement is effective
          until terminated. The termination of this Agreement may occur: (i) by
          mutual written agreement of Neuroelectrics® and the Licensee; (ii) by
          either party for a breach of this Agreement by the other party that
          the breaching party fails to cure to the non-breaching party’s
          reasonable satisfaction within thirty (30) days following its receipt
          of notice of the breach; (iii) by the Licensee by deleting the
          Software from all devices (e.g. computers, laptops, smartphones,
          tablets, etc.).
        </p>

        <p>
          Upon termination of this Agreement or expiration or termination of the
          License for any reason, all rights granted to Licensee for the
          Software shall immediately cease and Licensee shall immediately: (i)
          cease using the applicable Software; (ii) remove all copies,
          installations, and instances of the applicable Software from Licensee
          computers and any other devices on which the Software was installed;
          (iii) return the applicable Software to Neuroelectrics® together with
          all documentation and other materials associated with the Software and
          all copies of any of the foregoing, or destroy such items; (iv) pay
          Neuroelectrics® all amounts due and payable up to the date of
          termination; and (v) give Neuroelectrics® a written certification,
          within ten (10) days, that Licensee, have complied with all of the
          foregoing obligations.
        </p>

        <p>
          Any provision of this Agreement that requires or contemplates
          execution after the termination of this Agreement or a termination or
          expiration of a License, is enforceable against the other party and
          their respective successors and assignees notwithstanding such
          termination or expiration, including, without limitation, the
          Restrictions, Limited Warranty, Term and Termination and Governing Law
          and Venue. Termination of this Agreement or a License shall be without
          prejudice to any other remedies that the terminating party may have
          under law, subject to the limitations and exclusions set forth in this
          Agreement.
        </p>

        <p>
          <strong>8. General</strong>:
        </p>

        <p>
          (i)
          <strong>Governing Law and Venue</strong>: This Agreement shall be
          governed by and construed in accordance with the laws of Spain. Any
          action seeking enforcement of this Agreement or any provision hereof
          shall be brought exclusively in the courts of the city of Barcelona.
          Each party hereby agrees to submit to the jurisdiction of such courts.
        </p>

        <p>
          (ii)
          <strong>Assignment</strong>: Except as otherwise set forth herein,
          Licensee shall not, in whole or part, assign or transfer any part of
          this Agreement, the License granted under this Agreement or any other
          rights, interest or obligations hereunder, whether voluntarily, by
          contract, by operation of law or by merger (whether that party is the
          surviving or disappearing entity), stock or asset sale, consolidation,
          dissolution, through government action or order, or otherwise without
          the prior written consent of Neuroelectrics®.
        </p>

        <p>
          (iii)
          <strong>Severability</strong>: If any provision of this Agreement
          shall be held by a court of competent jurisdiction to be contrary to
          law, such provision will be enforced to the maximum extent permissible
          by law to effect the intent of the parties and the remaining
          provisions of this Agreement will remain in full force and effect.
          Notwithstanding the foregoing, the terms of this Agreement that limit,
          disclaim, or exclude warranties, remedies or damages are intended by
          the parties to be independent and remain in effect despite the failure
          or unenforceability of an agreed remedy. The parties have relied on
          the limitations and exclusions set forth in this Agreement in
          determining whether to enter into it.
        </p>

        <p>
          (iv)
          <strong>Waiver</strong>: Performance of any obligation required by a
          party hereunder may be waived only by a written waiver signed by an
          authorized representative of the other party, which waiver shall be
          effective only with respect to the specific obligation described
          therein. Any waiver or failure to enforce any provision of this
          Agreement on one occasion will not be deemed a waiver of any other
          provision or of such provision on any other occasion.
        </p>

        <p>
          (v)
          <strong>Headings</strong>: Headings in this Agreement are for
          convenience only and do not affect the meaning or interpretation of
          this Agreement. This Agreement will not be construed either in favor
          of or against one party or the other, but rather in accordance with
          its fair meaning. When the term “including” is used in this Agreement
          it will be construed in each case to mean “including, but not limited
          to.”
        </p>

        <p>
          (vi)
          <strong>Entire Agreement</strong>: This Agreement is intended by the
          parties as a final expression of their agreement with respect to the
          subject matter thereof and shall constitute the complete and exclusive
          statement of the terms and conditions and no extrinsic evidence
          whatsoever may be introduced in any judicial or arbitral proceeding
          that may involve the Agreement. Each party acknowledges that in
          entering into the Agreement it has not relied on, and shall have no
          right or remedy in respect of, any statement, representation,
          assurance or warranty (whether made negligently or innocently) other
          than as expressly set out in the Agreement.
        </p>
      </LicenseBox>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2%"
        }}
      >
        <Cancel onClick={onClickCancel}>Cancel</Cancel>
        <Ok onClick={onClickOk}>
          Accept{width > 575 && " & continue to download"}
        </Ok>
      </div>
    </div>
  )
}

const ResearchAgreements = ({
  onClickOk,
  onClickCancel,
  className,
  title,
  width
}) => {
  return (
    <div className={className} style={{ padding: "5%" }}>
      <h3 className="responsiveSubtitle">You are downloading:</h3>
      <p
        className="responsiveSmallSubtitle m-0"
        style={{ textDecoration: "underline" }}
      >
        <b>{title}</b>
      </p>
      <span style={{ textDecoration: "none" }}> Neuroelectrics®</span>
      <h3 className="responsiveSmallSubtitle mt-3">
        Accept the Terms &amp; Conditions of the NIC license for{" "}
        <u>Research Use</u>
      </h3>
      <LicenseBox className="softwareAgreements">
        <p>
          <strong>
            Neuroelectrics Barcelona, SLU. Software License Agreement
          </strong>
        </p>

        <p>
          <strong>
            THIS IS A LEGAL AGREEMENT BETWEEN YOU AND NEUROELECTRICS BARCELONA,
            SLU. CAREFULLY READ ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT
            BEFORE USING OR DOWNLOADING THIS SOFTWARE. BY DOWNLOADING,
            INSTALLING OR USING THIS SOFTWARE, YOU ACCEPT AND AGREE TO THE TERMS
            AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE WITH THESE
            TERMS AND CONDITIONS, DO NOT DOWNLOAD, INSTALL OR USE THIS SOFTWARE.
          </strong>
        </p>

        <p>
          <strong>1. Definitions</strong>: Capitalized terms not defined in
          context shall have the meaning assigned to them below:
        </p>

        <p>“Agreement” means this License Agreement.</p>

        <p>
          “License” means non-exclusive, non-transferable and non-sublicensable
          license to access and use the Software within the parameters of the
          terms and conditions applicable to the Product, granted by
          Neuroelectrics to Licensee.
        </p>

        <p>
          “Licensee” or “You” means the person or entity who downloads or by any
          other mean obtains, installs or uses the Software.
        </p>

        <p>“Neuroelectrics” means Neuroelectrics Barcelona, SLU.</p>

        <p>
          “Open Source Software” means third party software, which may be
          available without charge for use, modification or distribution and
          generally licensed under the GNU GPL, LGP, BSD, MIT, Apache or other
          open source software license.
        </p>

        <p>
          “Product” means the device or devices designed, developed and
          manufactured by Neuroelectrics to be used with the Software (e.g. EEG
          recording systems devices, transcranial current stimulators, cap
          systems, electrodes systems and others).
        </p>

        <p>
          “Software” means the object code version of the software (e.g. NIC
          2.0, NIC, NIC OFFLINE, NIC HOME, NEUROSURFER, MATNIC TOOLKIT) that is
          provided or made available to the Licensee by downloading (or any
          other means) as well as any corrections, updates, enhancements and
          upgrades to such software that are made available to the Licensee from
          time to time pursuant to this Agreement, and all the copies of the
          foregoing.
        </p>

        <p>
          <strong>2. License</strong>: Subject to the terms of this Agreement,
          Neuroelectrics grants to Licensee, and Licensee accepts from
          Neuroelectrics, a non-exclusive, non-transferable and
          non-sublicensable license to access and use the downloaded Software
          within the parameters of the terms and conditions applicable to the
          Product (the “License”). Licensee shall only use the Software in
          relation to the Products.
        </p>

        <p>
          <strong>3. Restrictions</strong>: With the exception of the MATNIC
          software, Licensee may not reverse engineer, decompile, dissemble, or
          attempt to discover or modify in any way the underlying source code of
          the Software, or any part thereof. In addition, Licensee may not: (i)
          with the exception of the MATNIC software, modify, translate,
          localize, adapt the Software; (ii) rent, lease, loan, create or
          prepare derivative works, or create a patent based on the Software
          and/or the Product; (iii) resell, sublicense or distribute the
          Software or the Product; (iv) provide, make available to, or permit
          the use of the Software, in whole or in part, by any third party
          without Neuroelectrics prior written consent; (v) use the Software or
          the Product to create or enhance a competitive offering or for any
          other purpose which is competitive to Neuroelectrics; (vi) perform or
          fail to perform any act which could result in a misappropriation or
          infringement of Neuroelectric’s intellectual or industrial property
          rights in the Products or the Software. Each permitted copy of the
          Software made by the Licensee hereunder must contain all titles,
          trademarks, copyrights and restricted rights notices as in the
          original. Licensee may not use any license keys or other license
          access devices not provided by Neuroelectrics, including but not
          limited to “pirate keys”, to install or access the Software.
        </p>

        <p>
          <strong>4. Copyright</strong>: Licensee acknowledges and agrees that:
          (i) the Products and the Software are protected by copyright,
          industrial property rights and other intellectual and industrial
          property regulations; (ii) Neuroelectrics and/or its suppliers own the
          copyright, as well as other intellectual and industrial property
          rights in the Products and Software; (iii) the Software is licensed,
          and not sold; (iv) Neuroelectrics reserves any and all rights, implied
          or otherwise, which are not expressly granted to Licensee in this
          Agreement.
        </p>

        <p>
          Licensee also acknowledges that some of the licensed Software includes
          Open Source Software. Licensee can access a complete list of these
          licenses for the Open Source Software provided with the
          Neuroelectrics’ Software. It is the responsibility of the Licensee to
          review and adhere to all licenses for Open Source Software. By
          accepting this Agreement the Licensee is also accepting the terms and
          conditions of the licenses applicable to any third party software,
          including any Open Source Software, included with the Neuroelectrics’
          Software. If the Software licensed by Neuroelectrics includes certain
          software licensed under the GNU General Public License or other
          similar Open Source Software with a license that requires the licensor
          to make the source code publicly available (“GPL Software”) and the
          applicable source code was not included in the Software, then the
          Licensee may obtain a copy of the applicable source code for the GPL
          Software by either (a) requesting the open source code be mailed by
          Neuroelectrics or (b) downloading the open source code by following
          the website link referred in this paragraph.
        </p>

        <p>
          <strong>5. Breach of Third Parties’ Rights</strong>: In case that, in
          the opinion of Neuroelectrics, the Software could be breaching any
          kind of rights of third parties, Neuroelectrics may, at its own
          discretion: (i) procure for the Licensee the right to use the
          Software; (ii) replace, without additional cost, the Software with
          other software that presents the same capabilities and
          functionalities; (iii) modify or correct the Software in the measure
          necessary in order to exclude the breach of the rights of third
          parties. If none of the aforementioned solutions is commercially,
          technically, functionally or economically feasible, Neuroelectrics
          will be able to terminate the Agreement in its totality of with
          respect to the part of the Software breaching the rights. Repair,
          replacement, or termination of the Agreement (at the option of
          Neuroelectrics) is the exclusive remedy, if there is a defect.
        </p>

        <p>
          <strong>
            6. Limited Warranty (Disclaimer and Limitation of Liability)
          </strong>
          : Neuroelectrics does not warrant any object codes contained in the
          Software furnished to it by third parties. In the event the Licensee
          wishes further information about object codes contained in the
          Software that are not created by Neuroelectrics but which are used by
          Neuroelectrics with permission of third parties, please contact
          Neuroelectrics.
        </p>

        <p>
          Due to the complex nature of the Software and the devices that
          interact with it, Neuroelectrics does not warrant that the Software is
          completely error free, will operate without interruption, or is
          compatible with all equipment and software configurations. The
          Licensee expressly assumes all risk for use of the Software,
          acknowledges to be the unique and exclusive responsible of the
          Software use and will maintain Neuroelectrics harmless and fully
          indemnify in case of any kind of claim.
        </p>

        <p>
          **NEUROELECTRICS MAKES NO OTHER WARRANTIES EXPRESSED OR IMPLIED,
          RELATED TO THE LICENSED SOFTWARE (INCLUDING ASSOCIATED WRITTEN
          MATERIALS) OR THEIR FITNESS FOR ANY PARTICULAR PURPOSE. **
        </p>

        <p>
          <strong>
            THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
            "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
            FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
            NEUROELECTRICS, THE COPYRIGHT OWNER, AUTHORS OR CONTRIBUTORS BE
            LIABLE FOR ANY CLAIM, DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
            PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR
            PROFITS; BUSINESS INTERRUPTION; PERSONAL INJURY) HOWEVER CAUSED AND
            ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY,
            OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT
            OF THE USE OF THIS SOFTWARE OR OTHER DEALINGS IN THE SOFTWARE, EVEN
            IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. IN NO EVENT SHALL THE
            LIABILITY OF NEUROELECTRICS EXCEED THE AMOUNT PAID FOR THE LICENSED
            SOFTWARE.
          </strong>
        </p>

        <p>
          <strong>
            NEUROELECTRICS IS NOT LIABLE AND LICENSEE RELEASES NEUROELECTRICS
            FROM ALL LIABILITY DERIVED FROM ANY KIND OF MANIPULATIONS AND/OR
            CHANGES INTRODUCED BY THE LICENSEES IN THE LICENSED SOFTWARE.
          </strong>
        </p>

        <p>
          <strong>7. Term and Termination</strong>: This Agreement is effective
          until terminated. The termination of this Agreement may occur: (i) by
          mutual written agreement of Neuroelectrics and the Licensee; (ii) by
          either party for a breach of this Agreement by the other party that
          the breaching party fails to cure to the non-breaching party’s
          reasonable satisfaction within thirty (30) days following its receipt
          of notice of the breach; (iii) by the Licensee by deleting the
          Software from all devices (e.g. computers, laptops, smartphones,
          tablets, etc.).
        </p>

        <p>
          Upon termination of this Agreement or expiration or termination of the
          License for any reason, all rights granted to Licensee for the
          Software shall immediately cease and Licensee shall immediately: (i)
          cease using the applicable Software; (ii) remove all copies,
          installations, and instances of the applicable Software from Licensee
          computers and any other devices on which the Software was installed;
          (iii) return the applicable Software to Neuroelectrics together with
          all documentation and other materials associated with the Software and
          all copies of any of the foregoing, or destroy such items; (iv) pay
          Neuroelectrics all amounts due and payable up to the date of
          termination; and (v) give Neuroelectrics a written certification,
          within ten (10) days, that Licensee, have complied with all of the
          foregoing obligations.
        </p>

        <p>
          Any provision of this Agreement that requires or contemplates
          execution after the termination of this Agreement or a termination or
          expiration of a License, is enforceable against the other party and
          their respective successors and assignees notwithstanding such
          termination or expiration, including, without limitation, the
          Restrictions, Limited Warranty, Term and Termination and Governing Law
          and Venue. Termination of this Agreement or a License shall be without
          prejudice to any other remedies that the terminating party may have
          under law, subject to the limitations and exclusions set forth in this
          Agreement.
        </p>

        <p>
          <strong>8. General</strong>:
        </p>

        <p>
          (i)
          <strong>Governing Law and Venue</strong>: This Agreement shall be
          governed by and construed in accordance with the laws of Spain. Any
          action seeking enforcement of this Agreement or any provision hereof
          shall be brought exclusively in the courts of the city of Barcelona.
          Each party hereby agrees to submit to the jurisdiction of such courts.
        </p>

        <p>
          (ii)
          <strong>Assignment</strong>: Except as otherwise set forth herein,
          Licensee shall not, in whole or part, assign or transfer any part of
          this Agreement, the License granted under this Agreement or any other
          rights, interest or obligations hereunder, whether voluntarily, by
          contract, by operation of law or by merger (whether that party is the
          surviving or disappearing entity), stock or asset sale, consolidation,
          dissolution, through government action or order, or otherwise without
          the prior written consent of Neuroelectrics.
        </p>

        <p>
          (iii)
          <strong>Severability</strong>: If any provision of this Agreement
          shall be held by a court of competent jurisdiction to be contrary to
          law, such provision will be enforced to the maximum extent permissible
          by law to effect the intent of the parties and the remaining
          provisions of this Agreement will remain in full force and effect.
          Notwithstanding the foregoing, the terms of this Agreement that limit,
          disclaim, or exclude warranties, remedies or damages are intended by
          the parties to be independent and remain in effect despite the failure
          or unenforceability of an agreed remedy. The parties have relied on
          the limitations and exclusions set forth in this Agreement in
          determining whether to enter into it.
        </p>

        <p>
          (iv)
          <strong>Waiver</strong>: Performance of any obligation required by a
          party hereunder may be waived only by a written waiver signed by an
          authorized representative of the other party, which waiver shall be
          effective only with respect to the specific obligation described
          therein. Any waiver or failure to enforce any provision of this
          Agreement on one occasion will not be deemed a waiver of any other
          provision or of such provision on any other occasion.
        </p>

        <p>
          (v)
          <strong>Headings</strong>: Headings in this Agreement are for
          convenience only and do not affect the meaning or interpretation of
          this Agreement. This Agreement will not be construed either in favor
          of or against one party or the other, but rather in accordance with
          its fair meaning. When the term “including” is used in this Agreement
          it will be construed in each case to mean “including, but not limited
          to.”
        </p>

        <p>
          (vi)
          <strong>Entire Agreement</strong>: This Agreement is intended by the
          parties as a final expression of their agreement with respect to the
          subject matter thereof and shall constitute the complete and exclusive
          statement of the terms and conditions and no extrinsic evidence
          whatsoever may be introduced in any judicial or arbitral proceeding
          that may involve the Agreement. Each party acknowledges that in
          entering into the Agreement it has not relied on, and shall have no
          right or remedy in respect of, any statement, representation,
          assurance or warranty (whether made negligently or innocently) other
          than as expressly set out in the Agreement.
        </p>
      </LicenseBox>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2%"
        }}
      >
        <Cancel onClick={onClickCancel}>Cancel</Cancel>
        <Ok onClick={onClickOk}>
          Accept{width > 575 && " & continue to download"}
        </Ok>
      </div>
    </div>
  )
}

const ResourcesForm = ({
  onClickOk,
  onClickCancel,
  className,
  title,
  width
}) => {
  return (
    <div className={className}>
      First name:
      <br />
      <input type="text" name="firstname" />
      <br />
      Last name:
      <br />
      <input type="text" name="lastname" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2%"
        }}
      >
        <Cancel onClick={onClickCancel}>Cancel</Cancel>
        <Ok onClick={onClickOk}>
          Accept{width > 575 && " & continue to download"}
        </Ok>
      </div>
    </div>
  )
}

export default Software
