import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import './components.css'

const Gallery = ({ data, referedTo, title, subtitle, smallbold, small }) => {
  const [currentIndex, setIndex] = useState(0)
  const slideNext = () =>
    currentIndex < data.list.length - 1 && setIndex(currentIndex + 1)
  const slidePrev = () => currentIndex > 0 && setIndex(currentIndex - 1)

  const thumbItem = (item, i) => <span onClick={() => slideTo(i)}>* </span>

  const slideTo = i => setIndex(i)

  const [rightOpacity, setRightOpacity] = useState('1')
  const [leftOpacity, setLeftOpacity] = useState('1')

  const updateIndex = () => {
    if (currentIndex === 0) {
      setLeftOpacity('.3')
    } else {
      setLeftOpacity('1')
    }

    if (currentIndex === data.list.length - 1) {
      setRightOpacity('.3')
    } else {
      setRightOpacity('1')
    }
  }

  useEffect(() => {
    updateIndex()
  }, [currentIndex])

  const handleOnDragStart = e => e.preventDefault()

  if (data.list != undefined && data.list.length > 0) {
    return (
      <div style={{ position: 'relative' }}>
        {data.category && (
          <h3 style={title} className='text-center mt-4 mb-4'>
            {data.category}
          </h3>
        )}
        <AliceCarousel
          swipeDisabled={true}
          onSlideChanged={updateIndex}
          dotsDisabled={true}
          buttonsDisabled={true}
          infinite={false}
          slideToIndex={currentIndex}
          mouseDragEnabled
        >
          {data.list.map(item => (
            <Link
              className='tile fade-in box'
              style={{ marginLeft: '9%', marginRight: '9%' }}
              to={
                referedTo === 'component'
                  ? `/solution/spareparts-consumables/${item.item_preview.url_param_ref}`
                  : `/solutions/${item.seo_url_family}/${item.seo_url_product}`
              }
            >
              <img
                src={`/img/${referedTo}/${item.item_preview.image.path}`}
                alt={item.item_preview.image.path}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: '0 0'
                }}
              />
              <div className='p-3'>
                <p style={smallbold}>{item.item_preview.title}</p>
                <p style={small}>{item.item_preview.desc}</p>
              </div>
            </Link>
          ))}
        </AliceCarousel>
        <nav style={{ display: 'none' }}>{data.list.map(thumbItem)}</nav>
        <button
          style={{
            outline: 'none',
            width: '10vw',
            border: 'none',
            background: 'none',
            position: 'absolute',
            top: 0,
            left: '-1vw',
            padding: 0,
            height: '100%'
          }}
          onClick={slidePrev}
        >
          <img
            style={{ width: '100%', opacity: leftOpacity }}
            src='/img/left-arrow.png'
            alt='left-arrow.png'
          />
        </button>
        <button
          style={{
            outline: 'none',
            width: '10vw',
            border: 'none',
            background: 'none',
            position: 'absolute',
            top: 0,
            right: '-1vw',
            padding: 0,
            height: '100%'
          }}
          onClick={slideNext}
        >
          <img
            style={{ width: '100%', opacity: rightOpacity }}
            src='/img/right-arrow.png'
            alt='right-arrow.png'
          />
        </button>
      </div>
    )
  } else {
    return ''
  }
}

export default Gallery
