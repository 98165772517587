import React, { useEffect, useState } from "react"
import Form from "../../components/Form"
import ActualPath from "../../components/ActualPath"
import { Container, Col, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

const Contact = ({ location }) => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const title =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "8vw",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "3vw"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "2.3vw"
            }
            : {
              fontWeight: 500,
              fontSize: "2vw"
            }

  const subtitle =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "4.7vw",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1.5vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1.1vw",
              lineHeight: "initial"
            }

  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "1.3vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }

  const smallbold =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "16px",
        textDecoration: "none"
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "1.5vw",
            textDecoration: "none"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "1.2vw",
              textDecoration: "none",
              lineHeight: "initial"
            }
            : {
              fontSize: "1.2vw",
              fontWeight: 500,
              textDecoration: "none",
              lineHeight: "initial"
            }

  return (
    <div style={width < 769 ? {} : { paddingTop: "3.6vw" }}>
      {/* Helmet */}
      <>
        <Helmet>
          <title>Contact us. Europe +34932540366 | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Contact us. Europe +34932540366 | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Contact us. Europe +34932540366 | Neuroelectrics"
          />

          <meta
            name="description"
            content="Contact us and Neuroelectrics® team will get in touch as soon as we can!"
          />
          <meta
            name="twitter:description"
            content="Contact us and Neuroelectrics® team will get in touch as soon as we can!"
          />
          <meta
            property="og:description"
            content="Contact us and Neuroelectrics® team will get in touch as soon as we can!"
          />
          <link rel="canonical" href="https://neuroelectrics.com/support" />
        </Helmet>
      </>

      <div style={{ background: "white" }}>
        <ActualPath
          levelOne={{ text: "Home", url: "/" }}
          actual={{ text: "Contact" }}
        />
      </div>
      <Container
        fluid
        style={
          width < 575
            ? {}
            : {
              paddingLeft: "15vw",
              paddingRight: "15vw",
              paddingTop: "0",
              paddingBottom: "2vw"
            }
        }
      >
        <Row>
          <Col md={9} lg={9} className={width < 575 ? "p-0" : ""}>
            <div
              style={
                width < 575
                  ? { padding: "4%" }
                  : { paddingBottom: "1%", paddingLeft: "1%", paddingTop: "2%" }
              }
            >
              <h1 style={title}>Contact us</h1>
              <h2 style={subtitle}>
                For any technical questions or issues regarding our products our
                services, please fill in our Support form.
              </h2>
            </div>
            <div
              className="getQuoteForm"
              style={
                width < 575
                  ? { padding: "4%", paddingTop: 0, boxShadow: "0" }
                  : {}
              }
            >
              <Form
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_CONTACT_HBSPT_FORM_ID}
                type="contact"
              />
            </div>
          </Col>
          <Col
            style={{ paddingTop: "10%", paddingBottom: "10%" }}
            md={3}
            lg={3}
          >
            <h2 style={title}>Locations</h2>
            <h5 style={subtitle}>
              <b>Europe Office</b>
            </h5>
            <p style={small}>
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Starlab+Barcelona/@41.4149429,2.1318094,15z/data=!4m2!3m1!1s0x0:0xff0aa138accf11b9?sa=X&ved=2ahUKEwi9tbbgybDlAhUDzYUKHUUxA1kQ_BIwCnoECA4QCA"
              >
                <span>Avinguda Tibidabo, 47 bis</span>
              </a>
            </p>
            <p style={small}>
              <span>08035 Barcelona, Spain</span>
            </p>
            <p style={small}>
              <span>Tel. +34 93 254 03 66</span>
            </p>
            <p style={small}>
              <span>Fax. +34 93 212 64 45</span>
            </p>
            <h5 style={small}>
              <b>US Office</b>
            </h5>
            <p style={small}>
              <a
                target="_blank"
                href="https://www.google.com/maps/place/One+Broadway,+1+Broadway,+Cambridge,+MA+02142,+USA/@42.3624028,-71.0838447,3a,75y,237.31h,94.98t/data=!3m7!1e1!3m5!1sWANjG-NQa1521G17U5BJNQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DWANjG-NQa1521G17U5BJNQ%26cb_client%3Dsearch.gws-prod.gps%26w%3D86%26h%3D86%26yaw%3D30.892466%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m5!3m4!1s0x89e370a5b9258c7b:0x7d922521464507ad!8m2!3d42.3627822!4d-71.0835375"
              >
                <span>1 Broadway, 14th Floor</span>
              </a>
            </p>
            <p style={small}>
              <span>Cambridge 02142</span>
            </p>
            <p style={small}>
              <span>Massachusetts, USA</span>
            </p>
            <p style={small}>
              <span>Tel. +1 617 225 4350</span>
            </p>
            {width > 575 && (
              <h4
                style={{
                  borderTop: "1px solid lightgrey",
                  marginTop: "15%",
                  marginBottom: "15%"
                }}
              >
                {" "}
              </h4>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Contact
