import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import SweetAlert from "sweetalert2-react";
import { Helmet } from "react-helmet";
import axios from "axios";

import "./targeteditorepilepsy.css";

const TargetEditorEpilepsy = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showAlert, setShowAlert] = useState(false);
  const [showIdAlert, setShowIdAlert] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = () => setWidth(window.innerWidth);

  const handleSubmit = (e) => {

    e.preventDefault()

    if (!e.target[1].value || !e.target[2].value) {
      setShowAlert(true);
      return showAlert;

    } else if (e.target[3].value.toString().length !== 5) {
      setShowIdAlert(true);
      return showIdAlert;  

    } else {    

      const today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth()+1; //January is 0!
      const yyyy = today.getFullYear();
      if(dd<10) { dd='0'+dd };
      if(mm<10) { mm='0'+mm };

      const filename = mm+dd+yyyy+"-tMap"+e.target[2].value+"-subjectID_"+e.target[3].value;

      let blob = new Blob([e.target[1].value], {type: 'text/plain'});

      const file = new FormData();

      file.append("file", blob, `${filename}.txt`);

      let application = {        
        email: e.target[0].value,
        patient: e.target[3].value,        
      }

      for (let key in application) {
        file.append(key, application[key])
      }      

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/neuroelectrics/targeteditorepilepsy`,
          file
        )
        .then(res => window.location.reload() && window.scrollTo(0, 0))
        .catch(err => console.log(err))      
    }
  }

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "2vw"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  return (
    <Fragment>
      {/* Helmet */}
      <>
        <Helmet>
          <title>
            Online Target Editor - Epilepsy | Neuroelectrics
          </title>
          <meta
            name="twitter:title"
            content="Online Target Editor - Epilepsy | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Online Target Editor - Epilepsy | Neuroelectrics"
          />

          <meta
            name="description"
            content="Computational head models are essential tools for maximizing the effects of stimulation and accurately interpreting the results of your study. The electrical current distribution (or equivalent electric field) in tES determines its effects on neurons."
          />
          <meta
            name="twitter:description"
            content="Computational head models are essential tools for maximizing the effects of stimulation and accurately interpreting the results of your study. The electrical current distribution (or equivalent electric field) in tES determines its effects on neurons."
          />
          <meta
            property="og:description"
            content="Computational head models are essential tools for maximizing the effects of stimulation and accurately interpreting the results of your study. The electrical current distribution (or equivalent electric field) in tES determines its effects on neurons."
          />
          <meta name="robots" content="none" />
          
        </Helmet>
      </>

      <Container style={{ paddingTop: "5.6vw", background: "transparent" }}>
        <h1 style={title}>Neuroelectrics® Stimtargeter - Epilepsy</h1>
      </Container>

      {/* Target Editor */}
      <>
        <iframe
          title="target editor program"
          src={process.env.REACT_APP_LOCAL_UNITY_EPILEPSY_URL}
          frameBorder="0"
          scrolling="np"
          style={{ width: "100%", height: "45vw", overflow: "hidden" }}          
        />

      </>

      {/* Form */}
      <>
        <div className={width > 575 ? "p-5" : "p-4"}>
          <Container className="pb-5">
            <Row>
              <Form
                id="targetForm"
                className="w-100 targetEditorForm getQuoteForm"
                onSubmit={handleSubmit}
                style={{ padding: "3%" }}
              >
                <Row>
                  <Col xs={12}>
                    <h2 style={title}>Customer information</h2>
                  </Col>
                  
                  <Col sm={12} >
                    <Form.Group controlId="email">
                      <Form.Label className="formTitle" style={small}>
                        Email address*
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter email"
                      />
                      <p className="small">
                        Please introduce your email address.
                      </p>
                    </Form.Group>
                  </Col>                  

                  <Col xs={12} className="pt-4 pb-2">
                    <h2 style={title}>Stimulation Parameters</h2>
                    <p style={smallbold} className="m-0">
                      Target data
                    </p>
                    <span
                      className="labelDanger" id="brainDataStatus"
                    >                      
                      Missing
                    </span>
                    <h5 className="formTitle small mt-1">
                      Use the "Save" button in the Target Editor to update the data.
                    </h5>
                    <Form.Group>
                      <SweetAlert
                        show={showAlert}
                        title="Warning!"                        
                        confirmButtonColor="#3085d6"
                        text="You need to add data from the Target Editor, use the Save button on the frame above to do that!"
                        onConfirm={() => setShowAlert(false)}
                      />
                      <Form.Control
                          required                        
                          id="formBrainData"
                          type="hidden"
                        />
                        <Form.Control
                          required                          
                          id="formBrainType"
                          type="hidden"
                        />
                     </Form.Group>
                  </Col>

                  <Col sm={12} className="pt-4 pb-2">
                    <h2 style={title}>Patient Information</h2>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group>
                      <SweetAlert
                        show={showIdAlert}
                        title="Warning!"                        
                        confirmButtonColor="#3085d6"
                        text="The Patient Identification Code should have 5 digits"
                        onConfirm={() => setShowIdAlert(false)}
                      />
                      <Form.Label className="p-0" style={smallbold}>
                        Patient Identification Code (5 digits)*:
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter the Patient Identification Code"                        
                      />
                      <p className="small">
                        Please introduce the patient identification code. This
                        should be a 5 digits label that uniquely identifies each patient
                        in your study.
                      </p>
                    </Form.Group>
                  </Col>                  

                  <Col sm={6}>
                    <button type="submit" className="careersButton">
                      Send
                    </button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </div>
      </>
    </Fragment>
  )
}

export default TargetEditorEpilepsy
