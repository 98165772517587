export const starstimStimulationFamilyData = {
  title: "Starstim Stimulation",
  sections: [
    { title: "360° solution" },
    { title: "Technology" },
    { title: "Resources" },
    { title: "Products Comparison" },
    {
      redirect: "Starstim",
      sections: [
        {
          title: "32",
          to: "starstim/32"
        },
        {
          title: "20",
          to: "starstim/20"
        },
        {
          title: "8",
          to: "starstim/8"
        },
        {
          title: "tES",
          to: "starstim/tes"
        }
      ]
    }
  ]
}

export const starstimHomeStimulationFamilyData = {
  title: "Starstim-Home System",
  sections: [
    { title: "360° Solution" },
    { title: "Technology" },
    {
      redirect: "Starstim",
      sections: [
        {
          title: "tES",
          to: "starstimhome/tes"
        }
      ]
    }
  ]
}

export const EegSubMenuFamilyData = {
  title: "Enobio EEG",
  sections: [
    { title: "360° Solution" },
    { title: "Technology" },
    { title: "Resources" },
    { title: "Products Comparison" },
    {
      redirect: "Enobio",
      sections: [
        {
          title: "32",
          to: "enobio/32"
        },
        {
          title: "20",
          to: "enobio/20"
        },
        {
          title: "8",
          to: "enobio/8"
        },
        {
          title: "Dx",
          to: "enobiodx"
        }
      ]
    }
  ]
}
export const DxSubMenuFamilyData = {
  title: "Enobio Dx",
  sections: [
    { title: "Technology" },
    { title: "Family Products" },

  ]
}