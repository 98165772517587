import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getStarstimFam } from "../../api/api";
import { Link } from "react-router-dom"
import { downloadManuals } from "../../api/api"

import starstim_family from "../../contents/solutions_family_starstim";
import ebooks from "../../contents/ebooks";
import { products } from "../contents";

import OnepageMenu from '../../components/OnepageMenu';
import TableComparison from '../../components/TableComparison';
import Banner from '../../components/Banner';
import ActualPath from '../../components/ActualPath';
import Loading from '../../components/Loading';
import Resources from '../../components/Resources';
import Button from '../../components/Button';

import { starstimStimulationFamilyData } from '../../sectionsData';

const content = () => {
  const ref = starstim_family.product_table.product_references;
  let table = [];
  products.map(item => {
    const i = item;
    ref.map(item => {
      const ii = item;
      ii === i._id && table.push(i);
    });
  });
  starstim_family.product_table.product_items = table;

  const ref2 = starstim_family.resources.reference_ids;
  let resources = [];
  ebooks.map(item => {
    const i = item;
    ref2.map(item => {
      const ii = item;
      ii === i._id && resources.push(i);
    });
  });
  starstim_family.resources.resources_items = resources;

  return starstim_family;
}

const FamilyStarstim = () => {

  const data = content();
  // const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const sheetfile = data.support.technical_sheet_file;
  const brochurefile = data.support.brochure_sheet_file;

  if (
    window.location.pathname.substring(window.location.pathname.length - 1) ===
    '/'
  ) {
    window.location.pathname = window.location.pathname.slice(0, -1)
  }

  useEffect(() => {
    window.location.hash === '' && window.scrollTo(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const newData = await getStarstimFam();
    //     setData(newData);  

    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    // setData(content);

    const paramRef = window.location.hash;

    starstimStimulationFamilyData.sections.map(
      item => '#' + item.title === paramRef && item.title.scrollIntoView()
    )

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  const titleC =
  width < 575
      ? {
          fontWeight: 500,
          fontSize: '8vw',
          marginTop: 8
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: '4vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '3vw'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '2.3vw'
        }
      : {
          fontWeight: 500,
          fontSize: '2vw'
        }

  const titleSupport = 
    width < 575
    ? {
        fontWeight: 500,
        fontSize: '8vw',
        marginTop: 0
      }
    : width > 574 && width < 769
    ? {
        fontWeight: 500,
        fontSize: '4vw'
      }
    : width < 1025 && width > 768
    ? {
        fontWeight: 500,
        fontSize: '3vw',
        marginTop: 20
      }
    : width < 1441 && width > 1024
    ? {
        fontWeight: 500,
        fontSize: '2.3vw',
        marginTop: 20
      }
    : {
        fontWeight: 500,
        fontSize: '2vw',
        marginTop: 20
      }  
  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '8vw',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: '4vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '3vw'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '2.3vw'
        }
      : {
          fontWeight: 500,
          fontSize: '2vw',
          marginTop: 20
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '4.7vw',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2.8vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1.5vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1.1vw',
          lineHeight: 'initial'
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '1.3vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '16px',
          textDecoration: 'none'
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: 'none',
          fontSize: '2.2vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '1.5vw',
          textDecoration: 'none'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '1.2vw',
          textDecoration: 'none',
          lineHeight: 'initial'
        }
      : {
          fontSize: '1.2vw',
          fontWeight: 500,
          textDecoration: 'none',
          lineHeight: 'initial'
        }

  if (data !== undefined) {
    var responsiveWidth = "50vw"
    var responsiveBackground = "cover"

    if (width < 1024 && width > 992) {
      responsiveWidth = "70vw"
    } else if (width < 992 && width > 768) {
      responsiveWidth = "85vw"
      responsiveBackground = "cover"
    } else if (width < 769) {
      responsiveWidth = "95vw"
      responsiveBackground = "cover"

    }
    return (
      <div>
        <Helmet>
          <title>Starstim® tES-EEG systems | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="twitter:Starstim® tES-EEG systems | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Starstim® tES-EEG systems | Neuroelectrics"
          />

          <meta
            name="description"
            content="Unique and all-in-one, multi-channel solutions for wireless brain stimulation and monitoring"
          />
          <meta
            name="twitter:description"
            content="Unique and all-in-one, multi-channel solutions for wireless brain stimulation and monitoring"
          />
          <meta
            property="og:description"
            content="Unique and all-in-one, multi-channel solutions for wireless brain stimulation and monitoring"
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/solutions/starstim/"
          />
        </Helmet>

        {/* Single Page Menu */}
        <>
          {width > 575 && (
            <OnepageMenu onepageMenuSections={starstimStimulationFamilyData} />
          )}
        </>

        {/* Banner */}
        <>
          <Banner
            width={width}
            backgroundWhite={width < 426 && true}
            responsiveBannerBackgroundPaddingLeft={width < 426 && '16%'}
            heightResponsive={width > 425 && '25vw'}
            justifyDiv="center"
            backgroundPadding={
              width < 575
                ? 'center'
                : width > 574 && width < 769
                ? '-30vw'
                : width < 1025 && width > 768
                ? 'center'
                : width < 1441 && width > 1024
                ? 'center'
                : 'center'
            }
            textsPaddingRight={
              width < 575
                ? '0'
                : width > 574 && width < 769
                ? '32%'
                : width < 1025 && width > 768
                ? '22%'
                : width < 1441 && width > 1024
                ? '22%'
                : '22%'
            }
            title={data.banner.title}
            titleFontSize={
              width < 575
                ? '5.8vw'
                : width > 574 && width < 769
                ? '4vw'
                : width < 1025 && width > 768
                ? '2.5vw'
                : width < 1441 && width > 1024
                ? '2.5vw'
                : '2.5vw'
            }
            titleFontWeight="500"
            subtitle={data.banner.subtitle}
            subtitleFontSize={
              width < 575
                ? '4.1vw'
                : width > 574 && width < 769
                ? '2.6vw'
                : width < 1025 && width > 768
                ? '1.7vw'
                : width < 1441 && width > 1024
                ? '1.4vw'
                : '1.4vw'
            }
            subtitleFontWeight="300"
            img={isWebpSupported() ? `/img/banners/${newImg(data.banner.image.path)}` : `/img/banners/${data.banner.image.path}`}
          />
        </>

        {/* Actual Path */}
        <>
          <ActualPath
            levelOne={{ text: 'Home', url: '/' }}
            actual={{ text: 'Solutions / Starstim Stimulation' }}
          />
        </>

        {/* Complex tES investigations made simple */}
        <>
          <Container
            fluid
            id={starstimStimulationFamilyData.sections[0].title}
            style={
              width < 768
                ? { padding: '5%' }
                : width > 767
                ? {
                    background: 'linear-gradient(to bottom, #f7f6f5, #fff)',
                    paddingTop: '5%',
                    paddingBottom: '5%'
                  }
                : {}
            }
          >
            <Row>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={isWebpSupported() ? `/img/${
                    width < 575
                      ? newImg(data.concept.image.mobile)
                      : newImg(data.concept.image.path)
                  }` : 
                  `/img/${
                    width < 575
                      ? data.concept.image.mobile
                      : data.concept.image.path
                  }`}
                  alt={data.concept.image.title}
                  className="w-75 p-4"
                />
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div>
                  <h2 style={width < 575 ? subtitle : title}>
                    {width < 575 ? (
                      <span style={{ fontWeight: 500, fontSize: '130%' }}>
                        {data.concept.title}
                      </span>
                    ) : (
                      data.concept.title
                    )}
                  </h2>

                  {data.concept.application.map((item, index) => {
                    return (
                      <div key={index} className="m-0 pt-3">
                        <p style={subtitle} className={width < 575 && 'm-0'}>
                          <span style={{ fontWeight: 500 }}>{item.title}</span>
                        </p>
                        <p style={subtitle} className={width < 575 && 'm-0'}>
                          {item.content}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </>

        {/* Features */}
        <>
          <div
            id={starstimStimulationFamilyData.sections[1].title}
            className={width < 769 ? ' pt-1 pb-1' : 'pt-5 pb-5'}
            style={{
              backgroundImage: isWebpSupported() ? `url(${`/img/${newImg(data.technology.image.path)}`})` : `url(${`/img/${data.technology.image.path}`})`,
              backgroundSize: width < 769 ? 'contain' : 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              position: 'relative'
            }}
          >
            <Container fluid style={width > 1440 ? {} : {}}>
              <Row className="justify-content-between align-items-center p-4 position-relative">
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  style={
                    width < 1024
                      ? { background: 'rgba(255,255,255,.7)', padding: '5%' }
                      : { paddingLeft: '5%', paddingRight: '15%' }
                  }
                >
                  <h2
                    style={title}
                    style={width < 575 ? { fontSize: '180%' } : {}}
                  >
                    {data.technology.title}
                  </h2>
                  <p
                    className={width < 575 ? 'pt-3 pb-0 mb-2' : 'pt-3 pb-3'}
                    style={subtitle}
                  >
                    {data.technology.subtitle}
                  </p>
                  {width > 575 && (
                    <div className="d-flex justify-content-flex-start align-items-center">
                      <Button
                        text="Get a quote"
                        url="/get-quote/"
                        refe={data._id}
                        marginHorizontal="1vw"
                        padding={width > 1441 && '15px 30px'}
                        radius={
                          width > 1441
                            ? '50px'
                            : width < 1440 && width > 768 && '75px'
                        }
                        font={
                          width < 575
                            ? '3.8vw'
                            : width > 574 && width < 769
                            ? '2vw'
                            : width < 1025 && width > 768
                            ? '1.6vw'
                            : width < 1441 && width > 1024
                            ? '1.2vw'
                            : '1.1vw'
                        }
                        shadow
                      />

                      <Button
                        text="Get a demo"
                        url="/contact"
                        width="10vw"
                        refe={data._id}
                        marginHorizontal="0"
                        padding={width > 1441 && '15px 30px'}
                        radius={
                          width > 1441
                            ? '50px'
                            : width < 1440 && width > 768 && '75px'
                        }
                        font={
                          width < 575
                            ? '3.8vw'
                            : width > 574 && width < 769
                            ? '2vw'
                            : width < 1025 && width > 768
                            ? '1.6vw'
                            : width < 1441 && width > 1024
                            ? '1.2vw'
                            : '1.1vw'
                        }
                        shadow
                      />
                    </div>
                  )}
                </Col>

                {width < 575 && (
                  <div className="d-flex justify-content-center align-items-center w-100 pt-4 pb-4">
                    <Button
                      text="Get a quote"
                      url="/get-quote/"
                      refe={data._id}
                      marginHorizontal="3vw"
                      padding={width > 1441 && '15px 30px'}
                      radius={
                        width > 1441
                          ? '50px'
                          : width < 1440 && width > 768 && '75px'
                      }
                      font={subtitle}
                      shadow
                    />

                    <Button
                      text="Get a demo"
                      url="/contact"
                      width="10vw"
                      refe={data._id}
                      marginHorizontal="3vw"
                      padding={width > 1441 && '15px 30px'}
                      radius={
                        width > 1441
                          ? '50px'
                          : width < 1440 && width > 768 && '75px'
                      }
                      font={subtitle}
                      shadow
                    />
                  </div>
                )}

                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  style={
                    width < 1024
                      ? { background: 'rgba(255,255,255,.7)', padding: '5%' }
                      : { paddingRight: '5%' }
                  }
                  className={width < 1025 ? ' p-3 mt-4' : ' pl-5 ml-5'}
                >
                  <h2
                    style={title}
                    style={width < 575 ? { fontSize: '180%' } : {}}
                  >
                    {data.technology.features_title}
                  </h2>
                  <Row>
                    {data.technology.features.map((item, index) => {
                      return (
                        <Col md={12} key={index}>
                          <p style={subtitle} className={width < 575 && 'm-0'}>
                            <span style={{ fontWeight: 500 }}>
                              {item.title}
                            </span>
                          </p>
                          <p
                            style={subtitle}
                            className={width < 575 && 'm-0 mb-3'}
                          >
                            {item.content}
                          </p>
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </>
        {/* Support */}
          <div
            style={{
              backgroundImage: isWebpSupported() ? "url(/img/components_support.webp)" : "url(/img/components_support.jpg)",
              backgroundPosition: "center",
              backgroundSize: responsiveBackground,
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%"
            }}
          >
              <Row
              style={{
                padding: "2%",
                margin: "5%",
                background: "white",
                width: responsiveWidth
              }}
            >
              <Col md={7} className="componentsSupport">
                <h2 
                style={titleSupport}
                >
                  {data.support.title}
                </h2>
                <p style={subtitle}>{data.support.intro}</p>
              </Col>
              <Col md={5} className="componentsSupport">
                <Link
                  className="componentsSupportLink"
                  onClick={() => downloadManuals(sheetfile)}
                  style={{
                    borderBottom: "1px solid lightgrey",
                    marginBottom: "22% !important",
                    width: "100%",
                    padding: "4%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  
                  <h3
                    style={{
                      fontSize: "1vw",
                      paddingLeft: ".8vw",
                      height: "100%",
                      margin: 0,
                      color: "#555555"
                    }}
                    
                  >
                    Technical sheet{"      "}
                  </h3>
                  <img
                    style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                    src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                    alt="/img/arrow.png"
                  />
                </Link>
                <Link
                  className="componentsSupportLink"
                  onClick={() => downloadManuals(brochurefile)}
                  style={{
                    marginBottom: "22% !important",
                    width: "100%",
                    padding: "5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1vw",
                      height: "100%",
                      margin: 0,
                      color: "#555555"
                    }}
                  >
                    Product brochure <u></u>{" "}
                  </h3>
                  <img
                    style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                    src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                    alt="arrow-asset"
                  />
                </Link>
              </Col>
            </Row>            
          </div>
        {data.resources.resources_items.length > 0 && (
          <Resources
            id={starstimStimulationFamilyData.sections[2].title}
            data={data.resources.resources_items}
            title={data.resources.title}
          />
        )}

        <TableComparison
          id={starstimStimulationFamilyData.sections[3].title}
          data={data.product_table.product_items}
          features={data.product_table.applications}
          service={data.product_table.service}
        />
      </div>
    )
  } else {
    return <Loading />
  }
}

export default FamilyStarstim
