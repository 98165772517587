import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import {isWebpSupported} from 'react-image-webp/dist/utils';

import HubspotForm from "react-hubspot-form"

import "./footer.css"

const currentTime = new Date();
const thisYear = currentTime.getFullYear();

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: linear-gradient(to bottom, #e0e8e8, #f7f6f5);
  width: 100%;
`

const Footer = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [window.location.pathname])

  const handleResize = () => setWidth(window.innerWidth)

  return (
    <footer>
      {window.location.pathname != "/menuforblog" && (
        <Cont
          style={{
            padding: "4%",
            paddingBottom: "2%"
          }}
        >
          <Container fluid style={{ background: "white", padding: "3%" }}>
            <Row className="footerRight">
              <Col
                xl={2}
                lg={6}
                md={6}
                sm={6}
                style={width < 769 ? { marginTop: "4%" } : {}}
              >
                <Link
                  className="navbar-brand"
                  to="/"
                  style={{
                    height: "auto",
                    width: "100%",
                    paddingRight: "25%"
                  }}
                  target={
                    window.location.pathname === "/footerforblog"
                      ? "_blank"
                      : ""
                  }
                >
                  <img
                    src={isWebpSupported() ? require("../../assets/img/logo-02.webp") : require("../../assets/img/logo-02.png")}
                    alt="neuroelectrics-logo"
                    style={width < 575 ? { width: "60%" } : { width: "90%" }}
                  />
                </Link>
                <p className="m-0"></p>
                <p className="mt-3">
                  Reinventing brain health. Personalized technologies for brain
                  monitoring and stimulation.
                </p>
              </Col>
              {width > 1200 && <div className="transparentDash"></div>}
              <Col
                xl={2}
                lg={6}
                md={6}
                sm={6}
                style={width < 1200 ? {} : { paddingLeft: "5vw" }}
              >
                <p className="footerSection">Our company</p>
                <p className="mt-2">
                  <Link
                    to="/neuroelectrics/whoweare"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Who we are
                  </Link>
                </p>

                <p className="mt-2">
                  <Link
                    to="/neuroelectrics/team"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Team
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroelectrics/careers"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Careers
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroelectrics/distributors"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Collaborators
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroelectrics/news-events"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    News and Events
                  </Link>
                </p>
              </Col>
              <Col
                xl={2}
                lg={6}
                md={6}
                sm={6}
                style={width < 769 ? { marginTop: "4%" } : {}}
              >
                <p className="footerSection">Solutions</p>
                <p className="mt-2">
                  <Link
                    to="/solutions/enobio"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Enobio EEG
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/solutions/starstim"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Starstim Stimulation
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/solutions/software-integrations"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Software Integrations
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/solutions/spareparts-consumables"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Spare Parts & Consumables
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/solutions/modeling-services"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Modeling Services
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/solutions/target-editor"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Target Editor
                  </Link>
                </p>
              </Col>
              <Col
                xl={2}
                lg={6}
                md={6}
                sm={6}
                style={width < 769 ? { marginTop: "4%" } : {}}
              >
                <p className="footerSection">Neuroscience</p>
                <p className="mt-2">
                  <Link
                    to="/neuroscience/eeg"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    EEG Technology
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroscience/brainstimulation"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Stimulation Technology
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroscience/home-therapy"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Home System Technology
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroscience/research-applications"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Neuroscience Research
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/neuroscience/clinical"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Clinical Research
                  </Link>
                </p>
              </Col>
              <Col
                xl={2}
                lg={6}
                md={6}
                sm={6}
                style={width < 769 ? { marginTop: "4%" } : {}}
              >
                <p className="footerSection">Resources</p>
                <p className="mt-2">
                  <a
                    target="_blank"
                    href="https://www.neuroelectrics.com/wiki/index.php/Collection_of_publications_of_independent_research_studies_and_mentions_about_Starstim"
                  >
                    Papers
                  </a>
                </p>
                <p className="mt-2">
                  <Link
                    to="/resources/library"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Library
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/resources/webinar"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Webinars
                  </Link>
                </p>
                <p className="mt-2">
                  <a
                    target="_blank"
                    href="https://www.neuroelectrics.com/blog"
                  >
                    Blog
                  </a>
                </p>
                <p className="mt-2">
                  <Link
                    to="/resources/videos"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Videos
                  </Link>
                </p>
                <p className="mt-2">
                  <a
                    target="_blank"
                    href="https://www.neuroelectrics.com/wiki/"
                  >
                    Wiki
                  </a>
                </p>
                <p className="mt-2">
                  <Link
                    to="/resources/manuals"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    User manuals
                  </Link>
                </p>
                <p className="mt-2">
                  <Link
                    to="/resources/software"
                    target={
                      window.location.pathname === "/footerforblog"
                        ? "_blank"
                        : ""
                    }
                  >
                    Software downloads
                  </Link>
                </p>
              </Col>
              <Col
                xl={2}
                lg={6}
                md={6}
                sm={6}
                style={width < 769 ? { marginTop: "4%" } : {}}
              >
                <p className="mb-2">Subscribe to our newsletter</p>

                <HubspotForm
                  portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                  formId={process.env.REACT_APP_NEWSLETTER_HBSPT_FORM_ID}
                  loading={<div>Loading...</div>}
                />

                <p
                  style={
                    width < 575
                      ? {
                          color: "#000",
                          fontWeight: "bolder",
                          marginTop: "15%",
                        }
                      : {
                          color: "#000",
                          fontWeight: "bolder",
                          marginTop: "15%",
                        }
                  }
                >
                  Follow us
                </p>
                <div className="d-flex pr-1 pl-0 pb-3">
                  <a
                    href="https://www.facebook.com/Neuroelectrics/"
                    target="_blank"
                    className="pr-1"
                  >
                    <img
                      src={isWebpSupported() ? require("./assets/facebook.webp") : require("./assets/facebook.png")}
                      alt="facebook.png"
                      style={width < 575 ? { width: "95%" } : { width: "100%" }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/neuroelectrics/?hl=en"
                    target="_blank"
                    className="pr-1"
                  >
                    <img
                      src={isWebpSupported() ? require("./assets/instagram.webp") : require("./assets/instagram.png")}
                      alt="instagram.png"
                      style={width < 575 ? { width: "95%" } : { width: "100%" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/neuroelectrics-barcelona-s-l-/"
                    target="_blank"
                    className="pr-1"
                  >
                    <img
                      src={isWebpSupported() ? require("./assets/linkedin.webp") : require("./assets/linkedin.png")}
                      alt="linkedin.png"
                      style={width < 575 ? { width: "95%" } : { width: "100%" }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/Neuroelectrics"
                    target="_blank"
                    className="pr-1"
                  >
                    <img
                      src={isWebpSupported() ? require("./assets/twitter.webp") : require("./assets/twitter.png")}
                      alt="twitter.png"
                      style={width < 575 ? { width: "95%" } : { width: "100%" }}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/user/neuroelectrics"
                    target="_blank"
                    className="pr-1"
                  >
                    <img
                      src={isWebpSupported() ? require("./assets/youtube.webp") : require("./assets/youtube.png")}
                      alt="youtube.png"
                      style={width < 575 ? { width: "95%" } : { width: "100%" }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>

          {/* LEGALS */}
          <div
            className="d-flex justify-content-around"
            style={
              width > 575
                ? { color: "#000", width: "500px", marginTop: "3%" }
                : {
                  color: "#000",
                  width: "100vw",
                  padding: "2%",
                  marginTop: "5%"
                }
            }
          >
            <Link
              to="/legals/privacy-policy"
              rel="nofollow"
              style={{ color: "#000" }}
              target={
                window.location.pathname === "/footerforblog" ? "_blank" : ""
              }
            >
              Privacy Policy
            </Link>
            |
            <Link
              to="/legals/terms-of-use"
              rel="nofollow"
              style={{ color: "#000" }}
              target={
                window.location.pathname === "/footerforblog" ? "_blank" : ""
              }
            >
              Terms of use
            </Link>
            |
            <Link
              to="/legals/quality-policy"
              rel="nofollow"
              style={{ color: "#000" }}
              target={
                window.location.pathname === "/footerforblog" ? "_blank" : ""
              }
            >
              Quality Policy
            </Link>
            |
            <Link
              to="/cookiespolicy"
              rel="nofollow"
              style={{ color: "#000" }}
              target={
                window.location.pathname === "/footerforblog" ? "_blank" : ""
              }
            >
              Cookies Policy
            </Link>
          </div>
          <p className={width < 575 ? "" : "pt-3"} style={{ fontSize: "90%" }}>
            Copyright {thisYear} Neuroelectrics. All rights reserved.
          </p>
        </Cont>
      )}
    </footer>
  )
}

export default Footer
