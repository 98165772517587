import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isWebpSupported } from 'react-image-webp/dist/utils';
// import { getCareers } from "../../../api/api";

import neuroelectrics_careers from "../../../contents/neuroelectrics_careers";

import ActualPath from "../../../components/ActualPath";
import Loading from "../../../components/Loading";
import CarouselBox from "../../../components/CarouselBox";
import Collapsable from "../../../components/Collapsable";
import Banner from "../../../components/Banner";

import axios from "axios";

import "./careers.css";

const Careers = ({ history }) => {

  const data = neuroelectrics_careers;
  const [width, setWidth] = useState(window.innerWidth)
  // const [data, setData] = useState()
  const [fileUpload, setFileUpload] = useState()
  const [fileUpload2, setFileUpload2] = useState()

  useEffect(() => {
    window.scroll(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const careers = await getCareers();
    //     setData(careers);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0] + ".webp";

  const apply = id => {
    let a = document.getElementById("position")
    a.value = id
    a.focus()
  }

  const handleSubmit = e => {
    e.preventDefault()
    var application = {
      position: e.target[0].value,
      name: e.target[1].value,
      lastname: e.target[2].value,
      email: e.target[3].value
    }

    let filesSize

    if (fileUpload.files[0] && fileUpload2.files[0]) {
      filesSize = fileUpload.files[0].size + fileUpload2.files[0].size
    } else if (fileUpload.files[0]) {
      filesSize = fileUpload.files[0].size
    } else if (fileUpload2.files[0]) {
      filesSize = fileUpload2.files[0].size
    }

    if (filesSize > 7340032) {
      window.alert("Maximum upload files size is 7MB")
    } else {

      const files = new FormData()

      files.append("file", fileUpload.files[0])
      files.append("file", fileUpload2.files[0])

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/neuroelectrics/careers/${application.position}/${application.name}/${application.lastname}/${application.email}`,
          files
        )
        .then(res => history.push('/careers-thanks'))
        .catch(err => console.log(err))

    }
  }

  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "4.7vw",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1.5vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1.1vw",
              lineHeight: "initial"
            }

  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>Join the team | Neuroelectrics</title>
          <meta name="twitter:title" content="Join the team | Neuroelectrics" />
          <meta property="og:title" content="Join the team | Neuroelectrics" />

          <meta
            name="description"
            content="We're looking for people who want to add value to the world by sharing our aim in finding solutions to the world's health problems.'"
          />
          <meta
            name="twitter:description"
            content="'We're looking for people who want to add value to the world by sharing our aim in finding solutions to the world's health problems."
          />
          <meta
            property="og:description"
            content="We're looking for people who want to add value to the world by sharing our aim in finding solutions to the world's health problems."
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/neuroelectrics/careers"
          />
        </Helmet>
      </>

      {/* Banner */}
      <>
        <div
          style={
            width < 575
              ? {
                background: "linear-gradient(to bottom right, #f7f6f5, #eee)"
              }
              : {}
          }
        >
          <Banner
            width={width}
            backgroundWhite={width < 426 && true}
            responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
            heightResponsive={width > 425 && "15vw"}
            justifyDiv="center"
            paddingTop="3.4vw"
            textsPaddingRight={
              width < 575
                ? "0"
                : width > 574 && width < 769
                  ? "22%"
                  : width < 1025 && width > 768
                    ? "22%"
                    : width < 1441 && width > 1024
                      ? "22%"
                      : "22%"
            }
            title={data.banner.title}
            titleFontSize={
              width < 575
                ? "5.8vw"
                : width > 574 && width < 769
                  ? "4vw"
                  : width < 1025 && width > 768
                    ? "2.5vw"
                    : width < 1441 && width > 1024
                      ? "2.5vw"
                      : "2.5vw"
            }
            titleFontWeight="400"
          />
        </div>
      </>

      {/* Actual Path */}
      <>
        <ActualPath
          levelOne={{ text: "Home", url: "/" }}
          actual={{ text: "Neuroelectrics / Careers" }}
        />
      </>

      {/* Work Hard. Be Humble. Seek Perfection */}
      <>
        <div className={width > 575 ? "pt-5 pb-5" : "pt-3 pb-3 pl-1 pr-1"}>
          <Container
            fluid
            style={
              width < 575
                ? { background: "#f7f6f5", padding: "5%" }
                : width > 574 && width < 769
                  ? {
                    background: "#f7f6f5",
                    display: "flex",
                    flexDirection: "column-reverse"
                  }
                  : width < 1025 && width > 768
                    ? {
                      background: "#f7f6f5",
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
                    : width < 1441 && width > 1024
                      ? {
                        background: "#f7f6f5",
                        paddingLeft: "15vw",
                        paddingRight: "15vw"
                      }
                      : {
                        background: "#f7f6f5",
                        paddingLeft: "15vw",
                        paddingRight: "15vw"
                      }
            }
          >
            <Row style={width > 1024 ? { height: "25vw" } : { height: "auto" }}>
              <Col sm={12} md={6}>
                <h2
                  style={
                    width < 575
                      ? {
                        fontWeight: 500,
                        fontSize: "8vw",
                        marginTop: 0
                      }
                      : width > 574 && width < 769
                        ? {
                          fontWeight: 500,
                          fontSize: "4vw"
                        }
                        : width < 1025 && width > 768
                          ? {
                            fontWeight: 500,
                            fontSize: "3vw"
                          }
                          : width < 1441 && width > 1024
                            ? {
                              fontWeight: 500,
                              fontSize: "2.3vw"
                            }
                            : {
                              fontWeight: 500,
                              fontSize: "2vw"
                            }
                  }
                >
                  {data.text1}
                </h2>
                <br></br>
                <h5
                  style={
                    width < 575
                      ? {
                        fontWeight: 300,
                        fontSize: "4.7vw",
                        lineHeight: "initial",
                        marginTop: 0
                      }
                      : width > 574 && width < 769
                        ? {
                          fontWeight: 300,
                          fontSize: "2.8vw",
                          lineHeight: "initial"
                        }
                        : width < 1025 && width > 768
                          ? {
                            fontWeight: 300,
                            fontSize: "2vw",
                            lineHeight: "initial"
                          }
                          : width < 1441 && width > 1024
                            ? {
                              fontWeight: 300,
                              fontSize: "1.5vw",
                              lineHeight: "initial"
                            }
                            : {
                              fontWeight: 300,
                              fontSize: "1.1vw",
                              lineHeight: "initial"
                            }
                  }
                >
                  <b>{data.text2}</b> {data.text3}
                </h5>
                <br></br>
                <h5
                  style={
                    width < 575
                      ? {
                        fontWeight: 300,
                        fontSize: "4.7vw",
                        lineHeight: "initial",
                        marginTop: 0
                      }
                      : width > 574 && width < 769
                        ? {
                          fontWeight: 300,
                          fontSize: "2.8vw",
                          lineHeight: "initial"
                        }
                        : width < 1025 && width > 768
                          ? {
                            fontWeight: 300,
                            fontSize: "2vw",
                            lineHeight: "initial"
                          }
                          : width < 1441 && width > 1024
                            ? {
                              fontWeight: 300,
                              fontSize: "1.5vw",
                              lineHeight: "initial"
                            }
                            : {
                              fontWeight: 300,
                              fontSize: "1.1vw",
                              lineHeight: "initial"
                            }
                  }
                >
                  {data.text4}
                  <b>
                    <u>{data.text5}</u>
                  </b>
                </h5>
              </Col>
              <Col className="position-relative">
                <img
                  style={
                    width < 575
                      ? {
                        display: "none"
                      }
                      : width > 574 && width < 1025
                        ? { position: "absolute", top: "-30%", width: "75%" }
                        : width > 1024 && width < 1440
                          ? { position: "absolute", top: "-50%", width: "60%" }
                          : width > 1441
                            ? { position: "absolute", top: "-50%", width: "60%" }
                            : { position: "absolute", top: "-100%", width: "75%" }
                  }
                  src={isWebpSupported() ? `/img/${newImg(data.image.path)}` : `/img/${data.image.path}`}
                  alt={data.image.title}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>

      {/* Carousel  */}
      <>
        <div>
          <Container fluid={true}>
            <CarouselBox
              width={width}
              testimonies={data.testimonies}
              interval={0}
            />
          </Container>
        </div>
      </>

      {/* Jobs */}
      <>
        <div
          style={{ background: "#f1f1f1" }}
          className={width > 575 ? "p-5" : "p-1"}
        >
          <Container>
            <Row className={width > 575 ? "p-4" : "pt-2 pb-2 pl-3 pr-3"}>
              <h3
                style={
                  width < 575
                    ? {
                      fontWeight: 500,
                      fontSize: "8vw",
                      marginTop: 0
                    }
                    : width > 574 && width < 769
                      ? {
                        fontWeight: 500,
                        fontSize: "4vw"
                      }
                      : width < 1025 && width > 768
                        ? {
                          fontWeight: 500,
                          fontSize: "3vw"
                        }
                        : width < 1441 && width > 1024
                          ? {
                            fontWeight: 500,
                            fontSize: "2.3vw"
                          }
                          : {
                            fontWeight: 500,
                            fontSize: "2vw"
                          }
                }
              >
                {data.offers.title}
              </h3>
            </Row>
            <form action="https://neuroelectrics.jobs.personio.com/">
              <button
                type="submit"
                className="careersButton"
                style={{
                  color: "white",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "1.1vw",
                  borderRadius: "50px",
                  backgroundImage:
                    "linear-gradient(to bottom right, #7a92a4, #769cbf)",
                  boxShadow:
                    "0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19)",
                  border: 0,
                  outline: "none",
                  transition: "all .3s ease-in-out",
                  width: "30%",
                  position: "relative",
                  display: "block",
                  left: "35%",
                  justifyContent: "center"
                }}
              >
                See open positions
              </button>
            </form>

          </Container>
        </div>
      </>
    </div>
  ) : (
    <Loading />
  )
}

export default Careers
