import React from "react"
import { Link } from "react-router-dom"

import "./actualpath.css"

const ActualPath = ({
  children,
  levelOne,
  levelTwo,
  levelTwoParam,
  levelThree,
  actual,
  paddingLeftResponsive,
  white,
  mobileFilterPosition,
  product,
  resources
}) => {
  return (
    <div
      className="actualPathHeader"
      style={{
        paddingLeft: paddingLeftResponsive ? paddingLeftResponsive : "15vw",
        paddingRight: "15vw",
        paddingTop: "2vw",
        paddingBottom: "2vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: white === false ? "" : "#fff",
        position: mobileFilterPosition && "relative"
      }}
    >
      <p
        style={{
          margin: 0,
          fontSize: ".9vw",
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <Link style={{ color: "inherit", display: "flex" }} to={levelOne.url}>
          <b>{levelOne.text}</b>{" "}
          {!product ? (
            <p className="m-0 ml-1 mr-1"> / </p>
          ) : (
            <p className="m-0 ml-2"> </p>
          )}
        </Link>
        {levelTwo && (
          <div className="d-flex">
            {product && ` / Solutions /`}
            {product && <p className="m-0 mr-2"> </p>}

            <Link
              style={{ color: "inherit", display: "flex" }}
              to={{
                pathname: levelTwo.url,
                param: {
                  state: levelTwoParam
                }
              }}
            >
              <b> {levelTwo.text}</b>
              {!product && !resources ? (
                "/"
              ) : (
                <p className="m-0 ml-1 mr-1"> / </p>
              )}
            </Link>
          </div>
        )}{" "}
        {levelThree && (
          <Link
            style={{ color: "inherit", display: "flex" }}
            to={levelThree.url}
          >
            <b> {levelThree.text}</b> {!product && !resources ? (
                "/"
              ) : (
                <p className="m-0 ml-1 mr-1"> / </p>
              )}
          </Link>
        )}{" "}
        {actual.text}
      </p>

      {children}
    </div>
  )
}

export default ActualPath
