import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Helmet } from "react-helmet";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./productManuals.css";

import { isWebpSupported } from "react-image-webp/dist/utils";
import { downloadManuals } from "../../../api/api";

import manuals_enobio from "../../../contents/manuals_enobio";
import manuals_enobiodx from "../../../contents/manuals_enobiodx";
import manuals_enobio_research from "../../../contents/manuals_enobioresearch";
import manuals_starstim_8_tES from "../../../contents/manuals_starstim8-tES";
import manuals_starstim_20_32 from "../../../contents/manuals_starstim20-32";
import manuals_starstim_home from "../../../contents/manuals_starstim-home";

import Loading from "../../../components/Loading";
import ActualPath from "../../../components/ActualPath";

const TableRow = ({ item }) => {

  const [version, setVersion] = useState(item.items[0].items[0].value);
  const [link, setLink] = useState(item.items[0].file);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    handleLinkChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const onVersChange = (e) => {
    setVersion(e);
  };

  const handleLinkChange = () => {

    var elements = []
    item.items.map((el) => el.items.map((ell) => elements.push(ell)));

    const file = elements.filter(
      (element) => element.value === version 
    );  

    if (!file[0].file.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
      setLink(file[0].file);
    }
  };

  return (
    <Tr>
      <Td>
        <div style={{ padding: "1% 0" }}>
          <p>{item.category}</p>
          <h5>{item.title}</h5>
        </div>
      </Td>
      <Td>
        <img
          src={require("../../../assets/img/ico_document.svg").default}
          alt="resources-user-manuals"
          style={{
            marginRight: "10px",
            width: "50px",
            height: "50px",
          }}
        />
        {item.type}
      </Td>
      <Td>
        <div style={{ padding: "0 4%" }}>
      
          <VersDropdown
            item={item}
            version={version}
            onVersChange={onVersChange}
          />
        </div>
      </Td>

      
      <Td>
        <div style={{ paddingLeft: "4%" }}>
          <button
            style={{
              padding: "0",
              fontWeight: "normal",
              width: "170px",
              border: "0 !important",
              borderRadius: "0.25rem",
            }}
            disabled={disabled}
            onClick={() => downloadManuals(link)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "#86a7c6",
                  padding: "10px 5px 10px 10px",
                  borderRadius: "0.25rem 0 0 0.25rem",
                }}
              >
                <img
                  src={
                    require("../../../assets/img/ico_arrow_down.svg").default
                  }
                  alt="resources-user-manuals"
                  style={{
                    padding: "1%",
                    width: "30px",
                    height: "30px",
                  }}
                />
              </div>
              {disabled ? (
                <div style={{ padding: "10px  16px" }}>Not available</div>
              ) : (
                <div style={{ padding: "10px  16px", margin: "auto" }}>
                  Download
                </div>
              )}
            </div>
          </button>
        </div>
      </Td>
    </Tr>
  );
};

const VersDropdown = ({ item, version, onVersChange }) => {
  
  const onSelect = (option) => {
    onVersChange(option.value);
  };
  return(
    
    item.items[0] !== "" &&
    item.items.length > 0 && (
        
      <Dropdown
        controlClassName="dropdownStyle"
        options={item.items}
        onChange={onSelect}
        placeholder={version}
      />
    )
    
    );
};


const ProductManuals = ({ location }) => {
  const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    let device;
    if (
      window.location.pathname.substring(
        window.location.pathname.length - 1
      ) === "/"
    ) {
      device = window.location.pathname.slice(0, -1).split("/").slice(-1)[0];
    } else {
      device = window.location.pathname.split("/").pop();
    }
    device === "enobio"
      ? setData(manuals_enobio)
      : device === "enobiodx"
      ? setData(manuals_enobiodx)
      : device === "enobio-research"
      ? setData(manuals_enobio_research)
      : device === "starstim-8"
      ? setData(manuals_starstim_8_tES)
      : device === "starstim-20-32"
      ? setData(manuals_starstim_20_32)
  
      : device === "starstim-home"
      ? setData(manuals_starstim_home)
      : setData();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => setWidth(window.innerWidth);
  
  
  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          lineHeight: "initial",
          marginTop: 0,
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "4vw",
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "3vw",
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "2.3vw",
        }
      : {
          fontWeight: 500,
          lineHeight: "initial",
          fontSize: "2vw",
        };


  
  if (data !== undefined) {
    

    return (
      <div className={width < 994 ? "marginTop0" : "pt60px"}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>{data.title}</title>
            <meta name="twitter:title" content={data.title} />
            <meta property="og:title" content={data.title} />

            <meta name="description" content={data.description} />
            <meta name="twitter:description" content={data.description} />
            <meta property="og:description" content={data.description} />
          </Helmet>
        </>

        <ActualPath
          resources
          levelOne={{ text: "Home", url: "/" }}
          levelTwo={{ text: "Resources", url: "/resources" }}
          levelThree={{
            text: `${data.pathLevel3}`,
            url: `/resources/${data.pathLevel3.toLowerCase()}`,
          }}
          actual={{ text: `${data.pathLevel4}` }}
        />

        <div
          style={
            width > 575 ? { padding: "4%", paddingTop: "0" } : { padding: "4%" }
          }
        >
          <Container
            fluid
            style={
              width < 575
                ? { padding: 0 }
                : { paddingLeft: "12vw", paddingRight: "12vw" }
            }
          >
            <h1
              style={title}
              className={width < 575 ? "m-0 pb-3" : "m-0 pb-5 pt-5"}
            >
              <span style={width < 575 ? { fontSize: "80%" } : {}}>
                {data.h1}
              </span>
            </h1>

            {width > 575 && (
              <Container className="p-0" fluid style={{ position: "relative" }}>
                <img
                  style={{
                    height: "20px",
                    marginBottom: "20px",
                  }}
                  className="d-flex w-100"
                  src={
                    isWebpSupported()
                      ? require("../../../assets/img/neurocience_.webp")
                      : require("../../../assets/img/neurocience_.png")
                  }
                  alt="neuroelectrics-asset-bar"
                />
              </Container>
            )}
            <Table>
              <Thead>
                <Tr>
                  <Th>Title</Th>
                  <Th style={{ paddingLeft: "25px" }}>Document Type</Th>
                  <Th style={{ paddingLeft: "25px" }}>Version & Language</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.manuals.map((item, index) => (
                  <TableRow item={item} key={index} />
                ))}
              </Tbody>
            </Table>      
          </Container>       
        </div>     
      </div>  
    );
  } else {
    return <Loading />;
  }
};

export default ProductManuals;
