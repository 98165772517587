import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import Select from "react-select"
import axios from "axios"
import {isWebpSupported} from 'react-image-webp/dist/utils';

import Gallery from "./Gallery"

import SoftwareIntegrationsList from "./SoftwareIntegrationsList"
import OnepageMenu from "../../components/OnepageMenu"
import Banner from "../../components/Banner"
import ActualPath from "../../components/ActualPath"
import Loading from "../../components/Loading"

import "./softwareintegrations.css"

const SoftwareIntegrations = ({ location }) => {
  const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)
  const [selectedOption, setSelectedOption] = useState()
  const [filter, setFilter] = useState()
  const [isLoading, setLoading] = useState("none")

  const search = useRef()

  useEffect(() => {
    getData()
    window.addEventListener("resize", handleResize)
  }, [selectedOption, location.pathname])

  let url
  location.pathname === "/solutions/starstim-integrations"
    ? (url = "/fnirsmri")
    : (url = "/softwareintegrations")

  const getData = () =>
    axios
      .post(`${process.env.REACT_APP_API_URL}${url}`, {
        filter: filter
      })
      .then(res => {
        setData(res.data)
        setLoading("none")
      })

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  const titlee =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  const handleChange = selectedOption => {
    search.current.blur()
    setLoading("block")
    setSelectedOption(selectedOption)
    data.compatibility_filter.map(
      (item, index) => item === selectedOption && setFilter(index)
    )
  }

  if (data !== undefined) {
    var responsiveWidth = "50vw"
    var responsiveBackground = "cover"
    var responsivePadding = {
      paddingLeft: "16vw",
      paddingRight: "16vw",
      paddingTop: ".5vw",
      paddingBottom: ".5vw",
      position: "relative"
    }

    if (width < 1024 && width > 992) {
      responsiveWidth = "70vw"
    } else if (width < 992 && width > 768) {
      responsiveWidth = "85vw"
      responsiveBackground = "cover"
    } else if (width < 769) {
      responsiveWidth = "95vw"
      responsiveBackground = "cover"
      var responsivePadding = {
        paddingLeft: "3vw",
        paddingRight: "3vw",
        paddingTop: ".5vw",
        paddingBottom: ".5vw"
      }
    }

    return (
      <div style={{ background: "white" }}>
        <Helmet>
          <title>
            {location.pathname === "/solutions/starstim-integrations"
              ? "Ever-evolving software solutions"
              : "Ever-evolving software solutions"}{" "}
            | Neuroelectrics
          </title>
          <meta
            name="twitter:title"
            content="twitter:Ever-evolving software solutions | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Ever-evolving software solutions | Neuroelectrics"
          />

          <meta
            name="description"
            content="Manage experiments and interventions in easy steps, analyze data, and integrate signals with our robust software tools"
          />
          <meta
            name="twitter:description"
            content="Manage experiments and interventions in easy steps, analyze data, and integrate signals with our robust software tools"
          />
          <meta
            property="og:description"
            content="Manage experiments and interventions in easy steps, analyze data, and integrate signals with our robust software tools"
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/solutions/software-integrations/"
          />
        </Helmet>

        {/* Single Page Menu */}
        <>
          {width > 766 && <OnepageMenu onepageMenuSections={data.sections} />}
        </>

        {/* Banner */}
        <>
          <Banner
            width={width}
            backgroundWhite={width < 426 && true}
            responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
            heightResponsive={width > 425 && "25vw"}
            justifyDiv="center"
            backgroundPadding={
              width < 575
                ? "center"
                : width > 574 && width < 769
                ? "-20vw"
                : width < 1025 && width > 768
                ? "center"
                : width < 1441 && width > 1024
                ? "center"
                : "center"
            }
            textsPaddingRight={
              width < 575
                ? "0"
                : width > 574 && width < 769
                ? "32%"
                : width < 1025 && width > 768
                ? "22%"
                : width < 1441 && width > 1024
                ? "22%"
                : "22%"
            }
            title={data.banner.title}
            titleFontSize={
              width < 575
                ? "5.8vw"
                : width > 574 && width < 769
                ? "4vw"
                : width < 1025 && width > 768
                ? "2.5vw"
                : width < 1441 && width > 1024
                ? "2.5vw"
                : "2.5vw"
            }
            titleFontWeight="500"
            subtitle={data.banner.subtitle}
            subtitleFontSize={
              width < 575
                ? "4.1vw"
                : width > 574 && width < 769
                ? "2.6vw"
                : width < 1025 && width > 768
                ? "1.7vw"
                : width < 1441 && width > 1024
                ? "1.4vw"
                : "1.4vw"
            }
            subtitleFontWeight="300"
            img={isWebpSupported() ? `/img/banners/${newImg(data.banner.image.path)}` : `/img/banners/${data.banner.image.path}`}
          />
        </>

        {/* Actual Path */}
        <>
          <ActualPath
            levelOne={{ text: "Home", url: "/" }}
            actual={{
              text:
                location.pathname === "/solutions/starstim-integrations"
                  ? "Solutions / fNIRS Integration"
                  : "Solutions / Software"
            }}
            mobileFilterPosition
          >
            {!location.param && (
              <div
                style={
                  width < 575
                    ? {
                        position: "absolute",
                        top: "100%",
                        width: "92%"
                      }
                    : {}
                }
              >
                <Select
                  ref={search}
                  className={
                    isLoading === "block"
                      ? "componentsSelect pointerNone"
                      : "componentsSelect"
                  }
                  placeholder="Filter by product"
                  value={selectedOption}
                  onChange={handleChange}
                  options={data.compatibility_filter}
                />
              </div>
            )}
          </ActualPath>
        </>

        {/* List */}
        <>
          <div style={responsivePadding}>
            <div className={width < 575 ? "pt-5 mt-2 pb-4" : "pb-5"}>
              {data.components_list.map(item => (
                <SoftwareIntegrationsList
                  key={item.title}
                  data={item}
                  width={width}
                />
              ))}
            </div>
          </div>
        </>

        {/* Support */}
        <>
          <div
            style={{
              backgroundImage: isWebpSupported() ? "url(/img/components_support.webp)" : "url(/img/components_support.jpg)",
              backgroundPosition: "center",
              backgroundSize: responsiveBackground,
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <Row
              style={{
                padding: "2%",
                margin: "5%",
                background: "white",
                width: responsiveWidth
              }}
            >
              <Col md={7} className="componentsSupport">
                <h2 style={titlee}>{data.support.title}</h2>
                <p style={subtitle}>{data.support.intro}</p>
              </Col>
              <Col md={5} className="componentsSupport">
                <Link
                  className="componentsSupportLink"
                  to="/resources/manuals"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    marginBottom: "22% !important",
                    width: "100%",
                    padding: "4%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img
                    style={{ width: "2.2vw" }}
                    src={isWebpSupported() ? "/img/head-cap-icon.webp" : "/img/head-cap-icon.png"}
                    alt="/img/head-cap-icon.png"
                  />
                  <h3
                    style={{
                      fontSize: "1vw",
                      paddingLeft: ".8vw",
                      height: "100%",
                      margin: 0,
                      color: "#555555"
                    }}
                  >
                    User manuals{"      "}
                  </h3>
                  <img
                    style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                    src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                    alt="/img/arrow.png"
                  />
                </Link>
                <Link
                  className="componentsSupportLink"
                  to="/support"
                  style={{
                    marginBottom: "22% !important",
                    width: "100%",
                    padding: "5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1vw",
                      height: "100%",
                      margin: 0,
                      color: "#555555"
                    }}
                  >
                    or visit our <u>support page</u>{" "}
                  </h3>

                  <img
                    style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                    src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                    alt="arrow-asset"
                  />
                </Link>
              </Col>
            </Row>
          </div>
        </>
      </div>
    )
  } else {
    return <Loading />
  }
}
export default SoftwareIntegrations
