import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const Uploader = ({ title, section, sectionItems }) => {
  const [file, setFile] = useState(null)

  const [err, setErr] = useState('')

  const onChangeHandler = e => {
    checkFileSize(e) && setFile(e.target.files[0])
  }

  const [category, setCategory] = useState()

  const onChangeSelect = e => {
    setCategory(e.target.value)
  }

  const checkFileSize = event => {
    let files = event.target.files
    let size = 8628406270
    let err = ''
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err += 'File is too large, please pick a smaller file\n'
      }
    }
    if (err !== '') {
      event.target.value = null
      setErr(err)
      console.log(err)
      return false
    }

    return true
  }

  const onClickHandler = type => {
    const data = new FormData()
    data.append('file', file)
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload/${type}`, data, {
        asd: 'asd'
      })
      .then(res => {
        // then print response status
        console.log(res.statusText)
      })
  }
  return (
    <div className='d-flex justify-content-around align-items-center p-5'>
      <div className='w-25 d-flex flex-column justify-content-center align-items-center'>
        <div className='form-group w-100'>
          <h2>{title}</h2>
          <label>Category</label>
          <select className='form-control' onChange={onChangeSelect}>
            {section.map((item, i) => (
              <option>{item.title}</option>
            ))}
          </select>
        </div>
        <div className='form-group w-100'>
          <select className='form-control'>
            {section.map(
              (item, i) =>
                item.title === category &&
                item.items.map((item, i) => <option>{item.title}</option>)
            )}
          </select>
        </div>
        <div className='custom-file'>
          <input
            type='file'
            className='custom-file-input'
            onChange={onChangeHandler}
            id='validatedCustomFile'
            required
          />
          <label className='custom-file-label' for='validatedCustomFile'>
            {file != null ? <p>{file.name}</p> : 'Choose file...'}
          </label>
          <div className='invalid-feedback'>
            Example invalid custom file feedback
          </div>
        </div>
        {err !== '' && (
          <p
            style={{
              color: 'red',
              margin: 0,
              padding: 0,
              marginTop: '1rem'
            }}
          >
            {err}
          </p>
        )}
        <button
          type='button'
          className='btn btn-success btn-block mt-3'
          onClick={() => onClickHandler(title)}
        >
          Upload
        </button>
      </div>
    </div>
  )
}

const Admin = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/resources`)
      .then(response => setData(response.data))
      .catch(error => console.log(error))
  }, [])(
    <div className=''>
      <Uploader title='Manuals' section={data.manuals.categories} />
      <Uploader title='Software' section={data.software.categories} />
    </div>
  )
}

export default Admin
