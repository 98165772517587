import React, { useEffect, useState, Fragment } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getResources } from "../../api/api";

import resourcesHome from "../../contents/solutions_resources";
import ebooks from "../../contents/ebooks";

import Loading from '../../components/Loading';
import Banner from '../../components/Banner';
import ActualPath from '../../components/ActualPath';

import './resources.css';

const content = () => {
  let pdfsStack = [];
  resourcesHome.sections.map(item => {
    const i = item;
    item.pdfs &&
      item.pdfs.map(item => {
        const ref = item;
        ebooks.map(item => {
          ref === item._id && pdfsStack.push(item);
        });
      });
    item.pdfs && (item.pdf = pdfsStack);
  });
  return resourcesHome;
};

const newImg = (img) => img.split(".")[0]+".webp";

const Resources = () => {

  const data = content()
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {

    // async function fetchMyAPI() {
    //   try {
    //     const resources = await getResources();
    //     setData(resources);             
    //   }  catch (error) {
    //     console.log(error);
    //   }        
    // }    
    // fetchMyAPI();


    // setData(content);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '8vw',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: '4vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '3vw'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '2.3vw'
        }
      : {
          fontWeight: 500,
          fontSize: '2vw'
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '4.7vw',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2.8vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1.5vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1.1vw',
          lineHeight: 'initial'
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '1.3vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '16px',
          textDecoration: 'none'
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: 'none',
          fontSize: '2.2vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '1.5vw',
          textDecoration: 'none'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '1.2vw',
          textDecoration: 'none',
          lineHeight: 'initial'
        }
      : {
          fontSize: '1.2vw',
          fontWeight: 500,
          textDecoration: 'none',
          lineHeight: 'initial'
        }

  let boxHeight
  width < 575
    ? (boxHeight = '50vw')
    : width > 574 && width < 769
    ? (boxHeight = '40vw')
    : width > 768 && width < 1025
    ? (boxHeight = '25vw')
    : (boxHeight = '23vw')

  if (data !== undefined) {
    return (
      <Fragment>
        {/* Helmet */}
        <>
          <Helmet>
            <title>Find out here our resources | Neuroelectrics</title>
            <meta
              name="twitter:title"
              content="Find out here our resources | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Find out here our resources | Neuroelectrics"
            />

            <meta
              name="description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              name="twitter:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              property="og:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <link rel="canonical" href="https://neuroelectrics.com/resources" />
          </Helmet>
        </>

        <Switch>
          <Route
            path="/"
            component={() => (
              <Home
                data={data}
                width={width}
                title={title}
                subtitle={subtitle}
                small={small}
                smallbold={smallbold}
                boxHeight={boxHeight}
              />
            )}
          />
        </Switch>
      </Fragment>
    )
  } else {
    return <Loading />
  }
}

const Home = ({
  data,
  width,
  title,
  subtitle,
  small,
  smallbold,
  boxHeight
}) => (
  <div style={{ paddingTop: width < 769 ? '' : '3.56vw' }}>
    {/* Banner */}
    <>
      <Banner
        paddingTop="0"
        mobilePadding="0vw 10vw"
        width={width}
        backgroundWhite={width < 426 && true}
        responsiveBannerBackgroundPaddingLeft={width < 426 && '16%'}
        heightResponsive={width > 425 && '25vw'}
        justifyDiv="center"
        backgroundPadding={
          width < 575
            ? 'center'
            : width > 574 && width < 769
            ? '-30vw'
            : width < 1025 && width > 768
            ? 'center'
            : width < 1441 && width > 1024
            ? 'center'
            : 'center'
        }
        textsPaddingRight={
          width < 575
            ? '0'
            : width > 574 && width < 769
            ? '32%'
            : width < 1025 && width > 768
            ? '22%'
            : width < 1441 && width > 1024
            ? '22%'
            : '22%'
        }
        title={data.banner.title}
        titleFontSize={
          width < 575
            ? '5.8vw'
            : width > 574 && width < 769
            ? '4vw'
            : width < 1025 && width > 768
            ? '2.5vw'
            : width < 1441 && width > 1024
            ? '2.5vw'
            : '2.5vw'
        }
        titleFontWeight="500"
        subtitle={data.banner.subtitle}
        subtitleFontSize={
          width < 575
            ? '4.1vw'
            : width > 574 && width < 769
            ? '2.6vw'
            : width < 1025 && width > 768
            ? '1.7vw'
            : width < 1441 && width > 1024
            ? '1.4vw'
            : '1.4vw'
        }
        subtitleFontWeight="300"
        img={isWebpSupported() ? `/img/banners/resources.webp` : `/img/banners/resources.jpg`}
      />
    </>
    {/* Actual Path */}
    <>
      <div
        style={
          width < 575
            ? {
                paddingLeft: '1vw'
              }
            : {}
        }
      >
        <ActualPath
          levelOne={{ text: 'Home', url: '/' }}
          actual={{ text: 'Resources' }}
        />
      </div>
    </>

    {/* Boxes */}
    <>
      <Container
        fluid
        style={width > 575 ? { paddingLeft: '15vw', paddingRight: '15vw' } : {}}
      >
        <Row>
          {data.sections.map(item => (
            <Item
              data={item}
              parentData={data}
              smallbold={smallbold}
              boxHeight={boxHeight}
              width={width}
            />
          ))}
        </Row>
      </Container>
    </>

    {/* Featured */}
    <>
      <div style={{ background: 'white' }}>
        <Container
          fluid
          style={
            width > 575
              ? {
                  paddingTop: '2%',
                  paddingBottom: '2%',
                  paddingLeft: '16vw',
                  paddingRight: '16vw'
                }
              : {
                  paddingTop: '5%',
                  paddingBottom: '2%'
                }
          }
        >
          <Row>
            {data.featured.map((item, index) => {
              var i = index
              return (
                item.articles.length > 0 && (
                  <Col md={4} lg={4}>
                    <h4>{item.title}</h4>
                    {item.articles.map((item, index) =>
                      i < 2 ? (
                        <div className="mb-4">
                          >
                          <a
                            target="_blank"
                            href={item.url}
                            style={{
                              color: '#333',
                              textDecoration: 'underline'
                            }}
                            rel="noopener noreferrer"
                          >
                            {item.title}
                          </a>
                        </div>
                      ) : (
                        <div className="mb-4">
                          <p>{item.title}</p>
                          <iframe
                            title={`resource-${index}`}
                            style={{ width: '90%', height: '100%' }}
                            src={item.url}
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      )
                    )}
                  </Col>
                )
              )
            })}
          </Row>
        </Container>
      </div>
    </>
  </div>
)

const Item = ({ data, width, smallbold, boxHeight }) => (
  <Col
    md={6}
    lg={4}
    sm={6}
    xs={6}
    className="p-0 resourcesItem w-100"
    style={{ height: 'fit-content' }}
  >
    {data.title === 'Papers' ||
    data.title === 'Blog' ||
    data.title === 'Wiki' ? (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className="w-100 h-100"
      >
        <div
          className={width < 575 ? '' : 'm-1'}
          style={{
            backgroundImage: `url(/img/${data.image.path})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: boxHeight
          }}
        ></div>
      </a>
    ) : (
      <Link
        to={{
          pathname: `${data.url}`,
          state: {
            data: data,
            videos: data.videos,
            pdf: data.pdf,
            param: data.param && data.param
          }
        }}
        className="w-100"
        style={{ height: 'fit-content' }}
      >
        <div
          className={width < 575 ? '' : 'm-1'}
          style={{
            backgroundImage: isWebpSupported() ? `url(/img/${newImg(data.image.path)})` : `url(/img/${data.image.path})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: boxHeight
          }}
        ></div>
      </Link>
    )}
  </Col>
)

export default Resources
