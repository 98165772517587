import React from "react";
import styled from "styled-components";
import {isWebpSupported} from 'react-image-webp/dist/utils';

import Button from "../Button";

const newImg = (img) => img.split(".")[0]+".webp";

const Banner = ({
  justifyDiv,
  title,
  subtitle,
  subtitle1,
  img,
  quote,
  refe,
  backgroundPadding,
  textsPaddingRight,
  backgroundWhite,
  responsiveBannerBackgroundPaddingLeft,
  product,
  button,
  heightResponsive,
  titleFontSize,
  titleFontWeight,
  subtitleFontWeight,
  subtitleFontSize,
  subtitleColor,
  subtitleMarginTop,
  textPosition,
  legend,
  paddingTop,
  mobilePadding,
  width,
  divWidth,
  secondaryBackground,
  neuroelectrics,
  landing,
  landingImage,
  landingSubtitle,
  landingButton,
  video,
  team
}) => {
  const Img = styled.div`
    background-image: ${isWebpSupported() && img ? `url(${newImg(img)})` : `url(${img})`};
    background-position: ${backgroundPadding ? backgroundPadding : "0"};
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${secondaryBackground ? secondaryBackground : ""};
    height: ${heightResponsive ? heightResponsive : "43vw"};
    color: black;
    justify-content: ${justifyDiv ? justifyDiv : "center"}
    padding-top: ${paddingTop ? paddingTop : width > 768 ? "6vw" : ""};

    @media (max-width: 769px) {
      height: 50vw !important;
    }

    @media (max-width: 425px) {
      height: 80vw !important;
    }
  `;

  const Div = styled.div`
    padding-left: ${product || landing ? responsiveBannerBackgroundPaddingLeft : "15vw"};
    padding-right: ${textsPaddingRight ? textsPaddingRight : "15%"};
    width: ${divWidth ? divWidth : "65vw"};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: ${justifyDiv ? justifyDiv : "flex-end"};
    align-content: center;

    @media (max-width: 768px) {
      width: 80vw;
    }

    @media (max-width: 425px) {
      padding: ${mobilePadding ? mobilePadding : "17vw 20vw"};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  const BackgroundDiv = styled.div`
    background: ${backgroundWhite ? "rgba(255,255,255,.8);" : ""};

    padding: 7%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  const Title = styled.h1`
    margin: 0;
    font-weight: ${titleFontWeight ? titleFontWeight : "500"};
    line-height: 1.1;
    font-size: ${titleFontSize ? titleFontSize : "3.4vw"};
    color: #333333 !important;
  `;

  const Subtitle = styled.h2`
    font-weight: ${subtitleFontWeight ? subtitleFontWeight : "600"};
    font-size: ${subtitleFontSize ? subtitleFontSize : "1.4vw"};
    margin: 0;
    margin-top: ${subtitleMarginTop ? subtitleMarginTop : "6%"};
    color: ${subtitleColor ? subtitleColor : "#333 !important"};
  `;

  const Subtitle1 = styled.h2`
  font-weight: ${subtitleFontWeight ? subtitleFontWeight : "600"};
  font-size: ${subtitleFontSize ? subtitleFontSize : "1.4vw"};
  margin: 0 0 20px 0;  
  color: ${subtitleColor ? subtitleColor : "#333 !important"};
  `;

  let textsPosition;
  textPosition &&
    (textsPosition = {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      paddingLeft: "40%",
      paddingRight: "20%",
    });

  return (
    <Img>
      <Div style={textsPosition}>
        {backgroundWhite ? (
          <BackgroundDiv>
            {legend && <p className="m-0">{legend}</p>}

            {titleFontSize ? (
              <Title className="greyText">{title}</Title>
            ) : (
              <h1 className="responsiveBannerTitle">{title}</h1>
            )}

            {subtitleFontSize ? (              
              <Subtitle className="greyText">{subtitle}</Subtitle>              
            ) : (            
              <h2 className="responsiveBannerSubtitle">{subtitle}</h2>              
            )}

            {subtitle1 && subtitleFontSize ? (
              <Subtitle1 className="greyText">{subtitle1}</Subtitle1>
            ) : (
              <h2 className="responsiveBannerSubtitle">{subtitle1}</h2>
            )}

            {quote && (
              <Button
                text="Get a quote"
                url="/get-quote"
                refe={refe}
                width="10vw"
                font="1vw"
              />
            )}

            {button && (
                <Button
                  text={button.text}
                  url={button.url}
                  refe={refe}                  
                  font="3.8vw"
                  fontWeight="500"                  
                />
              )}

          </BackgroundDiv>
        ) : (<>
        {landing ? (
          <div style={{ display: width > 768 && "flex", flexDirection: "row" }}>
            <div style={{ display: width > 768 && "flex", flexDirection: "column"}}>            
              <div style={{ display: width > 500 && "flex", flexDirection: "row"}}>
                {landingImage && (
                  <div>
                    <img
                        src={isWebpSupported() ?  `/img/${newImg(landingImage.path)}` : `/img/${landingImage.path}`}
                        alt={landingImage.title}
                        className={width > 850 ? "w-100": ""}                        
                        style={{ paddingRight: "1vw", width: width < 850 && "20vw"}}
                      />               
                  </div>
                )}
                
                {titleFontSize ? (
                  <Title
                    className={
                      width > 768 ? "greyText mt-3" : "greyText"
                    }
                  >
                    {title}
                  </Title>
                ) : (
                  <h1 className="responsiveBannerTitle">{title}</h1>
                )}
              </div>
              <div style={{ display: width < 575 ? "none" : "flex", flexDirection: "column"}}>
                {landingSubtitle && (
                  <div>
                  {subtitleFontSize ? (              
                    <Subtitle className="greyText mt-3">{landingSubtitle}</Subtitle>              
                  ) : (              
                    <h2 className="responsiveBannerSubtitle">{landingSubtitle}</h2>              
                  )}
                  </div>
                )}

                <div style={{ display: width > 580 && "flex", flexDirection: "row"}} >
                  <div style={{ paddingTop: "50px", marginRight: "50px"}}>
                    {landingButton && (
                      <Button
                        text={landingButton.text}
                        js={landingButton.js}                      
                        width="10vw"                      
                        font={width > 768 ? "1.2vw" : "2.2vw"}
                        fontWeight="500"                  
                      />
                    )}
                  </div>                   
                  {width > 575 && 
                  <div style={{ display: "flex", flexDirection: "row", marginLeft: "50px" }}>                                  
                    {team.map((item, index) => 
                      <div key={index} style={{ paddingTop: "20px"}}>                      
                        <img
                          style={{ borderRadius: '25px', width: '50px', height: '50px', margin: '15px' }}                        
                          src={isWebpSupported() ? `/img/team/${newImg(item.img.path)}` : `/img/team/${item.img.path}`}
                          alt={item.img.title}
                        />                      
                        <p style={{margin: "0 20px", textAlign: "center"}}>{item.name}</p>                 
                      </div>                  
                    )}   
                  </div>}              
                </div>
              </div>           
            </div>         

          {width > 1100 && (
            <div style={{ position: "relative", width: "20%" }}>
              <div
                style={{
                  background: "#fff",
                  height: "20px",
                  width: "65vw",
                  transform: "rotate(105deg)",
                  position: "absolute",
                  left: "-27vw",
                  bottom: 0,
                  top: 0,
                }}
              />
            </div>
          )}

          {video && width > 850 && (
            <iframe
              title="banner_video"
              className="w-100"
              style={{ height: "35vh", marginTop: "30px", paddingLeft: "2%" }}                                
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen={true}
            />
          )}
        </div>
        ) : (
          <div style={{ display: neuroelectrics && width > 768 && "flex" }}>
            {titleFontSize ? (
              <Title
                className={
                  neuroelectrics && width > 768 ? "greyText mt-3" : "greyText"
                }
              >
                {title}
              </Title>
            ) : (
              <h1 className="responsiveBannerTitle">{title}</h1>
            )}

            {neuroelectrics && width > 768 && (
              <div style={{ position: "relative", width: "100%" }}>
                <div
                  style={{
                    background: "#fff",
                    height: "20px",
                    width: "44vw",
                    transform: "rotate(105deg)",
                    position: "absolute",
                    left: "-14vw",
                    bottom: 0,
                    top: 0,
                  }}
                ></div>
              </div>
            )}

            {subtitleFontSize ? (              
              <Subtitle className="greyText mt-3">{subtitle}</Subtitle>              
            ) : (              
              <h2 className="responsiveBannerSubtitle">{subtitle}</h2>              
            )}

            {subtitle1 && subtitleFontSize ? (
              <Subtitle1 className="greyText">{subtitle1}</Subtitle1>
            ) : (
              <h2 className="responsiveBannerSubtitle">{subtitle1}</h2>
            )}

            <div className="">
              {quote && (
                <Button
                  text="Get a quote"
                  url="/get-quote"
                  refe={refe}
                  width="10vw"
                  font="1vw"
                />
              )}
                            
              {button && (
                <Button
                  text={button.text}
                  url={button.url}
                  refe={refe}
                  width="10vw"
                  font="1.2vw"
                  fontWeight="500"                  
                />
              )}
            </div>
          </div>
          )}
          </>
        )}
      </Div>
    </Img>
    
  );
};

export default Banner;