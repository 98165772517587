import React from "react"

import Style from "style-it"

import { Link } from "react-router-dom"

const Button = ({
  text,
  url,
  refe,
  font,
  padding,
  shadow,
  fontWeight,
  marginHorizontal,
  marginVertical,
  radius,
  color,
  background,
  margin,
  js,  
  whiteSpace
}) => {
  return (
    <Style>
      {`
        .button {
          color: ${color ? color : "#ffffff"};
          font-family: 'Roboto';
          font-weight: ${fontWeight ? fontWeight : "400"};
          font-size: ${font ? font : ".8vw"};
          border-radius: ${radius ? radius : "25px"};
          background-image: ${!background && "linear-gradient(to bottom right, #7a92a4, #769cbf)"};
          background-color: ${background ? background : ""};
          box-shadow: ${shadow &&
            "0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19);"};
          border: 0;
          margin-left: ${marginHorizontal ? marginHorizontal : "10px"};
          margin-right: ${marginHorizontal ? marginHorizontal : "10px"};
          margin-top: ${marginVertical ? marginVertical : marginVertical / 2};
          margin-bottom: ${
            marginVertical ? marginVertical : marginVertical / 2
          };
          margin: ${margin && margin};         
          display: inline-block;
          outline: none;
          padding: ${padding ? padding : "15px 25px"};
          transition: all .3s ease-in-out;
          white-space: ${whiteSpace ? whiteSpace : "nowrap"};
          text-align: center;
        }

        .button:hover {
          text-decoration: none;
          background-color: ${background ? background : "#769cbf" };
          box-shadow: ${shadow &&
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19)"};
        }
    `}
      {js ? (
        <a className="button" target="_blank" rel="noopener noreferrer" href={js}>{text}</a>
      ) : (      
        <Link
          className="button"
          to={{
            pathname: `${url}`,
            state: { refe: refe }
          }}
        >
          {text}
        </Link>
      )}
    </Style>
  )
}

export default Button
