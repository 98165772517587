import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import { Link, Redirect } from "react-router-dom"
import axios from "axios"
import {isWebpSupported} from 'react-image-webp/dist/utils';

import ActualPath from "../../components/ActualPath"
import Button from "../../components/Button"
import Loading from "../../components/Loading"

import "./softwareintegrations.css"
import "./softwareintegrationslist.sass"

const SoftwareIntegration = ({ match, location }) => {
  const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState()

  useEffect(() => {
    setLoading(true)

    getData(match.params.id)

    window.addEventListener("resize", handleResize)
  }, [])

  const getData = id =>
    axios
      .post(`${process.env.REACT_APP_API_URL}/softwareintegration`, { id: id })
      .then(res => {
        setData(res.data)
        setLoading(false)
        window.scrollTo(0, 0)
      })

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  if (loading) {
    return <Loading />
  } else if (!loading && data !== undefined && JSON.stringify(data) === "{}") {
    return <Redirect to="/error" />
  } else if (!loading && data !== undefined && data._id) {
    var responsivePadding = {
      paddingLeft: "16vw",
      paddingRight: "16vw",
      background: "#fff"
    }

    if (width < 769) {
      var responsivePadding = {
        paddingLeft: "7vw",
        paddingRight: "7vw",
        background: "#fff"
      }
    }
    const nic2desc = (length,item,i) => {
        if (length - 1 == i){
          return <p style={small}>{item}<a href="https://www.neuroelectrics.com/resources/software"> website.</a></p>
        }else{
          return <p style={small}>{item}</p>
        }
    }
    return (
      <div
        style={{ paddingBottom: "5%" }}
        className={width > 766 ? "pt60px" : ""}
      >
        <Helmet>
          <title>
            {data.banner.title.substring(0, 37)} Software | Neuroelectrics
          </title>
          <meta
            name="twitter:title"
            content={`${data.banner.title.substring(
              0,
              37
            )} Software | Neuroelectrics`}
          />
          <meta
            property="og:title"
            content={`${data.banner.title.substring(
              0,
              37
            )} Software | Neuroelectrics`}
          />

          <meta
            name="description"
            content={
              data.banner.description[0].length > 150
                ? data.banner.description[0].substring(0, 150) + "..."
                : data.banner.description[0]
            }
          />
          <meta
            name="twitter:description"
            content={
              data.banner.description[0].length > 150
                ? data.banner.description[0].substring(0, 150) + "..."
                : data.banner.description[0]
            }
          />
          <meta
            property="og:description"
            content={
              data.banner.description[0].length > 150
                ? data.banner.description[0].substring(0, 150) + "..."
                : data.banner.description[0]
            }
          />
          <link
            rel="canonical"
            href={`https://neuroelectrics.com/solutions/software-integrations/${window.location.pathname
              .split("/")
              .pop()}`}
          />

          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css"
          />
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js" />
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js" />
        </Helmet>

        {/* Actual Path */}
        <>
          <ActualPath
            product
            levelOne={{ text: "Home", url: "/" }}
            levelTwo={
              data.hwintegration
                ? {
                    text: " fNIRS Integration",
                    url: "/solutions/starstim-integrations",
                    levelTwoParam: data.hwintegration
                  }
                : {
                    text: " Software",
                    url: "/solutions/software-integrations"
                  }
            }
            actual={{ text: data.banner.title }}
          />
        </>

        {/* Item */}
        <>
          <Container fluid>
            <Row>
              <Col
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className="d-flex justify-content-center align-items-center p-0"
              >
                <img
                  style={
                    width < 575
                      ? {
                          padding: "4vw",
                          paddingBottom: 0,
                          width: "100%",
                          height: "auto"
                        }
                      : width > 575 && width < 769
                      ? {
                          padding: "4vw",
                          paddingBottom: 0,
                          width: "100%",
                          height: "auto"
                        }
                      : width > 768 && width < 1025
                      ? {
                          paddingLeft: "7vw",
                          paddingBottom: 0,
                          width: "100%",
                          height: "auto"
                        }
                      : {
                          paddingLeft: "7vw",
                          paddingBottom: 0,
                          width: "100%",
                          height: "auto"
                        }
                  }
                  src={isWebpSupported() ? `/img/component/${newImg(data.banner.image.path)}` : `/img/component/${data.banner.image.path}`}
                  alt={`/img/component/${data.banner.image.path}`}
                />
              </Col>
              <Col
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className="d-flex justify-content-center align-items-center"
                style={
                  width < 769
                    ? {
                        width: "100%",
                        paddingRight: "4vw",
                        paddingLeft: "4vw"
                      }
                    : {}
                }
              >
                <Col
                  lg={10}
                  md={12}
                  sm={12}
                  xs={12}
                  style={width < 769 ? { background: "#fff" } : {}}
                >
                  <div>
                    <h3 style={smallbold} className="mb-0">
                      {data.banner.code}
                    </h3>
                    <h1 style={title} className="mt-2">
                      {data.banner.title}
                    </h1>
                    <div>
                        {Array.isArray(data.banner.description) ? (
                        location.pathname == "/solution/software-integrations/nic2" ?          
                          data.banner.description.map((item, i) => (nic2desc(data.banner.description.length,item,i)))          
                          : data.banner.description.map(item => (<p style={small}>{item}</p>))
                          ) 
                        : (<p style={small}>{data.banner.description}</p>)}
                    </div>
                    <h3 style={smallbold} className="mb-0">
                      Technical specifications
                    </h3>
                    <div
                      className="panel-group"
                      id="accordion"
                      className="mt-0"
                    >
                      {data.banner.specifications.map((item, index) => (
                        <div
                          className="panel panel-default"
                          style={{ boxShadow: "none", margin: 0 }}
                        >
                          <div className="panel-heading">
                            <h4 className="panel-title">
                              <a
                                className="accordion-toggle"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href={`#collapse${index}`}
                                style={
                                  width < 575
                                    ? {
                                        fontWeight: 500,
                                        textDecoration: "none"
                                      }
                                    : width > 574 && width < 769
                                    ? {
                                        fontWeight: 500,
                                        textDecoration: "none",
                                        fontSize: "17px"
                                      }
                                    : width < 1025 && width > 768
                                    ? {
                                        fontWeight: 500,
                                        textDecoration: "none"
                                      }
                                    : width < 1441 && width > 1024
                                    ? {
                                        fontWeight: 500,
                                        textDecoration: "none"
                                      }
                                    : {
                                        fontSize: "1.2vw",
                                        fontWeight: 500,
                                        textDecoration: "none"
                                      }
                                }
                              >
                                {item.title}
                              </a>
                            </h4>
                          </div>
                          <div
                            id={`collapse${index}`}
                            className={
                              width > 575
                                ? `panel-collapse collapse ${
                                    index === 0 ? " in" : ""
                                  }`
                                : "panel-collapse collapse"
                            }
                          >
                            <div className="panel-body">
                              {item.items.map(item => (
                                <div>
                                  <p
                                    style={
                                      width < 575
                                        ? {
                                            fontWeight: 300,
                                            fontSize: "16px",
                                            lineHeight: "initial",
                                            marginTop: 0
                                          }
                                        : width > 574 && width < 769
                                        ? {
                                            fontWeight: 300,
                                            fontSize: "2.2vw",
                                            lineHeight: "initial"
                                          }
                                        : width < 1025 && width > 768
                                        ? {
                                            fontWeight: 300,
                                            fontSize: "1.5vw",
                                            lineHeight: "initial"
                                          }
                                        : width < 1441 && width > 1024
                                        ? {
                                            fontWeight: 300,
                                            fontSize: "1.2vw",
                                            lineHeight: "initial"
                                          }
                                        : {
                                            fontWeight: 300,
                                            fontSize: "1vw",
                                            lineHeight: "initial"
                                          }
                                    }
                                  >
                                    <b>·</b> {item}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {(data._id === "solutions_hwintegration_mribipolar" ||
                      data._id === "solutions_hwintegration_fnirs") && (
                      <div
                        className={
                          width < 769
                            ? "h-100 d-flex align-items-center justify-content-center mt-4 mb-4"
                            : "h-100 d-flex align-items-center mt-3 mb-5"
                        }
                      >
                        <Button
                          text="Get quote"
                          url="/get-quote"
                          refe={data._id}
                          marginHorizontal="0"
                          padding={width > 1441 && "15px 27px"}
                          radius={width > 1441 && "50px"}
                          font={
                            width < 575
                              ? "3.8vw"
                              : width > 574 && width < 769
                              ? "2vw"
                              : width < 1025 && width > 768
                              ? "1.6vw"
                              : width < 1441 && width > 1024
                              ? "1.2vw"
                              : "1.1vw"
                          }
                          shadow
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </>

        {/* Compatibles */}
        <>
          {data.compatibility_items.list.length > 0 && (
            <Container fluid style={responsivePadding}>
              <h2 style={title} className="mb-5 mt-5">
                Choose your system
              </h2>
              <Row>
                {data.compatibility_items.list.map(item => (
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    className={width > 575 ? "p-1 mb-5" : "p-0"}
                  >
                    <Col
                      className="p-2"
                      style={{ background: "transparent", height: "100%" }}
                    >
                      <Link
                        onClick={() => getData(item.item_preview.url_param_ref)}
                        to={{
                          pathname: `/solutions/${item.seo_url_family}/${item.seo_url_product}`,
                          state: { refe: item._id }
                        }}
                        className="tile fade-in box h-100"
                      >
                        <img
                          src={isWebpSupported() ? `/img/product/${newImg(item.item_preview.image.path)}` : `/img/product/${item.item_preview.image.path}`}
                          alt={item.item_preview.image.path}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            objectPosition: "0 0"
                          }}
                        />
                        <div
                          className="tile-text p-3"
                          style={{ background: "#fff" }}
                        >
                          <p style={smallbold}>{item.item_preview.title}</p>
                          <p style={small}>{item.item_preview.desc}</p>
                        </div>
                      </Link>
                    </Col>
                  </Col>
                ))}
              </Row>
            </Container>
          )}
        </>
      </div>
    )
  } else {
    return <Loading />
  }
}

export default SoftwareIntegration
