import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { isWebpSupported } from "react-image-webp/dist/utils";

import { Container, Row, Col, Form } from "react-bootstrap";

import PaymentModal from "./PaymentModal";

const country_list = [
  { code: "AF", name: "Afghanistan" },
  { code: "AX", name: "Aland Islands" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarctica" },
  { code: "AG", name: "Antigua And Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia" },
  { code: "BA", name: "Bosnia And Herzegovina" },
  { code: "BW", name: "Botswana" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BR", name: "Brazil" },
  { code: "IO", name: "British Indian Ocean Territory" },
  { code: "BN", name: "Brunei Darussalam" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos (Keeling) Islands" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo" },
  { code: "CD", name: "Congo, Democratic Republic" },
  { code: "CK", name: "Cook Islands" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: `Cote D'Ivoire` },
  { code: "HR", name: "Croatia" },
  { code: "CU", name: "Cuba" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FK", name: "Falkland Islands (Malvinas)" },
  { code: "FO", name: "Faroe Islands" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GF", name: "French Guiana" },
  { code: "PF", name: "French Polynesia" },
  { code: "TF", name: "French Southern Territories" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greece" },
  { code: "GL", name: "Greenland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard Island & Mcdonald Islands" },
  { code: "VA", name: "Holy See (Vatican City State)" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Iran, Islamic Republic Of" },
  { code: "IQ", name: "Iraq" },
  { code: "IE", name: "Ireland" },
  { code: "IM", name: "Isle Of Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "KR", name: "Korea" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LA", name: `Lao People's Democratic Republic` },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libyan Arab Jamahiriya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Macedonia" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia, Federated States Of" },
  { code: "MD", name: "Moldova" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "AN", name: "Netherlands Antilles" },
  { code: "NC", name: "New Caledonia" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolk Island" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palestinian Territory, Occupied" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PN", name: "Pitcairn" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "Reunion" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russian Federation" },
  { code: "RW", name: "Rwanda" },
  { code: "BL", name: "Saint Barthelemy" },
  { code: "SH", name: "Saint Helena" },
  { code: "KN", name: "Saint Kitts And Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "MF", name: "Saint Martin" },
  { code: "PM", name: "Saint Pierre And Miquelon" },
  { code: "VC", name: "Saint Vincent And Grenadines" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Sao Tome And Principe" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "GS", name: "South Georgia And Sandwich Isl." },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SJ", name: "Svalbard And Jan Mayen" },
  { code: "SZ", name: "Swaziland" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syrian Arab Republic" },
  { code: "TW", name: "Taiwan" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TZ", name: "Tanzania" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad And Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TC", name: "Turks And Caicos Islands" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "UM", name: "United States Outlying Islands" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "VG", name: "Virgin Islands, British" },
  { code: "VI", name: "Virgin Islands, U.S." },
  { code: "WF", name: "Wallis And Futuna" },
  { code: "EH", name: "Western Sahara" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
];

const Payments = () => {
  const [requiredFilled, setRequiredFilled] = useState(true);
  const [userData, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => setWidth(window.innerWidth);

  const send = (e) => {
    e.preventDefault();
    const name = e.target[0].value;
    const email = e.target[1].value;
    const phone = e.target[2].value;
    const company = e.target[4].value;
    const invoicenumber = e.target[5].value;

    const country = country_list.find((el) => el.code === e.target[3].value);

    const amount = parseInt(e.target[6].value*100);
    
    const currency = e.target[7].value;
    let apiKey;
    currency === "EUR"
      ? (apiKey = "pk_live_9wS6jwUQff8VQKVpAUmdFT4n")
      : (apiKey = "pk_live_CO8tbug3oRGsCHfnosmjA3DQ");

    setData({
      name,
      email,
      phone,
      country,
      company,
      invoicenumber,
      amount,
      currency,
      apiKey,
    });
    setRequiredFilled(false);
  };

  return (
    <div
      style={{
        paddingTop: "5.5vw",
        paddingBottom: "2.5vw",
        background: isWebpSupported()
          ? `url(/img/workshop.webp) no-repeat right`
          : `url(/img/workshop.jpg) no-repeat right`,
        backgroundSize: "cover",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Helmet */}
      <>
        <Helmet>
          <title>Payments | Neuroelectrics</title>
          <meta name="twitter:title" content="Payments | Neuroelectrics" />
          <meta property="og:title" content="Payments | Neuroelectrics" />

          <meta
            name="description"
            content="NEUROELECTRICS® BARCELONA, S.L.U. collects and processes personal data in accordance with the EU General Data Protection Regulation (GDPR). See the Neuroelectrics® Barcelona S.L.U. PRIVACY POLICY for further details."
          />
          <meta
            name="twitter:description"
            content="NEUROELECTRICS® BARCELONA, S.L.U. collects and processes personal data in accordance with the EU General Data Protection Regulation (GDPR). See the Neuroelectrics® Barcelona S.L.U. PRIVACY POLICY for further details.

"
          />
          <meta
            property="og:description"
            content="NEUROELECTRICS® BARCELONA, S.L.U. collects and processes personal data in accordance with the EU General Data Protection Regulation (GDPR). See the Neuroelectrics® Barcelona S.L.U. PRIVACY POLICY for further details.

"
          />
          <link rel="canonical" href="https://neuroelectrics.com/payments" />
        </Helmet>
      </>

      <div
        style={{
          width: width < 575 ? "90%" : "70%",
          background: "white",
          padding: width < 575 ? 0 : "3%",
          paddingTop: width < 575 ? "7%" : "3%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: "20px",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
          src={
            isWebpSupported()
              ? require("../../assets/img/neurocience_.webp")
              : require("../../assets/img/neurocience_.png")
          }
          alt="neuroscience_"
        />

        {!requiredFilled ? (
          <PaymentModal userData={userData} />
        ) : (
          <Form className="w-100 pl-5 pr-5" onSubmit={send}>
            <Container>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="name">
                    <Form.Label className=" text-left w-100">
                      Full Name*
                    </Form.Label>
                    <Form.Control required type="text" placeholder="Name" />
                  </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className=" text-left w-100">
                      Email address*
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="your_email@example.com"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="phone">
                    <Form.Label className=" text-left w-100">
                      Phone Number
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter email" />
                  </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="country">
                    <Form.Label className=" text-left w-100">
                      Country*
                    </Form.Label>
                    <Form.Control required as="select">
                      <option></option>
                      {country_list.map((item, i) => (
                        <option key={i} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="companyname">
                    <Form.Label className=" text-left w-100">
                      Company name
                    </Form.Label>
                    <Form.Control type="text" placeholder="Company" />
                  </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="invoicenumber">
                    <Form.Label className=" text-left w-100">
                      Invoice, Quotation Number or Concept*:
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Invoice or Quotation Number:"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="amount">
                    <Form.Label className=" text-left w-100">
                      Amount*:
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      max={5000}
                      min={0}
                      step={0.01}
                      precision={2}
                      placeholder="Amount (e.g. 100.67, max 5000)"
                    />
                    <small
                      id="emailHelp"
                      className="form-text text-muted  text-left w-100"
                    >
                      If you want to specify cents, use point.
                    </small>
                  </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <Form.Group controlId="currency">
                    <Form.Label className=" text-left w-100">
                      Currency*
                    </Form.Label>
                    <Form.Control required as="select">
                      <option></option>
                      <option value="USD">US dollar</option>
                      <option value="EUR">Euro</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Container>

            <button
              type="submit"
              className="animatedButtonOn small mt-4"
              style={{ border: "none" }}
            >
              Pay
            </button>
          </Form>
        )}

        <p className="w-75 pt-5 small">
          NEUROELECTRICS® BARCELONA, S.L.U. collects and processes personal data
          in accordance with the EU General Data Protection Regulation (GDPR).
          See the Neuroelectrics® Barcelona S.L.U. PRIVACY POLICY for further
          details.
        </p>

        <img
          style={{
            height: "20px",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
          src={
            isWebpSupported()
              ? require("../../assets/img/neurocience_.webp")
              : require("../../assets/img/neurocience_.png")
          }
          alt="neuroscience_"
        />
      </div>
    </div>
  );
};

export default Payments;
