export const onepageMenuSections = {
  title: "EEG family page",
  sections: [
    { title: "Features" },
    { title: "Interoperability" },
    { title: "Family comparison" },
    { title: "Case study" },
    { title: "Enobio 8" },
    { title: "Go to action", buttonAction: "#" }
  ]
}

export const menuSections = [
  {
    title: "Neuroscience",
    submenu: [
      {
        title: "Technologies",
        submenu: [
          {
            path: "/neuroscience/eeg",
            title: "EEG"
          },
          {
            path: "/neuroscience/brainstimulation",
            title: "Brain Stimulation"
          },
          {
            path: "/neuroscience/home-therapy",
            title: "Telemedicine"
          }
        ]
      },
      {
        title: "Research Applications",
        path: "/neuroscience/research-applications"
      }
    ]
  },
  {
    title: "Solutions",
    submenu: [
      {
        title: "Enobio EEG",
        path: "/solutions/enobio"
      },
      {
        title: "Starstim Stimulation",
        path: "/solutions/starstim"
      },
      {
        title: "Software",
        path: "/solutions/software-integrations"
      },
      {
        title: "Spare Parts and Consumables",
        path: "/solutions/spareparts-consumables"
      },
      {
        title: "fNIRS Integration",
        path: "/solutions/starstim-integrations",
        param: "fnirs-mri"
      },
      {
        title: "Montage optimization",
        submenu: [
          {
            title: "Modeling Services",
            path: "/solutions/modeling-services"
          },
          {
            title: "Target Editor",
            path: "/solutions/target-editor"
          }
        ]
      }
    ]
  },
  {
    title: "Neuroelectrics",
    submenu: [
      {
        title: "Who we are",
        path: "/neuroelectrics/whoweare"
      },
      {
        title: "Team",
        path: "/neuroelectrics/team"
      },
      {
        title: "Distributors",
        path: "/neuroelectrics/distributors"
      },
      {
        title: "Careers",
        path: "/neuroelectrics/careers"
      },
      {
        title: "News & Events",
        path: "/neuroelectrics/news-events"
      }
    ]
  },
  {
    title: "Resources",
    path: "/resources"
  },  
  {
    title: "Contact",
    submenu: [
      {
        title: "Get a quote",
        path: "/get-quote"
      },
      {
        title: "Get customer support",
        path: "/support"
      },
      {
        title: "Get info",
        path: "/contact"
      }      
    ]
  }
]
