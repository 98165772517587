import axios from 'axios';

// GET ROUTES

export const getHome = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/home`)
		.then(res => res.data);
};

export const getResources = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/resources`)
		.then(res => res.data);
};

export const getSupport = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/support`)
		.then(res => res.data);
};

export const getEeg = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroscience/eeg`)
		.then(res => res.data);
};

export const getBrainstimulation = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroscience/brainstimulation`)
		.then(res => res.data);
};

export const getTelemedicine = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroscience/home-therapy`)
		.then(res => res.data);
};

export const getResearchApplications = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroscience/research-applications`)
		.then(res => res.data);
};

export const getClinical = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/clinical`)
		.then(res => res.data);
};

export const getWhoWeAre = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroelectrics/whoweare`)
		.then(res => res.data);
};

export const getTeam = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroelectrics/team`)
		.then(res => res.data);
};

export const getCareers = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroelectrics/careers`)
		.then(res => res.data);
};

export const getInbound = (id) => {
	return axios.get(`${process.env.REACT_APP_API_URL}/inbound/${id}`)
		.then(res => res.data);
};

export const getSoftware = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/resources/software`)
		.then(res => res.data);
};

export const downloadManuals = name => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/downloads/${name}`, {
      responseType: "blob"
    })
    .then(res => {
      return window.open(`${process.env.REACT_APP_API_URL}/downloads/${name}`);
    });
};

export const getWebinar = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/webinar`)
		.then(res => res.data);
};

export const getCollaborators = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroelectrics/collaborators`)
		.then(res => res.data);
};

export const getNews = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/neuroelectrics/newsandevents`)
		.then(res => res.data);
};

export const getEnobioFam = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/eeg_family`)
		.then(res => res.data);
};

export const getStarstimFam = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/starstim_family`)
		.then(res => res.data);
};

export const getStarstimHomeFam = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/starstim_home_family`)
		.then(res => res.data);
};

export const getModelingServices = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/modelingservices`)
		.then(res => res.data);
};

export const getLegals = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/legals`)
		.then(res => res.data);
};


// POST ROUTES

// export const postCareers = (application, files) => {
// 	return axios.post(`${process.env.REACT_APP_API_URL}/neuroelectrics/careers/${application.position}/${application.name}/${application.lastname}/${application.email}`, files)
// 		.then(res => window.location.reload() && window.scrollTo(0, 0));
// };


