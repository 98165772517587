import React from "react"
import loading from "../../assets/img/loading.gif"

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        paddingTop: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999999999999999999999999999
      }}
    >
      <img src={loading} style={{ height: "25%" }} alt="loading" />
    </div>
  )
}

export default Loading
