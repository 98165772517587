import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const PaymentModal = (props) => {
  const { userData } = props;
  const stripePromise = loadStripe(userData.apiKey);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm userData={userData} />
    </Elements>
  );
};

export default PaymentModal;
