import React, { useEffect, useState } from "react"
import HubspotForm from "react-hubspot-form"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { isWebpSupported } from 'react-image-webp/dist/utils';

import axios from "axios"

import "./quote.css"

var hsSent = false;

const Quote = ({ location, history }) => {
  const [width, setWidth] = useState(window.innerWidth)

  const submitted = () => {
    hsSent = true;
    history.push("/quote-thank-you")
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  let submitForm = {};

  const send = form => {
    let country
    form.map(item => {
      return item.name === "country" && (country = item.value)
    })
    axios
      .post(`${process.env.REACT_APP_API_URL}/form`, {
        country,
        form: form,
        type: "quote"
      })
      .then(res => res.data)
      .catch(err => console.log(err))

    setTimeout(() => {
      if (!hsSent) {
        axios.post(`${process.env.REACT_APP_API_URL}/send-emails`, {
          country,
          form: form,
          type: "quote"
        }).then(res => res.data);
      }
    }, 20000)
  }

  const title =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "8vw",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "3vw"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "2.3vw"
            }
            : {
              fontWeight: 500,
              fontSize: "2vw"
            }

  const subtitle =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "4.7vw",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1.5vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1.1vw",
              lineHeight: "initial"
            }

  // DINAMIC "WHICH NEUROELECTRICS PRODUCTS ARE YOU INTERESTED IN?"

  // Listener to form loading state
  const [formReady, setFormReady] = useState(false)

  // HTML Select Tag
  var whichProductInterested
  formReady &&
    (whichProductInterested = document.getElementById(
      `which_neuroelectrics_products_are_you_interested_in_-${process.env.REACT_APP_QUOTE_HBSPT_FORM_ID}`
    ))

  // HTML Checkboxes Tag for Starstim (only if Starstim is selected)
  var starstimOption = document.getElementsByClassName(
    "hs_starstim_integrations_"
  )

  // Checkboxes hidden by default
  starstimOption[0] !== undefined && (starstimOption[0].style.display = "none")

  // Checkboxes show when Starstim is selected
  var selected
  whichProductInterested !== null &&
    starstimOption[0] !== undefined &&
    whichProductInterested.addEventListener("change", function (e) {
      selected = e.target.value
      selected === "Starstim (Stimulation)"
        ? (starstimOption[0].style.display = "block")
        : (starstimOption[0].style.display = "none")
      console.log(selected)
    })

  // Switch for selecting by default depending on LOCATION.STATE.REFE (this is sent from the screen where the get a quote button was clicked)
  if (location.state && location.state.refe) {
    if (whichProductInterested != null) {
      setTimeout(() => {
        // Starstim integrations checkboxes
        var first = document.getElementById(
          `starstim_integrations_0-${process.env.REACT_APP_QUOTE_HBSPT_FORM_ID}`
        )

          ; (location.state.refe === "solutions_hwintegration_mribipolar") &&
            (first.checked = true)

        var second = document.getElementById(
          `starstim_integrations_1-${process.env.REACT_APP_QUOTE_HBSPT_FORM_ID}`
        )

        location.state.refe === "solutions_hwintegration_fnirs" &&
          (second.checked = true)
      }, 500)

        // Enobio
        ; (location.state.refe === "solutions_family_enobio" ||
          location.state.refe === "enobio_32" ||
          location.state.refe === "enobio_20" ||
          location.state.refe === "enobio_8") &&
          (whichProductInterested.selectedIndex = "1")

      // Starstim
      console.log(location.state.refe)
        ; (location.state.refe === "solutions_family_starstim" ||
          location.state.refe === "starstim_32" ||
          location.state.refe === "starstim_20" ||
          location.state.refe === "starstim_8" ||
          location.state.refe === "starstim_tes" ||
          location.state.refe === "solutions_hwintegration_mribipolar" ||
          location.state.refe === "solutions_hwintegration_fnirs") &&
          (whichProductInterested.selectedIndex = "2") &&
          (starstimOption[0].style.display = "block")

        // Starstim Home
        ; (location.state.refe === "starstimhometeseeg" ||
          location.state.refe === "starstimhometes" ||
          location.state.refe === "solutions_family_starstimhome") &&
          (whichProductInterested.selectedIndex = "3")

      // Spare Parts
      location.state.refe === "spareparts" &&
        (whichProductInterested.selectedIndex = "5")

      // Modeling Services
      location.state.refe === "modelingservices" &&
        (whichProductInterested.selectedIndex = "4")
    }
  }

  return (
    <div
      style={
        width > 768
          ? {
            background: isWebpSupported() ? `url(/img/inbound2.webp) no-repeat` : `url(/img/inbound2.png) no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            paddingLeft: "15vw",
            paddingRight: "15vw",
            paddingBottom: "5vw"
          }
          : {
            background: isWebpSupported() ? `url(/img/inbound2.webp) no-repeat` : `url(/img/inbound2.png) no-repeat`,
            backgroundSize: "contain"
          }
      }
    >
      {/* Helmet */}
      <>
        <Helmet>
          <title>Get a quote on our products here | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Get a quote on our products here | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Get a quote on our products here | Neuroelectrics"
          />

          <meta
            name="description"
            content="Get in contact with us and ask for a quotation for the products you are interested in."
          />
          <meta
            name="twitter:description"
            content="Get in contact with us and ask for a quotation for the products you are interested in."
          />
          <meta
            property="og:description"
            content="Get in contact with us and ask for a quotation for the products you are interested in."
          />
          <link rel="canonical" href="https://neuroelectrics.com/get-quote" />

          <meta name="robots" content="noindex" />
        </Helmet>
      </>

      <Container className={width > 575 ? "pt60px" : "p-0"} fluid>
        <div className="pt-5 pb-3" style={width < 575 ? { padding: "3%" } : {}}>
          <h1 style={title}>Your request for quotation</h1>
          <h2 style={subtitle} className="m-0">
            Get a quote on our products here
          </h2>
        </div>

        <div
          className="getQuoteForm"
          style={
            width < 575
              ? {
                paddingLeft: "3vw",
                paddingRight: "3vw",
                paddingBottom: "5vw"
              }
              : {}
          }
        >
          <HubspotForm
            portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
            formId={process.env.REACT_APP_QUOTE_HBSPT_FORM_ID}
            loading={<div>Loading...</div>}
            onFormSubmitted={() => submitted()}
            onSubmit={form => {
              submitForm = form
              send(submitForm)
            }}
            onReady={setFormReady}
          />
        </div>
      </Container>
    </div>
  )
}

export default Quote
