import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { isWebpSupported } from 'react-image-webp/dist/utils';
import downloadImage from '../../assets/img/download.png'

import Button from "../Button"

const newImg = (img) => img.split(".")[0] + ".webp";

const Resource = ({ item, width, smallbold, small }) => (
  <Col
    xl={2}
    lg={3}
    md={3}
    sm={4}
    xs={6}
    className={width < 575 ? "mb-4" : "m-0"}
    style={{
      padding:
        width > 575 && width < 1024
          ? ".7%"
          : width < 1441 && width > 1023
            ? "1%"
            : "1%"
    }}
  >
    <div
      className="d-flex flex-column align-items-start "
      style={{
        background: "#fff",
        border: "1px solid #fff",
        height: "100%"
      }}
    >
      <img
        className="w-100"
        src={isWebpSupported() ? `/img/ebooks/${newImg(item.image.path)}` : `/img/ebooks/${item.image.path}`}
        alt={item.image.path}
      />
      <div className="d-flex flex-column align-items-start justify-content-between h-100">
        <p style={smallbold} className="pl-3 pr-3 pt-3">
          {item.title}
        </p>
        <p style={small} className="pl-3 pr-3">
          {item.subtitle}
        </p>

        <div
          className="d-flex flex-column align-items-center justify-content-end w-100 border-0 pb-3"
          style={width > 575 ? { height: "100%" } : {}}
        >
          <Button
            width="10vw"
            marginHorizontal="2vw"
            marginVertical="2%"
            padding={
              width > 1441
                ? "10px 25px"
                : width < 575
                  ? "10px 20px"
                  : "15px 25px"
            }
            radius={width > 1441 && "50px"}
            font={
              width < 575
                ? "3.8vw"
                : width > 574 && width < 769
                  ? "2vw"
                  : width < 1025 && width > 768
                    ? "1.6vw"
                    : width < 1441 && width > 1024
                      ? "1.2vw"
                      : "1.1vw"
            }
            shadow
            url={item.url + item._id}
            refe={item._id}
            text="Read more"
          />
        </div>
      </div>
    </div>
  </Col>
)

const Resources = ({ data, title, id }) => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [window.location.pathname])

  const handleResize = () => setWidth(window.innerWidth)

  const titlee =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "8vw",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "3vw"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "2.3vw"
            }
            : {
              fontWeight: 500,
              fontSize: "2vw"
            }

  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "1.3vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }

  const smallbold =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "16px",
        textDecoration: "none"
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "1.5vw",
            textDecoration: "none"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "1.2vw",
              textDecoration: "none",
              lineHeight: "initial"
            }
            : {
              fontSize: "1.2vw",
              fontWeight: 500,
              textDecoration: "none",
              lineHeight: "initial"
            }

  return (
    <Container fluid id={id}>
      <Row
        style={width < 769 ? { flexWrap: "wrap-reverse" } : {}}
        className={`justify-content-center ${width < 575
            ? "p-2 m-auto"
            : width < 769 && width > 575
              ? "p-5"
              : width < 1025 && width > 768
                ? "pl-5 pt-5 pb-5 pr-1"
                : width < 1441 && width > 1024
                  ? "pl-5 pt-5 pb-5 pr-4"
                  : "p-5"
          }`}
      >
        {data.map((item, index) => (
          <Resource
            key={index}
            item={item}
            width={width}
            smallbold={smallbold}
            small={small}
          />
        ))}

        <Col
          lg={3}
          md={width > 768 && 3}
          className={
            width < 576
              ? "d-flex justify-content-center flex-column m-4 p-0"
              : width > 575 && width < 769
                ? "d-flex justify-content-center flex-row m-4 p-0"
                : width > 768 && width < 1441
                  ? "d-flex justify-content-center flex-column pl-3"
                  : "d-flex justify-content-center flex-column pl-5 w-25"
          }
        >
          <img
            style={
              width < 575
                ? { width: "13%", padding: "1.5%" }
                : width > 574 && width < 769
                  ? { width: "10%", padding: "2%" }
                  : width < 1025 && width > 768
                    ? { width: "20%", padding: "3%" }
                    : width < 1441 && width > 1024
                      ? { width: "17%", padding: "3%" }
                      : { width: "10%", marginBottom: "5%" }
            }
            src={downloadImage}
            alt="download"
          />
          <h2 className="m-0" style={titlee}>
            <span style={width < 575 ? { fontSize: "80%" } : {}}>{title}</span>
          </h2>
        </Col>
      </Row>
    </Container>
  )
}

export default Resources

{
  /* 
    width < 575
      ? { MOBILE } 
      : width > 574 && width < 769
      ? { TABLET / IPAD }
      : width < 1025 && width > 768 
      ? { DESKTOP }
      : width < 1441 && width > 1024 
      ? { DESKTOP L }
      : { 4K }
  */
}
