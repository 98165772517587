import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import {isWebpSupported} from 'react-image-webp/dist/utils';

import resourcesHome from "../../../contents/solutions_resources";
import ebooks from "../../../contents/ebooks";
import neuroelectrics_newsandevents from "../../../contents/neuroelectrics_newsandevents";
import streamings from "../../../contents/streamings";

import HubspotForm from "react-hubspot-form"

import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import ActualPath from "../../../components/ActualPath";

const content = () => {
  let pdfsStack = [];
  resourcesHome.sections.map(item => {
    const i = item;
    item.pdfs &&
      item.pdfs.map(item => {
        const ref = item;
        ebooks.map(item => {
          ref === item._id && pdfsStack.push(item);
        });
      });
    item.pdfs && (item.pdf = pdfsStack);
  });
  return resourcesHome;
};

const newImg = (img) => img.split(".")[0]+".webp";

const Live = () => {

  const data = content();
  
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {    

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth)

  const colors = [
    "#e9d8e0",
    "#d5dce2",    
    "#e6efef",
    "#e9d8e0",
    "#d5dce2",    
    "#e6efef",
    "#e9d8e0",
    "#d5dce2",    
    "#e6efef",
    "#e9d8e0",
    "#d5dce2",    
    "#e6efef",   
  ]

  
  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }
    
    const videoHeight = (width) => {
      if (width < 575) {
        return "220px"
      }
      return "40%"
    }

  if (data !== undefined && streamings !== undefined) {
    return (
      <div className={width < 994 ? "marginTop0" : "pt60px"}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>Live Streaming | Resources | Neuroelectrics</title>
            <meta
              name="twitter:title"
              content="Live Streaming | Resources | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Live Streaming | Resources | Neuroelectrics"
            />

            <meta
              name="description"
              content="Join our webinars now"
            />
            <meta
              name="twitter:description"
              content="Join our webinars now"
            />
            <meta
              property="og:description"
              content="Join our webinars now"
            />
            <link
              rel="canonical"
              href="https://neuroelectrics.com/resources/live"
            />
          </Helmet>
        </>      
        
        <ActualPath
          resources
          levelOne={{ text: "Home", url: "/" }}
          levelTwo={{ text: "Resources", url: "/resources" }}
          actual={{ text: "Live Streaming" }}
        />

        <Container
          style={{
            background: "#f5f5f5",
            paddingBottom: "3%"
          }}
        >     
             
          <Row>            
            <Col xs={12} md={7}  style={{ padding:  "2%" }}>
            
              <h1 className="pt-4 pb-4 mb-0">Live Streaming</h1>
              <iframe
                width="99%"
                height={videoHeight(width)}                    
                src={streamings.defaultYoutubeURL}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                allowfullscreen="true"
              />

              <div style={{ display: width < 575 ? "none": "block" }}>
                <h4 className="pt-4 pb-4 mb-0">Subscribe to our newsletter</h4>
                <p>In order to keep up to date with our agenda, please subscribe to our newsletter</p>
                <div className="getQuoteForm">
                  <HubspotForm
                    portalId="181943"
                    formId="38213054-8ce5-413d-b4fd-e5bfd2177ece"
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>  
                
            </Col>   
            <Col style={{ margin: "0 5%", padding: width < 575 ? "0" : "1%" }}>
              <h2 className="pt-4 pb-4 mb-0">Upcoming events</h2>
            
            {streamings.events.map((item, i) => (
                <Row key={i}>
                  <div
                    style={{
                      background: colors[i],
                      margin: "1%",                      
                      padding: "6%",
                      width: "100%",
                      height: "100%"                      
                    }}
                  >
                    <h5 >
                      {item.title}
                    </h5>
                    <h6><i className="fa fa-calendar"/> {item.date} </h6>
                    <h6><i className="fa fa-hand-o-right"/>  Add to  <a target="_blank" href={item.calendarURL}><img border="0" src="https://www.google.com/calendar/images/ext/gc_button1_en.gif"/></a>
                    </h6>                  
                    <p>{item.speakers}</p>
                    <p>{item.description}</p>                    
                  </div>
                </Row>
              ))}  

             <div style={{ display: width < 575 ? "block": "none" }}>
                <h4 className="pt-4 pb-4 mb-0">Subscribe to our newsletter</h4>
                <p>In order to keep up to date with our agenda, please subscribe to our newsletter</p>
                <div className="getQuoteForm">
                  <HubspotForm
                    portalId="181943"
                    formId="38213054-8ce5-413d-b4fd-e5bfd2177ece"
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>  
            </Col>  
          </Row>

        </Container>
        <div style={{ background: "white" }}>

          {/* PDFs */}
        
          <Container
            fluid
            style={
              width > 575 ? { paddingLeft: "15vw", paddingRight: "15vw" } : {}
            }
          >
            <h1 style={title} className="pt-4 pb-4 pl-3">
              Find out here our Ebooks, Whitepapers & Case Studies
            </h1>

            <Row className="pl-4 pr-4">
              {data.sections[1].pdf.map((item, index) => (
                <Col
                  xl={3}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={6}
                  className={width < 575 ? "mb-4" : "m-0"}
                  style={{
                    padding:
                      width > 575 && width < 1024
                        ? ".7%"
                        : width < 1441 && width > 1023
                        ? "1%"
                        : "1%"
                  }}
                >
                  <div
                    className="d-flex flex-column align-items-start "
                    style={{
                      background: "#fff",
                      border: "1px solid #fff",
                      height: "100%"
                    }}
                  >
                    <img
                      className="w-100"
                      src={isWebpSupported() ? `/img/ebooks/${newImg(item.image.path)}` : `/img/ebooks/${item.image.path}`}
                      alt={item.image.path}
                    />
                    <div className="d-flex flex-column align-items-start justify-content-between h-100">
                      <p style={smallbold} className="pl-3 pr-3 pt-3">
                        {item.title}
                      </p>
                      <p style={small} className="pl-3 pr-3">
                        {item.subtitle}
                      </p>

                      <div
                        className="d-flex flex-column align-items-center justify-content-end w-100 border-0 pb-3"
                        style={width > 575 ? { height: "100%" } : {}}
                      >
                        <Button
                          width="10vw"
                          marginHorizontal="2vw"
                          marginVertical="2%"
                          padding={
                            width > 1441
                              ? "10px 15px"
                              : width < 575
                              ? "10px 20px"
                              : "15px 25px"
                          }
                          radius={width > 1441 && "50px"}
                          font={
                            width < 575
                              ? "3.8vw"
                              : width > 574 && width < 769
                              ? "2vw"
                              : width < 1025 && width > 768
                              ? "1.6vw"
                              : width < 1441 && width > 1024
                              ? "1.2vw"
                              : "1.1vw"
                          }
                          shadow
                          url={item.url + item._id}
                          refe={item._id}
                          text="Read more"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>     

        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

export default Live
