import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

import "./CheckoutForm.css";

const CheckoutForm = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const {
    userData: {
      name,
      email,
      phone,
      country,
      company,
      invoicenumber,
      amount,
      currency,
    },
  } = props;

  const description = `Name: ${name} / Email: ${email} / Company: ${company} / Country: ${country.name} / Phone Number: ${phone} / Invoice: ${invoicenumber}`;

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
        headers: {
          "Content-Type": "application/json",
        },
        amount,
        currency,
        description,
      })
      .then((res) => res.data)
      .then((data) => {
        setClientSecret(data.clientSecret);
      });
  }, [amount, currency, description]);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            country: country.code,
          },
          email,
          name,
          phone,
        },
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/payments`, props.userData)
        .catch((error) => {
          console.log("Sending email error: ", error);
        });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={{ hidePostalCode: true }}
        onChange={handleChange}
      />
      <button disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? <div className="spinner" id="spinner"></div> : "Pay"}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded. The confirmation email has been sent to {email}.
      </p>
    </form>
  );
};

export default CheckoutForm;
