import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getCollaborators } from "../../../api/api";

import neuroelectrics_collaborators from "../../../contents/neuroelectrics_collaborators";

import { Link } from "react-router-dom";

import ActualPath from "../../../components/ActualPath";
import Loading from "../../../components/Loading";
import Banner from "../../../components/Banner";

const Collaborators = ({ location }) => {
  const [width, setWidth] = useState(window.innerWidth);
  // const [data, setData] = useState();
  const data = neuroelectrics_collaborators;

  useEffect(() => {
    location.state && location.state.toPartners && window.scroll(0, 100)

    // async function fetchMyAPI() {
    //   try {
    //     const collaborators = await getCollaborators();
    //     setData(collaborators);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    // setData(neuroelectrics_collaborators);

    window.addEventListener("resize", handleResize)
      return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth);
  
  const newImg = (img) => img.split(".")[0]+".webp";

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>Distributors & Partners | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Distributors & Partners | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Distributors & Partners | Neuroelectrics"
          />

          <meta
            name="description"
            content="In case you are based in a country where there is no official Neuroelectrics® distributor, please submit a quote request or contact Neuroelectrics® team."
          />
          <meta
            name="twitter:description"
            content="In case you are based in a country where there is no official Neuroelectrics® distributor, please submit a quote request or contact Neuroelectrics® team."
          />
          <meta
            property="og:description"
            content="In case you are based in a country where there is no official Neuroelectrics® distributor, please submit a quote request or contact Neuroelectrics® team."
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/neuroelectrics/distributors"
          />
        </Helmet>
      </>

      {/* Banner */}
      <>
        {width > 575 && (
          <div
            style={
              width < 575
                ? {
                    background:
                      "linear-gradient(to bottom right, #f7f6f5, #eee)"
                  }
                : {}
            }
          >
            <Banner
              width={width}
              backgroundWhite={width < 426 && true}
              responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
              heightResponsive={width > 425 && "15vw"}
              justifyDiv="center"
              paddingTop="3.4vw"
              textsPaddingRight={
                width < 575
                  ? "0"
                  : width > 574 && width < 769
                  ? "22%"
                  : width < 1025 && width > 768
                  ? "22%"
                  : width < 1441 && width > 1024
                  ? "22%"
                  : "22%"
              }
              title={data.banner.title}
              titleFontSize={
                width < 575
                  ? "5.8vw"
                  : width > 574 && width < 769
                  ? "4vw"
                  : width < 1025 && width > 768
                  ? "2.5vw"
                  : width < 1441 && width > 1024
                  ? "2.5vw"
                  : "2.5vw"
              }
              titleFontWeight="400"
            />
          </div>
        )}
      </>

      {/* Actual Path */}
      <>
        <ActualPath
          levelOne={{ text: "Home", url: "/" }}
          actual={{ text: "Neuroelectrics / Distributors" }}
        />
      </>

      <div className="p-5">
        <Container>
          <Row>
            <h2 style={title}>{data.title}</h2>
            <h2 style={subtitle}>{data.subtitle}</h2>
          </Row>
        </Container>
      </div>

      <img
        className="w-100"
        src={`/img/collaborators/${
          width > 575 ? (isWebpSupported() ? newImg(data.image.path) : data.image.path) : 
          (isWebpSupported() ? newImg(data.image.mobile) : data.image.mobile)
        }`}
        alt={data.image.title}
      />

      <div className={width > 575 ? "p-5" : "p-1"}>
        <Container fluid>
          <Row className="justify-content-center">
            {data.continents.map((item, i) => (
              <Col xs={6} md={3} lg={2} key={i}>
                <h4 style={title} className="pt-4 m-0">
                  {item.title}
                </h4>
                <br></br>
                {item.items.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      borderBottom: "1px solid black",
                      marginBottom: "20px"
                    }}
                  >
                    <p style={smallbold}>{item.title}</p>
                    {item.items.map((item, i) => (
                      <div
                        key={i}
                        style={{
                          margin: "1%",
                          padding: "1%"
                        }}
                      >
                        {item.link ? (
                          <a
                            target="_blank"
                            style={small}
                            href={item.link}
                            className="mb-0"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            style={small}
                            href={`mailto: ${item.mail}`}
                            className="mb-0"
                          >
                            {item.title}
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </Col>
            ))}
          </Row>
          <Row className="w-100 d-flex justify-content-center p-5">
            <Link
              to="/contact"
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: width < 1025 ? "1.1em" : "1.1vw",
                borderRadius: "50px",
                backgroundImage:
                  "linear-gradient(to bottom right, #7a92a4, #769cbf)",
                boxShadow:
                  "0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 17px 0 rgba(0, 0, 0, 0.19)",
                border: 0,
                outline: "none",
                padding: "13px 45px",
                transition: "all .3s ease-in-out",
                whiteSpace: "nowrap"
              }}
            >
              Become a distributor
            </Link>
          </Row>
        </Container>
      </div>

      <div style={{ background: "#e5e5e5" }}>
        <Container id="ourpartners">
          <Row className="d-block p-5">
            <h3 style={title}>Our partners</h3>
            <h5 style={subtitle}>
              Below you can find a list of our partners offering integrations of
              our technology with NIRS, Biosensors, and TMS.
            </h5>
          </Row>
          <Row className="pb-3">
            {data.partners.map((item, i) => (
              <Col
                xs={12}
                sm={12}
                md={4}
                className={width < 575 ? "mb-4 p-0" : ""}
              >
                <a href={item.link} target="_blank">
                  <div
                    style={{
                      margin: "2%",
                      background: "white",
                      padding: "15%",
                      height: "100%"
                    }}
                  >
                    <img
                      className="w-100 pb-4"
                      src={isWebpSupported() ? `/img/collaborators/${newImg(item.image)}` : `/img/collaborators/${item.image}`}
                      alt={item.image}
                    />
                    <p className="mb-0" style={small}>
                      {item.text}
                    </p>
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default Collaborators
