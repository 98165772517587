import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getResources } from "../../../api/api";

import resourcesHome from "../../../contents/solutions_resources";
import ebooks from "../../../contents/ebooks";

import Banner from "../../../components/Banner";
import Loading from "../../../components/Loading";
import ActualPath from "../../../components/ActualPath";

const content = () => {
  let pdfsStack = [];
  resourcesHome.sections.map(item => {
    const i = item;
    item.pdfs &&
      item.pdfs.map(item => {
        const ref = item;
        ebooks.map(item => {
          ref === item._id && pdfsStack.push(item);
        });
      });
    item.pdfs && (item.pdf = pdfsStack);
  });
  return resourcesHome;
};

const Videos = ({ location }) => {

  const data = content();
  // const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {

    // async function fetchMyAPI() {
    //   try {
    //     const resources = await getResources();
    //     setData(resources);             
    //   }  catch (error) {
    //     console.log(error);
    //   }        
    // }    
    // fetchMyAPI();


    // setData(content);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  if (data != undefined) {
    return (
      <div className={width < 994 ? "marginTop0" : "pt60px"}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>Videos | Resources | Neuroelectrics</title>
            <meta
              name="twitter:title"
              content="Videos | Resources | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Videos | Resources | Neuroelectrics"
            />

            <meta
              name="description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              name="twitter:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              property="og:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <link
              rel="canonical"
              href="https://neuroelectrics.com/resources/videos"
            />
          </Helmet>
        </>

        {/* Banner */}
        <>
          <Banner
            paddingTop="0"
            mobilePadding="0vw 10vw"
            width={width}
            backgroundWhite={width < 426 && true}
            responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
            heightResponsive={width > 425 && "25vw"}
            justifyDiv="center"
            backgroundPadding={
              width < 575
                ? "center"
                : width > 574 && width < 769
                ? "-30vw"
                : width < 1025 && width > 768
                ? "center"
                : width < 1441 && width > 1024
                ? "center"
                : "center"
            }
            textsPaddingRight={
              width < 575
                ? "0"
                : width > 574 && width < 769
                ? "32%"
                : width < 1025 && width > 768
                ? "22%"
                : width < 1441 && width > 1024
                ? "22%"
                : "22%"
            }
            title={data.banner.title}
            titleFontSize={
              width < 575
                ? "5.8vw"
                : width > 574 && width < 769
                ? "4vw"
                : width < 1025 && width > 768
                ? "2.5vw"
                : width < 1441 && width > 1024
                ? "2.5vw"
                : "2.5vw"
            }
            titleFontWeight="500"
            subtitle={data.banner.subtitle}
            subtitleFontSize={
              width < 575
                ? "4.1vw"
                : width > 574 && width < 769
                ? "2.6vw"
                : width < 1025 && width > 768
                ? "1.7vw"
                : width < 1441 && width > 1024
                ? "1.4vw"
                : "1.4vw"
            }
            subtitleFontWeight="300"
            img={isWebpSupported() ? `/img/banners/resources.webp` : `/img/banners/resources.jpg`}
          />
        </>

        <ActualPath
          resources
          levelOne={{ text: "Home", url: "/" }}
          levelTwo={{ text: "Resources", url: "/resources" }}
          actual={{ text: "Videos" }}
        />

        <Container
          style={{
            background: "#f5f5f5",
            paddingBottom: "3%"
          }}
        >
          <h2 className="pt-4 pb-4 mb-0">Latest Videos</h2>
          <Row>
            {data.sections[4].videos.map(item => (
              <Col md={6} lg={6} style={{ padding: width < 575 ? "0" : "1%" }}>
                <iframe
                  width="100%"
                  height="315"
                  src={item.src}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                  allowfullscreen
                />
              </Col>
            ))}
          </Row>
        </Container>
        <div style={{ background: "white" }}>
          <Container
            fluid
            style={
              width > 575
                ? {
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    paddingLeft: "16vw",
                    paddingRight: "16vw"
                  }
                : {
                    paddingTop: "5%",
                    paddingBottom: "2%"
                  }
            }
          >
            <Row>
              {data.featured.map((item, index) => {
                var i = index
                return (
                  item.articles.length > 0 && (
                    <Col md={4} lg={4}>
                      <h4>{item.title}</h4>
                      {item.articles.map((item, index) =>
                        i < 2 ? (
                          <div className="mb-4">
                            >
                            <a
                              target="_blank"
                              href={item.url}
                              rel="noopener noreferrer"
                              style={{
                                color: "#333",
                                textDecoration: "underline"
                              }}
                            >
                              {item.title}
                            </a>
                          </div>
                        ) : (
                          <div className="mb-4">
                            <p>{item.title}</p>
                            <iframe
                              title={`resource-${index}`}
                              style={{ width: "90%", height: "100%" }}
                              src={item.url}
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )
                      )}
                    </Col>
                  )
                )
              })}
            </Row>
          </Container>
        </div>
      </div>
    )
  } else {
    return <Loading />
  }
}

export default Videos
