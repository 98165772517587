// JSON'S DATA // 

import enobio_product_8 from "../contents/solutions_product_enobio8.json";
import enobio_product_20 from "../contents/solutions_product_enobio20.json";
import enobio_product_32 from "../contents/solutions_product_enobio32.json";
import enobio_product_dx from "../contents/solutions_product_enobiodx.json";

import starstim_product_8 from "../contents/solutions_product_starstim8.json";
import starstim_product_20 from "../contents/solutions_product_starstim20.json";
import starstim_product_32 from "../contents/solutions_product_starstim32.json";

import solutions_product_starstimhometes from "../contents/solutions_product_starstimhometes.json";
import solutions_product_starstimtes from "../contents/solutions_product_starstimtes.json";

import solutions_accessory_ngpistim from "../contents/solutions_accessory_ngpistim.json";
import solutions_accessory_foretrode from "../contents/solutions_accessory_foretrode.json";
import solutions_accessory_earclip from "../contents/solutions_accessory_earclip.json";
import solutions_accessory_drytrode from "../contents/solutions_accessory_drytrode.json";
import solutions_accessory_headband from "../contents/solutions_accessory_headband.json";
import solutions_accessory_sticktrode from "../contents/solutions_accessory_sticktrode.json";
import solutions_accessory_nggeltrode from "../contents/solutions_accessory_nggeltrode.json";
import solutions_accessory_cap from "../contents/solutions_accessory_cap.json";
import solutions_accessory_caphome from "../contents/solutions_accessory_caphome.json";
import solutions_accessory_capkid from "../contents/solutions_accessory_capkid.json";
import solutions_accessory_cappro from "../contents/solutions_accessory_cappro.json";
import solutions_accessory_punchtool from "../contents/solutions_accessory_punchtool.json";
import solutions_accessory_sponstim from "../contents/solutions_accessory_sponstim.json";
import solutions_accessory_electrodecable from "../contents/solutions_accessory_electrodecable.json";
import solutions_accessory_electrodecablecustom from "../contents/solutions_accessory_electrodecablecustom.json";
import solutions_accessory_electrodecablehome from "../contents/solutions_accessory_electrodecablehome.json";
import solutions_accessory_gel from "../contents/solutions_accessory_gel.json";
import solutions_accessory_saline from "../contents/solutions_accessory_saline.json";
import solutions_accessory_syringe from "../contents/solutions_accessory_syringe.json";
import solutions_accessory_testboard from "../contents/solutions_accessory_testboard.json";
import solutions_accessory_ttltrigger from "../contents/solutions_accessory_ttltrigger.json";
import solutions_accessory_usbisolator from "../contents/solutions_accessory_usbisolator.json";
import solutions_accessory_cablesetenobio20 from "../contents/solutions_accessory_cablesetenobio20.json";
import solutions_accessory_cablesetenobio32 from "../contents/solutions_accessory_cablesetenobio32.json";
import solutions_accessory_cablesetstarstim20 from "../contents/solutions_accessory_cablesetstarstim20.json";
import solutions_accessory_cablesetstarstim32 from "../contents/solutions_accessory_cablesetstarstim32.json";

import solutions_software_eeglabplugin from "../contents/solutions_software_eeglabplugin.json";
import solutions_software_lsltcpintegrations from "../contents/solutions_software_lsltcpintegrations.json";
import solutions_software_matnic2 from "../contents/solutions_software_matnic2.json";
import solutions_software_nic2 from "../contents/solutions_software_nic2.json";
import solutions_software_portal from "../contents/solutions_software_portal.json";
import solutions_software_sdk from "../contents/solutions_software_sdk.json";

import solutions_software_fnirs from "../contents/solutions_hwintegration_fnirs.json";

export let products = [];
products.push(enobio_product_32);
products.push(enobio_product_20);
products.push(enobio_product_8);
products.push(enobio_product_dx);
products.push(starstim_product_32);
products.push(starstim_product_20);
products.push(starstim_product_8);
products.push(solutions_product_starstimhometes);
products.push(solutions_product_starstimtes);

export let accessoriesStack = [];
accessoriesStack.push(solutions_accessory_testboard);
accessoriesStack.push(solutions_accessory_ttltrigger);
accessoriesStack.push(solutions_accessory_usbisolator);
accessoriesStack.push(solutions_accessory_cablesetenobio20);
accessoriesStack.push(solutions_accessory_cablesetenobio32);
accessoriesStack.push(solutions_accessory_cablesetstarstim20);
accessoriesStack.push(solutions_accessory_cablesetstarstim32);
accessoriesStack.push(solutions_accessory_electrodecable);
accessoriesStack.push(solutions_accessory_electrodecablecustom);
accessoriesStack.push(solutions_accessory_electrodecablehome);
accessoriesStack.push(solutions_accessory_gel);
accessoriesStack.push(solutions_accessory_saline);
accessoriesStack.push(solutions_accessory_syringe);
accessoriesStack.push(solutions_accessory_foretrode);
accessoriesStack.push(solutions_accessory_earclip);
accessoriesStack.push(solutions_accessory_drytrode);
accessoriesStack.push(solutions_accessory_headband);
accessoriesStack.push(solutions_accessory_sticktrode);
accessoriesStack.push(solutions_accessory_nggeltrode);
accessoriesStack.push(solutions_accessory_cap);
accessoriesStack.push(solutions_accessory_caphome);
accessoriesStack.push(solutions_accessory_capkid);
accessoriesStack.push(solutions_accessory_cappro);
accessoriesStack.push(solutions_accessory_punchtool);
accessoriesStack.push(solutions_accessory_sponstim);
accessoriesStack.push(solutions_accessory_ngpistim);

export let softwaresStack = [];
softwaresStack.push(solutions_software_eeglabplugin);
softwaresStack.push(solutions_software_lsltcpintegrations);
softwaresStack.push(solutions_software_matnic2);
softwaresStack.push(solutions_software_nic2);
softwaresStack.push(solutions_software_portal);
softwaresStack.push(solutions_software_sdk);

export let fnirsmriStack = [];
fnirsmriStack.push(solutions_software_fnirs);
