import React from "react"
import { Helmet } from "react-helmet"
import {isWebpSupported} from 'react-image-webp/dist/utils';
import "./manualsrequestthanks.scss"

export default class ThanksPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      seconds: 10
    }

    this.componentWillMount = () => {
      if (this.state.seconds > 0) {
        this.interval = setInterval(
          () => this.setState({ seconds: this.state.seconds - 1 }),
          1000
        )
        this.interval = setInterval(
          () => window.location.replace("http://neuroelectrics.com/"),
          10000
        )
      }
    }
    this.componentWillUnmount = () => {
      clearInterval(this.interval)
    }
  }

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Helmet>
          <title>Thanks Page</title>
          <meta name="description" content="Thanks" />
        </Helmet>

        <div
          className="d-flex align-items-center justify-center flex-column manualsThanks"
          style={{ width: "50vw" }}
        >
          <div className="manualsThanksHeader" style={{ marginTop: "5%" }}>
            <div className="col-6 manualsThanksHeaderContainerText ">
              <img
                src={isWebpSupported() ? require("./nelogolight.webp") : require("./nelogolight.png")}
                className="manualsThanksHeaderLogoLight w-50"
              />
              <p className="manualsThanksHeaderP" style={{ color: "#fff" }}>
                Mobile brain signal sensing and stimulation systems
              </p>
              <h1 className="manualsThanksHeaderH1" style={{ color: "#fff" }}>
                Neuroelectrics thanks you for your time.
              </h1>
              <h2 className="manualsThanksHeaderH2" style={{ color: "#fff" }}>
                Sales will contact you.
              </h2>
              <p className="manualsThanksHeaderP" style={{ color: "#fff" }}>
                Redirecting to neuroelectrics.com in
                <strong>
                  <span id="countdown"> {this.state.seconds} </span> seconds
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
