import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getEeg, getBrainstimulation, getTelemedicine, getResearchApplications } from "../../api/api";

import neuroscience_eeg from "../../contents/neuroscience_eeg";
import neuroscience_stimulation from "../../contents/neuroscience_stimulation";
import neuroscience_telemedicine from "../../contents/neuroscience_telemedicine";
import neuroscience_researchapplications from "../../contents/neuroscience_researchapplications";
import ebooks from "../../contents/ebooks";

import ActualPath from '../../components/ActualPath';
import Loading from '../../components/Loading';
import Resources from '../../components/Resources';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import './neuroscience.css'

const content = (location) => {
  
  let page;
  switch (location) {
    case '/neuroscience/eeg':
      page = neuroscience_eeg;
      break;
    case '/neuroscience/brainstimulation':
      page = neuroscience_stimulation;
      break;
    case '/neuroscience/home-therapy':
      page = neuroscience_telemedicine;
      break;
    case '/neuroscience/research-applications':
      page = neuroscience_researchapplications;
      break;
    default:
  }   

  const ref = page.resources.reference_ids;
  let resources = [];
  ebooks.map(item => {
    const i = item;
    ref.map(item => {
      const ii = item;
      ii === i._id && resources.push(i);
    });
  });
  page.resources.resources_items = resources;

  return page;
};

const newImg = (img) => img.split(".")[0]+".webp";

const Neuroscience = ({ location }) => {

  const data = content(location.pathname)
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)
  const [scrollPosition, setScrollPosition] = useState(15)
  const [button, setButton] = useState('animatedButton')

   // APPLE LIKE BODY WIDTH RESPONSIVE TO SCROLL
  //const [parallaxWidth, setparallaxWidth] = useState('200px')
  //const [parallaxContainerFluid, setparallaxContainerFluid] = useState(false)

  
  useEffect(() => {
    window.scrollTo(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     let page;
    //     switch (location.pathname) {
    //       case '/neuroscience/eeg':
    //         page = await getEeg();
    //         break;
    //       case '/neuroscience/brainstimulation':
    //         page = await getBrainstimulation();
    //         break;
    //       case '/neuroscience/home-therapy':
    //         page = await getTelemedicine();  
    //         break;
    //       case '/neuroscience/research-applications':
    //         page = await getResearchApplications();  
    //         break;  
    //       default: 
    //     }
    //     setpage(page);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();


    // setData(content(location.pathname))

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', scroll)
    return () => {
      window.removeEventListener('scroll', scroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [window.location.pathname]);


  const handleResize = () => setWidth(window.innerWidth)

  const scroll = () => {
    setScrollPosition(window.scrollY)

    let mobileButtonScrollPosition
    width > 425
      ? (mobileButtonScrollPosition = 1100)
      : (mobileButtonScrollPosition = 3200)
    document.documentElement.scrollTop +
      window.innerHeight +
      mobileButtonScrollPosition >
    document.documentElement.offsetHeight
      ? setButton(
          `${width < 575 ? 'animatedButtonOn small' : 'animatedButtonOn'}`
        )
      : setButton(`${width < 575 ? 'animatedButton small' : 'animatedButton'}`)

    // APPLE LIKE BODY WIDTH RESPONSIVE TO SCROLL
    //setparallaxContainerFluid(window.scrollY / 700 > 4.3 ? true : false)
    //let a = document.getElementById('neuroscienceParallaxContainer').offsetWidth
    //let c = window.scrollY / 700
    //setparallaxWidth(a * c)
  }

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '8vw',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: '4vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '3vw'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '2.3vw'
        }
      : {
          fontWeight: 500,
          fontSize: '2vw'
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '4.7vw',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2.8vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1.5vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1.1vw',
          lineHeight: 'initial'
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '1.3vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '16px',
          textDecoration: 'none'
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: 'none',
          fontSize: '2.2vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '1.5vw',
          textDecoration: 'none'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '1.2vw',
          textDecoration: 'none',
          lineHeight: 'initial'
        }
      : {
          fontSize: '1.2vw',
          fontWeight: 500,
          textDecoration: 'none',
          lineHeight: 'initial'
        }

  return data !== undefined ? (
    <div className={width > 575 && 'pt60px'} id="neuroscience">
      <Helmet>
        <title>{data.title + ` Neuroscience Awarness | Neuroelectrics`}</title>
        <meta
          name="twitter:title"
          content={data.title + ' Neuroscience Awarness | Neuroelectrics'}
        />
        <meta
          property="og:title"
          content={data.title + ' Neuroscience Awarness | Neuroelectrics'}
        />

        <meta
          name="description"
          content={
            data.desc[0].desc[0].text.length > 150
              ? data.desc[0].desc[0].text.substring(0, 110) + '...'
              : data.desc[0].desc[0].text
          }
        />
        <meta
          name="twitter:description"
          content={
            data.desc[0].desc[0].text.length > 150
              ? data.desc[0].desc[0].text.substring(0, 110) + '...'
              : data.desc[0].desc[0].text
          }
        />
        <meta
          property="og:description"
          content={
            data.desc[0].desc[0].text.length > 150
              ? data.desc[0].desc[0].text.substring(0, 110) + '...'
              : data.desc[0].desc[0].text
          }
        />
        <link
          rel="canonical"
          href={`https://neuroelectrics.com/neuroscience/${window.location.pathname
            .split('/')
            .pop()}`}
        />
      </Helmet>

      <ActualPath
        levelOne={{ text: 'Home', url: '/' }}
        actual={{ text: data.actualPath }}
      />

      <div className={width > 575 ? 'p-0' : 'p-0'}>
        <Container>
          <Row
            style={
              width > 575
                ? scrollPosition > 155
                  ? {
                      background: isWebpSupported() ? `url(/img/${newImg(data.image.path)}) no-repeat 50% 26% / 45vw fixed` : `url(/img/${data.image.path}) no-repeat 50% 26% / 45vw fixed`,
                      height: '40vw'
                    }
                  : {
                      background: isWebpSupported() ? `url(/img/${newImg(data.image.path)}) no-repeat 50% 100px / 45vw ` : `url(/img/${data.image.path}) no-repeat 50% 100px / 45vw `,
                      height: '40vw'
                    }
                : {
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: isWebpSupported() ? `url(/img/${newImg(data.image.path)})` : `url(/img/${data.image.path})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    height: '500px',
                    margin: 0
                  }
            }
          >
            <h1
              style={{
                color: '#333',
                fontSize: width < 575 ? '3em' : '4em',
                paddingTop: '2%'
              }}
            >
              {data.title}
            </h1>
          </Row>
        </Container>
      </div>
      <div className={width > 575 ? 'pr-0 pl-0 pb-0 pt-0' : 'p-0'}>
        <Container className="p-0">
          {width > 575 ? (
            <img
              style={{ height: '20px' }}
              className="d-flex w-100"
              src={isWebpSupported() ? require('../../assets/img/neurocience_.webp') : require('../../assets/img/neurocience_.png')}
              alt="neuroelectrics-asset-bar"
            />
          ) : (
            <div
              style={{
                borderBottom: '3px solid #555',
                height: '1px',
                marginLeft: '4.1vw',
                marginRight: '4.1vw'
              }}
            ></div>
          )}
        </Container>

        <Container
          fluid={true}
          className={
            width < 575 ? 'position-relative' : 'position-relative pb-4'
          }
          id="neuroscienceParallaxContainer"
        >
          {/*
          // APPLE LIKE BODY WIDTH RESPONSIVE TO SCROLL //
          
          <div
            className='d-none w-100 h-100 justify-content-center align-items-end position-absolute'
            tabIndex='-1'
            style={{ top: 0, left: 0 }}
          >
            <div
              className='neuroscienceParallax'
              style={{
                background: '#fff',
                width: parallaxWidth,
                height: '100%'
              }}
            ></div>
          </div>
          */}
          <Container
            style={{ zIndex: 9999 }}
            className={width < 575 ? '' : 'mb-4'}
          >
            {data.desc.map((item, i) => (
              <Row
                className={width > 575 ? 'p-5' : 'pt-2'}
                style={{
                  background: '#fff',
                  borderBottom: '2px solid #f7f6f5',
                  zIndex: 1
                }}
              >
                <Row className={width < 575 ? 'm-0 p-2' : 'm-0'}>
                  <Col
                    xs={12}
                    sm={12}
                    md={
                      window.location.pathname ===
                      '/neuroscience/research-applications'
                        ? 12
                        : 4
                    }
                  >
                    {i === 0 ? (
                      <h1
                        style={{
                          color: '#333',
                          fontWeight: '500',
                          fontSize: width > 575 ? '2.1em' : '1.5em',
                          paddingBottom: '1%',
                          paddingTop: width < 575 && '2%'
                        }}
                      >
                        {item.title}
                      </h1>
                    ) : (
                      <h2
                        style={{
                          color: '#333',
                          fontWeight: '500',
                          fontSize: width > 575 ? '2.1em' : '1.5em',
                          paddingBottom: '1%',
                          paddingTop: width < 575 && '2%'
                        }}
                      >
                        {item.title}
                      </h2>
                    )}
                  </Col>

                  <Col>
                    {item.desc.map((item, index) => (
                      <div>
                        <p style={subtitle}>
                          {index > 0 &&
                          window.location.pathname ===
                            '/neuroscience/research-applications' ? (
                            <b>{item.text}</b>
                          ) : (
                            item.text
                          )}
                        </p>
                        {item.image && (
                          <img
                            className={width > 525 ? 'w-25' : 'w-100 '}
                            src={isWebpSupported() ? `/img/${newImg(item.image)}` : `/img/${item.image}`}
                            alt={item.alt}
                          ></img>
                        )}
                      </div>
                    ))}
                  </Col>

                  {item.list &&
                    item.list.map((desease, index) => (
                      <div
                        style={{
                          borderBottom: index < 2 && '1px solid lightgray',
                          margin: '1%',
                          paddingTop: '4%',
                          paddingBottom: '4%'
                        }}
                      >
                        <h2 style={smallbold}>{desease.category}</h2>
                        <Row
                          style={{
                            padding: '2%'
                          }}
                        >
                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            className="d-flex align-items-center"
                          >
                            <img
                              className={width < 575 ? 'w-100 p-2' : 'w-100'}
                              src={isWebpSupported() ? `/img/${desease.image &&
                                newImg(desease.image.path)}` : `/img/${desease.image &&
                                desease.image.path}`}
                              alt={desease.image && desease.image.alt}
                            />
                          </Col>
                          <Col>
                            <p style={subtitle}>
                              <b>{desease.title}</b>
                            </p>
                            <p style={subtitle}>{desease.text}</p>

                            <div className="d-flex">
                              {desease.links &&
                                desease.links.map(link => (
                                  <a
                                    href={link.link}
                                    target="_blank"
                                    style={{ maxWidth: '300px' }}
                                  >
                                    <img
                                      src={isWebpSupported() ? require('../../assets/img/ico_document.webp') : require('../../assets/img/ico_document.png')}
                                      alt="resources-user-manuals"
                                    />
                                    <p style={{ textDecoration: 'underline' }}>
                                      {link.title}
                                    </p>
                                  </a>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                </Row>
              </Row>
            ))}

            {data.table && (
              <Row
                className={width > 575 ? 'p-5' : 'pt-4 pb-4'}
                style={{
                  background: '#fff',
                  borderTop: '2px solid #f7f6f5'
                }}
              >
                <Col>
                  <Table
                    className="neuroscienceTable"
                    style={{ maxWidth: '100vw' }}
                  >
                    {data.table.map((item, index) =>
                      index === 0 ? (
                        <Thead>
                          <Tr>
                            {item.map(item => (
                              <Th style={{ padding: '5%' }}>{item.text}</Th>
                            ))}
                          </Tr>
                        </Thead>
                      ) : (
                        <Tbody>
                          <Tr>
                            {item.map(item => (
                              <Td style={{ padding: '5%' }}>{item.text}</Td>
                            ))}
                          </Tr>
                        </Tbody>
                      )
                    )}
                  </Table>
                </Col>
              </Row>
            )}

            {location.pathname === '/neuroscience/research-applications' && (
              <Row
                className={
                  width < 575
                    ? 'flex-column p-3 casesLinks'
                    : 'flex-column p-5 casesLinks'
                }
                style={{
                  background: '#fff',
                  borderTop: '2px solid #f7f6f5'
                }}
              >
                <h2 style={title} className="mb-4">
                  Applications of tDCS/tACS/tRNS
                </h2>
                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 1</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Stroke"
                    style={{ color: '#555' }}
                  >
                    {'   '}Stroke
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 2</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Pain"
                    style={{ color: '#555' }}
                  >
                    {'   '} Pain
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 3</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Depression"
                    style={{ color: '#555' }}
                  >
                    {'   '}Depression
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 4</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Tinnitus"
                    style={{ color: '#555' }}
                  >
                    {'   '} Tinnitus
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 5</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Epilepsy"
                    style={{ color: '#555' }}
                  >
                    {'   '}Epilepsy
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 6</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Migraine"
                    style={{ color: '#555' }}
                  >
                    {'   '} Migraine
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 7</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Addictive_disorders"
                    style={{ color: '#555' }}
                  >
                    {'   '}Addictive disorders
                  </a>
                </p>

                <p style={subtitle}>
                  <span style={{ color: '#555 !important' }}>
                    <b> 8</b>
                  </span>
                  <a
                    href="https://www.neuroelectrics.com/wiki/index.php/Montages_for_tCS#Cognitive_enhancement"
                    style={{ color: '#555' }}
                  >
                    {'   '} Cognitive enhancement
                  </a>
                </p>
              </Row>
            )}
            {data.readMore && (
              <Row className={width > 575 ? 'p-5' : 'p-4'}>
                <Col className="d-flex justify-content-center">
                  <Link className={button} to={data.readMore} style={small}>
                    Read more
                  </Link>
                </Col>
              </Row>
            )}
          </Container>
        </Container>
      </div>

      {data.resources.resources_items.length > 0 && (
        <Resources
          data={data.resources.resources_items}
          title={data.resources.title}
        />
      )}
    </div>
  ) : (
    <Loading />
  )
}

export default Neuroscience
