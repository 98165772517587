import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {isWebpSupported} from 'react-image-webp/dist/utils';

import Button from '../../../components/Button'

import axios from 'axios'

const QuoteThankyou = ({ location }) => {
  const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.scroll(0, 0)

    axios
      .get(`/`)
      .then(res => setData(res.data))
      .catch(err => console.log(err))

    window.addEventListener('resize', handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  return (
    data != undefined && (
      <div
        className={width > 575 && 'pt60px'}
        style={{
          background: isWebpSupported() ? `url(/img/workshop.webp) no-repeat right` : `url(/img/workshop.jpg) no-repeat right`,
          backgroundSize: 'cover',
          width: '100%',
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            background: 'white',
            padding: '3%',
            textAlign: 'center',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            style={{
              height: '20px',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%'
            }}
            src={isWebpSupported() ? require('../../../assets/img/neurocience_.webp') : require('../../../assets/img/neurocience_.png')}
            alt='neuroscience_'
          />
          <h1>Thank you!</h1>
          <p
            style={{
              fontWeight: 400,
              color: '#555',
              width: '100%'
            }}
          >
            Our sales team will get in contact with you.
          </p>
          <Button
            text='Home'
            url='/'
            marginHorizontal='1vw'
            padding={width > 1441 && '10px 30px'}
            radius={
              width > 1441 ? '50px' : width < 1440 && width > 768 && '75px'
            }
            font={
              width < 575
                ? '3.8vw'
                : width > 574 && width < 769
                ? '2vw'
                : width < 1025 && width > 768
                ? '1.6vw'
                : width < 1441 && width > 1024
                ? '1.2vw'
                : '1.1vw'
            }
            shadow
          />

          <img
            style={{
              height: '20px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%'
            }}
            src={isWebpSupported() ? require('../../../assets/img/neurocience_.webp') : require('../../../assets/img/neurocience_.png')}
            alt='neuroscience_'
          />
        </div>
      </div>
    )
  )
}

export default QuoteThankyou
