import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getTeam } from "../../../api/api";

import neuroelectrics_team from "../../../contents/neuroelectrics_team";

import ActualPath from "../../../components/ActualPath";
import Loading from "../../../components/Loading";
import Banner from "../../../components/Banner";

const newImg = (img) => img.split(".")[0]+".webp";

const Team = () => {

  const data = neuroelectrics_team;
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.scroll(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const team = await getTeam();
    //     setData(team);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    // setData(neuroelectrics_team);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);
  
  const handleResize = () => setWidth(window.innerWidth)  

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 500,
          color: "#555",
          fontSize: "4.3vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          color: "#555",
          fontSize: "2.5vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          color: "#555",
          fontSize: "1.8vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          color: "#555",
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 500,
          color: "#555",
          fontSize: ".9vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          color: "#333",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          color: "#333",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          color: "#333",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          color: "#333",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: ".8vw",
          color: "#333",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 600,
          fontSize: "4.3vw",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 600,
          textDecoration: "none",
          fontSize: "2.5vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 600,
          fontSize: "1.8vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 600,
          fontSize: "1.3vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: ".9vw",
          fontWeight: 600,
          textDecoration: "none",
          lineHeight: "initial"
        }

  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>An experienced team | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="An experienced team | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="An experienced team | Neuroelectrics"
          />

          <meta
            name="description"
            content="An experienced vison-driven implementation team. Neuroelectrics® was founded in Barcelona in 2012 and opened another office in Boston."
          />
          <meta
            name="twitter:description"
            content="An experienced vison-driven implementation team. Neuroelectrics® was founded in Barcelona in 2012 and opened another office in Boston."
          />
          <meta
            property="og:description"
            content="An experienced vison-driven implementation team. Neuroelectrics® was founded in Barcelona in 2012 and opened another office in Boston."
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/neuroelectrics/team"
          />
        </Helmet>
      </>

      {/* Banner */}
      <>
        <Banner
          paddingTop="3.6vw"
          mobilePadding="3% 2% 6% 2%"
          width={width}
          backgroundWhite={width < 426 && true}
          responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
          heightResponsive={width > 425 && "25vw"}
          justifyDiv="center"
          backgroundPadding={
            width < 575
              ? "center"
              : width > 574 && width < 769
              ? "0"
              : width < 1025 && width > 768
              ? "center"
              : width < 1441 && width > 1024
              ? "center"
              : "center"
          }
          textsPaddingRight={
            width < 575
              ? "0"
              : width > 574 && width < 769
              ? "0"
              : width < 1025 && width > 768
              ? "0"
              : width < 1441 && width > 1024
              ? "0"
              : "0"
          }
          divWidth="85%"
          title={data.team.title}
          titleFontSize={
            width < 575
              ? "5.8vw"
              : width > 574 && width < 769
              ? "4vw"
              : width < 1025 && width > 768
              ? "2.5vw"
              : width < 1441 && width > 1024
              ? "2.5vw"
              : "2.5vw"
          }
          titleFontWeight="500"
          subtitle={data.text}
          subtitleFontSize={
            width < 575
              ? {
                  fontWeight: 300,
                  fontSize: "4.7vw",
                  lineHeight: "initial",
                  marginTop: 0
                }
              : width > 574 && width < 769
              ? {
                  fontWeight: 300,
                  fontSize: "2.8vw",
                  lineHeight: "initial"
                }
              : width < 1025 && width > 768
              ? {
                  fontWeight: 300,
                  fontSize: "2vw",
                  lineHeight: "initial"
                }
              : width < 1441 && width > 1024
              ? {
                  fontWeight: 300,
                  fontSize: "1.5vw",
                  lineHeight: "initial"
                }
              : {
                  fontWeight: 300,
                  fontSize: "1.3vw",
                  lineHeight: "initial"
                }
          }
          subtitleFontWeight="300"
          neuroelectrics
        />
      </>

      <div style={{ background: "#fff" }}>
        <ActualPath
          levelOne={{ text: "Home", url: "/" }}
          actual={{ text: "Neuroelectrics / Team" }}
        />
      </div>

      <div
        className="mt-4 mb-4"
        style={
          width > 768
            ? { paddingLeft: "15vw", paddingRight: "15vw" }
            : { paddingLeft: "5vw", paddingRight: "5vw" }
        }
      >
        <Container fluid>
          <Row>
            {data.team.items.map(item => (
              <Item
                item={item}
                subtitle={subtitle}
                small={small}
                smallbold={smallbold}
              />
            ))}
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <h3 style={title} className="mt-5 mb-5">
              {data.team2.title}
            </h3>
          </Row>
        </Container>
        <Container fluid>
          <Row className="pt-4">
            {data.team2.items.map((item, index) => (
              <Item
                item={item}
                key={index}
                subtitle={subtitle}
                small={small}
                smallbold={smallbold}
              />
            ))}
          </Row>
        </Container>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

const Item = ({ item, subtitle, small, smallbold }) => (
  <Col sm={3} md={3} lg={3}>
    <div className="p-2">
      <div className="d-flex justify-content-center align-items-center">
        <img
          style={{ borderRadius: "50%" }}
          className="w-100 p-3"
          src={isWebpSupported() ? `/img/team/${newImg(item.image.path)}` : `/img/team/${item.image.path}`}
          alt={item.image.title}
        />
      </div>
      <div className="p-3">
        <h5 style={smallbold}>{item.name}</h5>
        <h6 style={subtitle}>{item.position}</h6>
        <p style={small}>{item.desc}</p>
      </div>
    </div>
  </Col>
)

export default Team
