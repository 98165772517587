import React, { Fragment, useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { NavLink, Link, animateScroll } from "react-scroll"
import { Row, Col } from "react-bootstrap"

import styled from "styled-components"

import "./onepage.scss"

const OnepageMenu = ({ onepageMenuSections, responsive }) => {
  const [onepageMenuPosition, setonepageMenuPosition] = useState({
    position: "absolute"
  })
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    scroll()

    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", scroll)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", scroll)
    }
  }, [window.scrollY])

  const handleResize = () => setWidth(window.innerWidth)

  const isStarstim =
    window.location.pathname === "/solutions/starstim" ? true : false

  const scroll = () => {
    window.scrollY > 60
      ? setonepageMenuPosition({
          position: "fixed",
          top: isStarstim
            ? width < 575 && width > 388
              ? "3%"
              : width < 388
              ? "-2%"
              : 0
            : width < 575 && width > 349
            ? "3%"
            : width < 349
            ? "-2.5%"
            : 0,
          boxShadow: width < 575 ? "" : "0px 0px 13px 0px rgba(0,0,0,0.4)",
          borderBottom: width < 575 ? "1px solid lightgray" : ""
        })
      : setonepageMenuPosition({
          position: "absolute",
          top: isStarstim
            ? width < 575 && width > 388
              ? "3%"
              : width < 388
              ? "-2%"
              : "3.5vw"
            : width < 575 && width > 349
            ? "3%"
            : width < 349
            ? "-2.5%"
            : "3.5vw",
          boxShadow: width < 575 ? "" : "0px 0px 5px 0px rgba(0,0,0,0.3)",
          borderBottom: width < 575 ? "1px solid lightgray" : ""
        })
  }

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  const Cont = styled.div`
    background: white;
    ${onepageMenuPosition}
    transition: padding 2.2s;
    width: ${responsive ? "100%" : "70%"};
    height: 40px;
    margin-left: ${responsive ? 0 : "15%"};
    margin-right: ${responsive ? 0 : "15%"};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
  `

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossorigin="anonymous"
        />
      </Helmet>
      <Cont className="onepageContainer">
        <Row style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Col
            md={3}
            lg={3}
            sm={3}
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              alignItems: "center",
              height: "45px",
              display: "flex"
            }}
            className="onepageResponsiveTextP"
          >
            <p
              className="onepageResponsiveText onepageResponsiveTextP"
              style={{ fontSize: ".8vw", margin: 0, fontWeight: "bolder" }}
            >
              {onepageMenuSections.title}
            </p>
          </Col>
          <Col md={9} lg={9} sm={9}>
            <ul
              className={`nav nav-pills nav-fill d-flex ${
                width < 575 ? "justify-content-around" : "justify-content-end"
              }`}
              style={
                width < 575 ? { flexWrap: "wrap" } : { flexWrap: "inherit" }
              }
            >
              {onepageMenuSections.sections.map((item, index) => {
                var i = item
                return (
                  <li
                    key={index}
                    className="onepageResponsiveText"
                    style={{
                      alignItems: "center",
                      height: "45px",
                      display: "flex",
                      cursor: "pointer",
                      width: "fit-content",
                      minWidth: "fit-content",
                      paddingLeft: "1%",
                      paddingRight: "1%"
                    }}
                  >
                    {!item.redirect ? (
                      <Link
                        style={{
                          fontSize: ".8vw",
                          borderRadius: 0,
                          padding: 0,
                          whiteSpace: "nowrap"
                        }}
                        activeClass="scrollActive"
                        spy={true}
                        smooth={true}
                        offset={width < 575 ? -100 : 0}
                        duration={500}
                        className={
                          item.buttonAction && "onepageButton nav-link"
                        }
                        to={item.title}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <div className="d-flex align-items-center w-100 justify-content-end">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <a
                            className="m-0 nav-link p-0"
                            style={{ fontSize: ".8vw" }}
                          >
                            Models:
                          </a>
                          {item.sections.map((item, index) => (
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <a
                                className="nav-link onePageModels"
                                style={{
                                  fontSize: ".8vw",
                                  color: "#333",
                                  minWidth: "fit-content",
                                  whiteSpace: "nowrap"
                                }}
                                href={item.to}
                              >
                                {item.title}
                              </a>
                              {index + 1 < i.sections.length && (
                                <p className="m-0" style={{ fontSize: ".8vw" }}>
                                  /
                                </p>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </Col>
        </Row>
      </Cont>
    </Fragment>
  )
}

export default OnepageMenu
