import React, { Component } from "react"
import { Helmet } from "react-helmet"
import "./manualsrequest.scss"
import {isWebpSupported} from 'react-image-webp/dist/utils';

import CForm from "./CustomForm"

export default class ManualsRequest extends Component {
  render() {
    return (
      <div
        className="feature-page"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Helmet>
          <title>Manuals</title>
          <meta name="description" content="Neuroelectrics" />
        </Helmet>
        <div style={{ width: "50vw" }}>
          <div className="summerSaleHeader">
            <div
              className="col-6 summerSaleHeaderContainerText "
              style={{ marginTop: "5%" }}
            >
              <img
                src={isWebpSupported() ? require("./nelogolight.webp") : require("./nelogolight.png")}
                className="summerSaleHeaderLogoLight"
              />
              <p className="summerSaleHeaderP" style={{ color: "#fff" }}>
                Digital Brain Health
              </p>
              <h1 className="summerSaleHeaderH1" style={{ color: "#fff" }}>
                A new paradigm to monitor and stimulate the Brain
              </h1>
              <h2 className="summerSaleHeaderH2" style={{ color: "#fff" }}>
                Get your <strong>manuals</strong> now
              </h2>
              <p className="summerSaleHeaderP" style={{ color: "#fff" }}>
                Tell us what product you have and we´ll help you
              </p>
            </div>
          </div>
          <div className="summerSaleItemsForm d-flex justify-content-center">
            <div className="col-12">
              <CForm
                ref={form => {
                  this.form = form
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
