import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';

import ActualPath from "../../components/ActualPath";
import Loading from "../../components/Loading";

// import { getClinical } from "../../api/api";

import neuroscience_clinical from "../../contents/neuroscience_clinical";

import "./clinical.css";

const Clinical = () => {
  
  const data = neuroscience_clinical;  
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)
  const newImg = (img) => img.split(".")[0]+".webp";

  useEffect(() => {
    window.scroll(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const clinical = await getClinical();
    //     setData(clinical);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  
  const handleResize = () => setWidth(window.innerWidth)

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }
  

  return data != undefined ? (
    <div className="">
      {/* Helmet */}
      <>
        <Helmet>
          <title>Clinical Diagnostic and treatment | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Clinical Diagnostic and treatment | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Clinical Diagnostic and treatment | Neuroelectrics"
          />

          <meta
            name="description"
            content="We sell customized solutions for neurological diagnostic. We can sell our devices in any countries were we are present with clearance. Check the table"
          />
          <meta
            name="twitter:description"
            content="We sell customized solutions for neurological diagnostic. We can sell our devices in any countries were we are present with clearance. Check the table"
          />
          <meta
            property="og:description"
            content="We sell customized solutions for neurological diagnostic. We can sell our devices in any countries were we are present with clearance. Check the table"
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/neuroscience/clinical-research"
          />
        </Helmet>
      </>

      <Container
        fluid
        style={
          width > 768
            ? { paddingLeft: "14vw", background: "#fff", height: "450px" }
            : { paddingLeft: "0", background: "#fff", height: "300px" }
        }
      >
        <Row
          className={width > 575 ? "p-0" : ""}
          style={{
            background: "white",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <Col
            md={6}
            sm={5}
            xs={12}
            className="p-5 justify-content-center d-flex flex-column"
          >
            <p className="m-0">{data.legend}</p>
            <h1 style={title}>{data.title}</h1>
            <h2 style={subtitle}>{data.subtitle}</h2>
          </Col>
          <Col className="p-0 text-right h-100">
            <div
              style={{
                top: 0,
                left: 0,
                position: width < 575 ? "absolute" : "",
                width: "100%",
                height: "100%",
                backgroundImage: isWebpSupported() ? `url(/img/${newImg(data.image.path)})` : `url(/img/${data.image.path})`,                
                backgroundSize: width > 768 ? "cover" : "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: width < 769 ? "center" : "center"
              }}
            ></div>
          </Col>
        </Row>
      </Container>

      <ActualPath
        paddingLeftResponsive="15.7vw"
        levelOne={{ text: "Home", url: "/" }}
        actual={{ text: "Clinical applications" }}
      />

      <Container className={width > 575 && "mt-5 mb-5"}>
        <Row
          className={width > 575 ? "p-5" : ""}
          style={
            width < 575
              ? {
                  padding: "4%",
                  borderBottom: "1px solid whitesmoke",
                  flexDirection: "column"
                }
              : {
                  padding: "1%",
                  background: "white",
                  borderBottom: "1px solid whitesmoke",
                  flexDirection: "column"
                }
          }
        >
          <h3 style={width < 575 ? subtitle : smallbold}>
            {width < 575 ? <b>{data.desc.title}</b> : data.desc.title}
          </h3>
          <p style={width < 575 ? subtitle : small}>
            <span style={width < 575 ? { fontSize: "85%" } : {}}>
              {data.desc.desc}
            </span>
          </p>
        </Row>

        <Row
          className={width > 575 ? "p-5" : ""}
          style={{ padding: "1%", background: "white" }}
        >
          <Col className="p-0">
            <div className={width < 575 ? "p-3" : {}}>
              <h3 style={width < 575 ? smallbold : small}>
                {data.table.title}
              </h3>
            </div>
            <table className="clinicalTable" style={{ maxWidth: "100vw" }}>
              <tbody>
                {data.table.rows.map(item => (
                  <tr>
                    {item.map(item => (
                      <td style={{ padding: ".5%" }}>
                        {item.text ? (
                          <p>{item.text}</p>
                        ) : item.image === "check" ? (
                          <i className="fa fa-check"></i>
                        ) : item.image === "dots" ? (
                          <i className="fa fa-ellipsis-h"></i>
                        ) : (
                          item.flag &&                             
                            <img src={isWebpSupported() ? `/img/${newImg(item.flag)}` : `/img/${item.flag}`}                                  
                              alt={item.flag}
                              style={{ width: "105px", padding: ".7vw" }}
                            />                                                    
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </div>
  ) : (
    <Loading />
  )
}

export default Clinical
