import React, { useEffect, useState, Fragment } from "react"
import { Helmet } from "react-helmet"
import { Container, Row, Col, Carousel } from "react-bootstrap"
import axios from "axios"
import {isWebpSupported} from 'react-image-webp/dist/utils';

import OnepageMenu from "../../components/OnepageMenu"
import Banner from "../../components/Banner"
import ActualPath from "../../components/ActualPath"
import PreviewItems from "../../components/PreviewItems"
import Button from "../../components/Button"
import Loading from "../../components/Loading"

import "./product.css"

const Product = () => {
  const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  let family
  let product

  if (
    window.location.pathname.substring(window.location.pathname.length - 1) ===
    "/"
  ) {
    family = window.location.pathname
      .slice(0, -1)
      .split("/")
      .slice(-2)[0]
    product = window.location.pathname
      .slice(0, -1)
      .split("/")
      .slice(-1)[0]
  } else {
    family = window.location.pathname.split("/").slice(-2)[0]
    product = window.location.pathname.split("/").slice(-1)[0]
  }

  let product_ref =
    family !== "starstimhome" ? family + "_" + product : family + product

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/product/`, {
        refe: product_ref
      })
      .then(res => {
        setData(res.data)
      })

    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  const download = name => {
    return axios(`${process.env.REACT_APP_API_URL}/downloads/${name}`, {
      method: "GET",
      responseType: "blob"
    }).then(res =>
      window.open(`${process.env.REACT_APP_API_URL}/downloads/${name}`)
    )
  }

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  var responsiveWidth = "50vw"
  var responsiveBackground = "cover"

  if (width < 1024 && width > 992) {
    responsiveWidth = "70vw"
  } else if (width < 992 && width > 768) {
    responsiveWidth = "85vw"
    responsiveBackground = "cover"
  } else if (width < 769) {
    responsiveWidth = "95vw"
    responsiveBackground = "cover"
  }

  return data !== undefined ? (
    <Fragment>
      {/* Helmet */}
      <>
        <Helmet>
          <title>
            {data.banner.title && data.banner.title} | Solutions |
            Neuroelectrics
          </title>
          <meta
            name="twitter:title"
            content={`${data.banner.title &&
              data.banner.title} | twitter:Solutions | Neuroelectrics`}
          />
          <meta
            property="og:title"
            content={`${data.banner.title &&
              data.banner.title} | Solutions | Neuroelectrics`}
          />

          <meta
            name="description"
            content={data.banner.subtitle && data.banner.subtitle}
          />
          <meta
            name="twitter:description"
            content={data.banner.subtitle && data.banner.subtitle}
          />
          <meta
            property="og:description"
            content={data.banner.subtitle && data.banner.subtitle}
          />

          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css"
          />
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js" />
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js" />
        </Helmet>
      </>

      {/* Single Page Menu */}
      <>{width > 575 && <OnepageMenu onepageMenuSections={data.sections} />}</>
      {/* Banner */}
      <>
        <Banner
          width={width}
          backgroundWhite={width < 426 && true}
          responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
          heightResponsive={width > 425 && "25vw"}
          justifyDiv="center"
          backgroundPadding={
            width < 575
              ? "center"
              : width > 574 && width < 769
              ? "-30vw"
              : width < 1025 && width > 768
              ? "center"
              : width < 1441 && width > 1024
              ? "center"
              : "center"
          }
          textsPaddingRight={
            width < 575
              ? "0"
              : width > 574 && width < 769
              ? "22%"
              : width < 1025 && width > 768
              ? "22%"
              : width < 1441 && width > 1024
              ? "22%"
              : "22%"
          }
          title={data.banner.title}
          titleFontSize={
            width < 575
              ? "5.8vw"
              : width > 574 && width < 769
              ? "4vw"
              : width < 1025 && width > 768
              ? "2.5vw"
              : width < 1441 && width > 1024
              ? "2.5vw"
              : "2.5vw"
          }
          titleFontWeight="500"
          subtitle={data.banner.subtitle}
          subtitleFontSize={
            width < 575
              ? "4.1vw"
              : width > 574 && width < 769
              ? "2.6vw"
              : width < 1025 && width > 768
              ? "1.7vw"
              : width < 1441 && width > 1024
              ? "1.4vw"
              : "1.4vw"
          }
          subtitleFontWeight="300"
          img={isWebpSupported() ? `/img/banners/${newImg(data.banner.image.path)}` : `/img/banners/${data.banner.image.path}`}
        />
      </>
      {/* Actual Path */}
      <>
        <ActualPath
          product
          levelOne={{ text: "Home", url: "/" }}
          levelTwo={{
            text: data.navigation_path.family,
            url: data.navigation_path.family_url
          }}
          actual={{ text: data.navigation_path.product_name }}
        />
      </>
      {/* Features */}
      <>
        <Container
          fluid
          id={data.sections.sections[0].title}
          className={width > 768 && "p-5 mt-4 mb-5"}
        >
          <Row className="d-flex align-items-center">
            <Col
              className="d-flex align-items-center justify-content-center"
              lg={6}
              md={12}
              sm={12}
            >
              <Carousel className="productCarousel">
                {data.technology.carousel.map((item, i) => (
                  <Carousel.Item key={i} className="p-5">
                    <img
                      src={isWebpSupported() ? "/img/product/" + newImg(item.img) : "/img/product/" + item.img}
                      alt={item.alt}
                      className={
                        width < 769 ? "w-100" : width > 768 && "w-100 h-100 p-5"
                      }
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              style={
                width < 576
                  ? {
                      background: "#fff",
                      padding: "5%",
                      margin: "3%"
                    }
                  : width > 768
                  ? { paddingRight: "12%" }
                  : {}
              }
            >
              <h2
                style={
                  width < 575
                    ? {
                        fontWeight: 500,
                        fontSize: "8vw",
                        marginTop: 0
                      }
                    : width > 574 && width < 769
                    ? {
                        fontWeight: 500,
                        fontSize: "4vw"
                      }
                    : width < 1025 && width > 768
                    ? {
                        fontWeight: 500,
                        fontSize: "3vw"
                      }
                    : width < 1441 && width > 1024
                    ? {
                        fontWeight: 500,
                        fontSize: "2.3vw"
                      }
                    : {
                        fontWeight: 500,
                        fontSize: "2vw"
                      }
                }
              >
                {data.technology.title}
              </h2>
              <p
                style={
                  width < 575
                    ? {
                        fontWeight: 300,
                        fontSize: "4.7vw",
                        lineHeight: "initial",
                        marginTop: 0
                      }
                    : width > 574 && width < 769
                    ? {
                        fontWeight: 300,
                        fontSize: "2.8vw",
                        lineHeight: "initial"
                      }
                    : width < 1025 && width > 768
                    ? {
                        fontWeight: 300,
                        fontSize: "2vw",
                        lineHeight: "initial"
                      }
                    : width < 1441 && width > 1024
                    ? {
                        fontWeight: 300,
                        fontSize: "1.5vw",
                        lineHeight: "initial"
                      }
                    : {
                        fontWeight: 300,
                        fontSize: "1.1vw",
                        lineHeight: "initial"
                      }
                }
              >
                {data.technology.intro}
              </p>
              <div className="panel-group" id="accordion">
                {data.technology.features.map((item, index) => (
                  <div
                    className="panel panel-default"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="accordion-toggle"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href={`#collapse${index}`}
                          style={
                            width < 575
                              ? {
                                  fontWeight: 500,
                                  textDecoration: "none"
                                }
                              : width > 574 && width < 769
                              ? {
                                  fontWeight: 500,
                                  textDecoration: "none",
                                  fontSize: "17px"
                                }
                              : width < 1025 && width > 768
                              ? {
                                  fontWeight: 500,
                                  textDecoration: "none"
                                }
                              : width < 1441 && width > 1024
                              ? {
                                  fontWeight: 500,
                                  textDecoration: "none"
                                }
                              : {
                                  fontSize: "1.2vw",
                                  fontWeight: 500,
                                  textDecoration: "none"
                                }
                          }
                        >
                          {item.title}
                        </a>
                      </h4>
                    </div>
                    <div
                      id={`collapse${index}`}
                      className={
                        width > 575
                          ? `panel-collapse collapse ${
                              index === 0 ? " in" : ""
                            }`
                          : "panel-collapse collapse"
                      }
                    >
                      <div className="panel-body">
                        <p
                          style={
                            width < 575
                              ? {
                                  fontWeight: 300,
                                  fontSize: "16px",
                                  lineHeight: "initial",
                                  marginTop: 0
                                }
                              : width > 574 && width < 769
                              ? {
                                  fontWeight: 300,
                                  fontSize: "2.2vw",
                                  lineHeight: "initial"
                                }
                              : width < 1025 && width > 768
                              ? {
                                  fontWeight: 300,
                                  fontSize: "1.5vw",
                                  lineHeight: "initial"
                                }
                              : width < 1441 && width > 1024
                              ? {
                                  fontWeight: 300,
                                  fontSize: "1.2vw",
                                  lineHeight: "initial"
                                }
                              : {
                                  fontWeight: 300,
                                  fontSize: "1vw",
                                  lineHeight: "initial"
                                }
                          }
                        >
                          {item.content}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={
                  width < 575
                    ? "d-flex justify-content-center align-items-center"
                    : width > 574 && width < 769
                    ? "d-flex justify-content-center align-items-center"
                    : width < 1025 && width > 768
                    ? "d-flex justify-content-center align-items-center"
                    : width < 1441 && width > 1024
                    ? "d-flex justify-content-center align-items-center"
                    : "d-flex justify-content-center align-items-center"
                }
              >
                <Button
                  text="Get quote"
                  url="/get-quote"
                  refe={product_ref}
                  width="10vw"
                  marginHorizontal="2vw"
                  padding={width > 1441 && "15px 30px"}
                  radius={width > 1441 && "50px"}
                  font={
                    width < 575
                      ? "3.8vw"
                      : width > 574 && width < 769
                      ? "2vw"
                      : width < 1025 && width > 768
                      ? "1.6vw"
                      : width < 1441 && width > 1024
                      ? "1.2vw"
                      : "1.1vw"
                  }
                  shadow
                />

                <Button
                  text="Get demo"
                  url="/resources/webinar"
                  refe={product_ref}
                  width="10vw"
                  marginHorizontal="2vw"
                  padding={width > 1441 && "15px 30px"}
                  radius={width > 1441 && "50px"}
                  font={
                    width < 575
                      ? "3.8vw"
                      : width > 574 && width < 769
                      ? "2vw"
                      : width < 1025 && width > 768
                      ? "1.6vw"
                      : width < 1441 && width > 1024
                      ? "1.2vw"
                      : "1.1vw"
                  }
                  shadow
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>

      {/* Support */}
      <>
        <div
          id={data.sections.sections[1].title}
          style={{
            backgroundImage: isWebpSupported() ? "url(/img/components_support.webp)" : "url(/img/components_support.jpg)",
            backgroundPosition: "center",
            backgroundSize: responsiveBackground,
            backgroundRepeat: "no-repeat",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            height: "100%",
            marginTop: "2%"
          }}
        >
          <Row
            style={{
              padding: "2%",
              margin: "5%",
              background: "white",
              width: responsiveWidth
            }}
          >
            <Col md={7} className="componentsSupport">
              <h2 style={title} className="mt-0">
                Need more information?
              </h2>
              <p style={subtitle}>
                Check our resources for this product to learn in detail about
                its technical specifications and instructions for use.
              </p>
            </Col>
            <Col md={5} className="componentsSupport">
              <button
                onClick={() => download(data.technicalsheet)}
                className="componentsSupportLink h-50"
                style={{
                  borderBottom: "1px solid lightgrey",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  marginBottom: "22% !important",
                  width: "100%",
                  padding: "4%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "transparent",
                  outline: "none"
                }}
              >
                <h3
                  style={{
                    fontSize: "1vw",
                    paddingLeft: ".8vw",
                    margin: 0,
                    color: "#555555"
                  }}
                >
                  Technical sheet
                </h3>
                <img
                  style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                  src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                  alt="/img/arrow.png"
                />
              </button>
              <button
                onClick={() => download(data.manual)}
                className="componentsSupportLink h-50"
                style={{
                  marginBottom: "22% !important",
                  width: "100%",
                  padding: "5%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  background: "transparent",
                  outline: "none"
                }}
              >
                <h3
                  style={{
                    fontSize: "1vw",
                    margin: 0,
                    color: "#555555"
                  }}
                >
                  User manual
                </h3>

                <img
                  style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                  src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                  alt="/img/arrow.png"
                />
              </button>
            </Col>
          </Row>
        </div>
      </>

      {/* Related Products */}
      <>
        <Container
          fluid
          style={{ background: "#fff", paddingTop: "4%", paddingBottom: "2%" }}
        >
          {data.included_accesories.length > 0 && (
            <PreviewItems
              id={data.sections.sections[2].title}
              data={data.included_accesories}
              title="Essentials In Your Kit"
              width={width}
            />
          )}

          <div className={width < 575 && "pb-5"}>
            {data.compatible_accesories.length > 0 && (
              <PreviewItems
                data={data.compatible_accesories}
                title="Unique Add-on Services, Softwares & Accessories"
                width={width}
              />
            )}
          </div>
        </Container>
      </>
    </Fragment>
  ) : (
    <Loading />
  )
}

export default Product
