import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function CookiesPolicy(){
  useEffect (() => {
    const script = document.createElement("script")
    script.src = "https://consent.cookiebot.com/8c91407c-f337-4d8b-967b-ca4a479923c8/cd.js" 
    script.async = true
    script.type = "text/javascript"
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  },[])

  return (
    <div className="pt60px">
      <Helmet>
        <title>Cookies Policy | Neuroelectrics</title>
        <meta
          name="twitter:title"
          content={`Cookies Policy | Neuroelectrics`}
        />
        <meta
          property="og:title"
          content={`Cookies Policy | Neuroelectrics`}
        />
        <meta name="description" content="Join our upcoming webinar" />
        <meta
          name="twitter:description"
          content="Join our upcoming webinar"
        />
        <meta property="og:description" content="Join our upcoming webinar" />
        <link
          rel="canonical"
          href="https://neuroelectrics.com/resources/webinar"
        />      
      </Helmet>
      <Container>
        <Row style={{ background: "white", margin: "5%", position: "relative" }}>       
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/dca44d10-aeea-49c7-9cf5-456a994c9cd8/cd.js" type="text/javascript" async></script>
        </Row>
      </Container>
    </div>
  )
}

export default CookiesPolicy