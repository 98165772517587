import React, { useState, useEffect } from "react"
import { Container, Carousel, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import styled from "styled-components"
import "react-alice-carousel/lib/alice-carousel.css"
import { isWebpSupported } from 'react-image-webp/dist/utils';
import checkNe from "../../assets/img/checkNE.png"
import hyphenNE from "../../assets/img/hyphenNE.png"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import "./tablecomparison.css"

import Button from "../Button"

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background: #fff;
`
const newImg = (img) => img.split(".")[0] + ".webp";

const tableComparisonTitles = (id,type) => {
  let tableTitle = id == "Starstim home Products Comparison" ? "" : "Family products";
  let tableSubtitle = id == "Starstim home Products Comparison" ? "" : ( type == "Dx" ? "" : "comparison" );

  return[tableTitle,tableSubtitle]
}

const TableComparison = ({
  data,
  features,
  service,
  id,
  comment,
  channels,
  type
}) => {
  const titles = tableComparisonTitles(id,type);
  
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const download = name =>
    window.open(
      `${process.env.REACT_APP_API_URL}/downloadmodelingservices/${name}`
    )

  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }

  const button = (item) => {
    return type == "Dx" ?
    "":
     <div>
            <Button
              text="More info"
              url={item.seo_url_family + "/" + item.seo_url_product}
              refe={data._id}
              padding="7px 15px"
              margin="10px"
              radius={width > 1441 && "50px"}
              font={
                width < 575
                  ? "3.8vw"
                  : width > 574 && width < 769
                    ? "1.5vw"
                    : width < 1025 && width > 768
                      ? "1.6vw"
                      : width < 1441 && width > 1024
                        ? "1.2vw"
                        : ".9vw"
              }
            />
      </div>    
  }

  const title =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "8vw",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "3vw"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "2.3vw"
            }
            : {
              fontWeight: 500,
              fontSize: "2vw"
            }

  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2.2vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "1.5vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1.2vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }

  const smallbold =
    width < 575
      ? {
        fontWeight: 500,
        textDecoration: "none"
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "17px"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            textDecoration: "none"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              textDecoration: "none"
            }
            : {
              fontSize: "1.2vw",
              fontWeight: 500,
              textDecoration: "none"
            }

  return (
    <Cont id={id} style={width < 575 ? { background: "#f7f6f5" } : {}}>
      <Container
        fluid
        className={
          width > 1024 ? "p-5 w-75 position-relative" : "position-relative"
        }
      >
        <h2
          style={title}
          className={
            width < 769 && width > 575
              ? "w-5 position-absolute"
              : "position-absolute"
          }
          style={width < 575 ? { padding: "5%" } : {}}
        >
          {titles[0]}
          <br></br>
          {titles[1]}
        </h2>
        {width > 575 && (
          <Table
            style={{ width: "100%" }}
            className={"ProductsTable ProductsTable1"}
          >
            <Thead>
              <Tr>
                <Th style={{ width: "50vw" }}></Th>
                {data.map((item, index) => (
                  <Th
                    key={index}
                    style={{
                      width: "10vw"
                    }}
                  >
                    <div
                      className={"itemModelProductsTable"}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: "4%"
                      }}
                    >
                      <p style={small} className="m-0 p-3 white-space-no-wrap">
                        {item.item_preview.subtitle
                          ? item.item_preview.subtitle
                          : (type == "Dx" ? item.item_preview.titleDx : item.item_preview.title)}
                      </p>
                      <img
                        style={
                          width < 575 ? { width: "60%" } : { width: "100%" }
                        }
                        src={isWebpSupported() ?
                          process.env.PUBLIC_URL +
                          `/img/product/${newImg(item.item_preview.image.path)}` :
                          process.env.PUBLIC_URL +
                          `/img/product/${item.item_preview.image.path}`
                        }
                        alt={item.item_preview.image.title}
                      />
                      {button(item)}
                      
                    </div>
                  </Th>
                ))}
              </Tr>
            </Thead>

            {features.channels && (
              <Tbody>
                {features.channels.map(item => (
                  <Tr
                    style={
                      width < 575
                        ? {
                          background: "white",
                          borderBottom: "1px solid lightgrey"
                        }
                        : {
                          borderBottom: "1px solid lightgrey"
                        }
                    }
                  >
                    {item.map(item => (
                      <Td>
                        <a
                          href={
                            item.product_reference &&
                            `/${item.product_reference}`
                          }
                        >
                          <div
                            className="h-100"
                            style={{
                              paddingTop: ".6vw",
                              paddingBottom: ".6vw"
                            }}
                          >
                            <p
                              style={small}
                              className={
                                item.product_reference
                                  ? "text-info m-0 "
                                  : "m-0 "
                              }
                            >
                              {typeof item.value == "string" && item.value}
                              {item.value === 0 && (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                  <img
                                    style={{
                                      width:
                                        width < 575
                                          ? "5vw"
                                          : width > 574 && width < 769
                                            ? "2.5vw"
                                            : width < 1025 && width > 768
                                              ? "1.4vw"
                                              : "1.4vw",
                                      padding: "5%"
                                    }}
                                    src={hyphenNE}
                                    alt="-"
                                  />
                                </div>
                              )}
                              {item.value === 1 && (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                  <img
                                    style={{
                                      padding: "5%",
                                      width:
                                        width < 641
                                          ? "6vw"
                                          : width < 769 && width > 640
                                            ? "3vw"
                                            : "1.7vw"
                                    }}
                                    src={checkNe}
                                    alt="check"
                                  />
                                </div>
                              )}
                              {item.value === 2 && (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                  <img
                                    style={{
                                      padding: "5%",
                                      width:
                                        width < 641
                                          ? "6vw"
                                          : width < 769 && width > 640
                                            ? "3vw"
                                            : "1.7vw"
                                    }}
                                    src={checkNe}
                                    alt="check"
                                  />
                                  <img
                                    style={{
                                      padding: "5%",
                                      width:
                                        width < 641
                                          ? "6vw"
                                          : width < 769 && width > 640
                                            ? "3vw"
                                            : "1.7vw"
                                    }}
                                    src={checkNe}
                                    alt="check"
                                  />
                                </div>
                              )}
                              {item.value === 3 && (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                  <img
                                    style={{
                                      padding: "5%",
                                      width:
                                        width < 641
                                          ? "6vw"
                                          : width < 769 && width > 640
                                            ? "3vw"
                                            : "1.7vw"
                                    }}
                                    src={checkNe}
                                    alt="check"
                                  />
                                  <img
                                    style={{
                                      padding: "5%",
                                      width:
                                        width < 641
                                          ? "6vw"
                                          : width < 769 && width > 640
                                            ? "3vw"
                                            : "1.7vw"
                                    }}
                                    src={checkNe}
                                    alt="check"
                                  />
                                  <img
                                    style={{
                                      padding: "5%",
                                      width:
                                        width < 641
                                          ? "6vw"
                                          : width < 769 && width > 640
                                            ? "3vw"
                                            : "1.7vw"
                                    }}
                                    src={checkNe}
                                    alt="check"
                                  />
                                </div>
                              )}
                            </p>
                          </div>
                        </a>
                      </Td>
                    ))}
                  </Tr>
                ))}
                <br></br>
                <br></br>
              </Tbody>
            )}

            <Tbody>
              <p style={smallbold}>{features.title} </p>
            </Tbody>

            <Tbody>
              {features.rows.map(item => (
                <Tr>
                  {item.map(item => (
                    <Td>
                      <a
                        href={
                          item.product_reference && `/${item.product_reference}`
                        }
                      >
                        <div
                          className="h-100"
                          style={{ paddingTop: ".6vw", paddingBottom: ".6vw" }}
                        >
                          <p
                            style={small}
                            className={
                              item.product_reference ? "text-info m-0" : "m-0"
                            }
                          >
                            {typeof item.value == "string" && item.value}
                            {item.value === 0 && (
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <img
                                  style={{
                                    width:
                                      width < 575
                                        ? "5vw"
                                        : width > 574 && width < 769
                                          ? "2.5vw"
                                          : width < 1025 && width > 768
                                            ? "1.4vw"
                                            : "1.4vw",
                                    padding: "5%"
                                  }}
                                  src={hyphenNE}
                                  alt="-"
                                />
                              </div>
                            )}
                            {item.value === 1 && (
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <img
                                  style={{
                                    padding: "5%",
                                    width:
                                      width < 641
                                        ? "6vw"
                                        : width < 769 && width > 640
                                          ? "3vw"
                                          : "1.7vw"
                                  }}
                                  src={checkNe}
                                  alt="check"
                                />
                              </div>
                            )}
                            {item.value === 2 && (
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <img
                                  style={{
                                    padding: "5%",
                                    width:
                                      width < 641
                                        ? "6vw"
                                        : width < 769 && width > 640
                                          ? "3vw"
                                          : "1.7vw"
                                  }}
                                  src={checkNe}
                                  alt="check"
                                />
                                <img
                                  style={{
                                    padding: "5%",
                                    width:
                                      width < 641
                                        ? "6vw"
                                        : width < 769 && width > 640
                                          ? "3vw"
                                          : "1.7vw"
                                  }}
                                  src={checkNe}
                                  alt="check"
                                />
                              </div>
                            )}
                            {item.value === 3 && (
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <img
                                  style={{
                                    padding: "5%",
                                    width:
                                      width < 641
                                        ? "6vw"
                                        : width < 769 && width > 640
                                          ? "3vw"
                                          : "1.7vw"
                                  }}
                                  src={checkNe}
                                  alt="check"
                                />
                                <img
                                  style={{
                                    padding: "5%",
                                    width:
                                      width < 641
                                        ? "6vw"
                                        : width < 769 && width > 640
                                          ? "3vw"
                                          : "1.7vw"
                                  }}
                                  src={checkNe}
                                  alt="check"
                                />
                                <img
                                  style={{
                                    padding: "5%",
                                    width:
                                      width < 641
                                        ? "6vw"
                                        : width < 769 && width > 640
                                          ? "3vw"
                                          : "1.7vw"
                                  }}
                                  src={checkNe}
                                  alt="check"
                                />
                              </div>
                            )}
                          </p>
                        </div>
                      </a>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {width < 575 && (
          <table>
            <Carousel
              controls={false}
              activeIndex={index}
              className="mobileFamilyTable"
            >
              {features.channels &&
                features.channels.map((product, i) => (
                  <Carousel.Item
                    style={{
                      background: "white",
                      borderTop: "3px solid #555"
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          borderBottom: "1px solid lightgray",
                          display: "flex"
                        }}
                      >
                        <th
                          className="text-center d-flex justify-content-between align-items-center p-3"
                          style={smallbold}
                        >
                          <button
                            onClick={() =>
                              index < 1
                                ? setIndex(features.rows.length)
                                : setIndex(index - 1)
                            }
                            style={{
                              border: "none",
                              background: "none",
                              outline: "none",
                              width: "10%"
                            }}
                          >
                            <img
                              className="w-100"
                              src={require("../../assets/img/left.svg").default}
                              alt="left-arrow"
                            />
                          </button>
                          <p
                            style={{
                              fontSize: "150%",
                              maxWidth: "65%",
                              lineHeight: "initial",
                              margin: 0
                            }}
                          >
                            {product[0].value}
                          </p>
                          <button
                            style={{
                              border: "none",
                              background: "none",
                              outline: "none",
                              width: "10%"
                            }}
                            onClick={() => setIndex(index + 1)}
                          >
                            <img
                              className="w-100"
                              src={require("../../assets/img/right.svg")}
                              alt="right-arrow"
                            />
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Container fluid>
                            <Row>
                              {data.map((item, i) => (
                                <Col
                                  className="text-center p-2"
                                  style={{
                                    background: "#f7f6f5",
                                    margin: "1%"
                                  }}
                                >
                                  <p style={small} className="m-0">
                                    <b style={{ whiteSpace: "nowrap" }}>
                                      {item.item_preview.subtitle
                                        ? item.item_preview.subtitle
                                        : item.item_preview.title}
                                    </b>
                                  </p>
                                  <img
                                    style={
                                      width < 575
                                        ? { width: "100%" }
                                        : { width: "100%" }
                                    }
                                    src={isWebpSupported() ?
                                      process.env.PUBLIC_URL +
                                      `/img/product/${newImg(item.item_preview.image.path)}` :
                                      process.env.PUBLIC_URL +
                                      `/img/product/${item.item_preview.image.path}`
                                    }
                                    alt={item.item_preview.image.title}
                                  />

                                  <p
                                    style={small}
                                    className={
                                      item.product_reference
                                        ? "text-info mt-2 mb-2"
                                        : "mt-2 mb-2"
                                    }
                                  >
                                    {typeof product[i + 1].value == "string" &&
                                      product[i + 1].value}
                                    {product[i + 1].value === 0 && (
                                      <div className="d-flex justify-content-center align-items-center h-100">
                                        <img
                                          style={{
                                            width:
                                              width < 575
                                                ? "5vw"
                                                : width > 574 && width < 769
                                                  ? "2.5vw"
                                                  : width < 1025 && width > 768
                                                    ? "1.4vw"
                                                    : "1.4vw",
                                            padding: "5%"
                                          }}
                                          src={hyphenNE}
                                          alt="-"
                                        />
                                      </div>
                                    )}
                                    {product[i + 1].value === 1 && (
                                      <div className="d-flex justify-content-center align-items-center h-100">
                                        <img
                                          style={{
                                            width: "25px",
                                            padding: "5%"
                                          }}
                                          src={checkNe}
                                          alt="check"
                                        />
                                      </div>
                                    )}
                                    {product[i + 1].value === 2 && (
                                      <div className="d-flex justify-content-center align-items-center h-100">
                                        <img
                                          style={{
                                            width: "25px",
                                            padding: "5%"
                                          }}
                                          src={checkNe}
                                          alt="check"
                                        />
                                        <img
                                          style={{
                                            width: "25px",
                                            padding: "5%"
                                          }}
                                          src={checkNe}
                                          alt="check"
                                        />
                                      </div>
                                    )}
                                    {product[i + 1].value === 3 && (
                                      <div className="d-flex justify-content-center align-items-center h-100">
                                        <img
                                          style={{
                                            width: "25px",
                                            padding: "5%"
                                          }}
                                          src={checkNe}
                                          alt="check"
                                        />
                                        <img
                                          style={{
                                            width: "25px",
                                            padding: "5%"
                                          }}
                                          src={checkNe}
                                          alt="check"
                                        />
                                        <img
                                          style={{
                                            width: "25px",
                                            padding: "5%"
                                          }}
                                          src={checkNe}
                                          alt="check"
                                        />
                                      </div>
                                    )}
                                  </p>

                                  <div className="pt-2 pb-2">
                                    <Button
                                      text="+"
                                      url={
                                        item.seo_url_family +
                                        "/" +
                                        item.seo_url_product
                                      }
                                      refe={data._id}
                                      padding={"15px 20px"}
                                      font={small}
                                    />
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Container>
                        </td>
                      </tr>
                    </tbody>
                  </Carousel.Item>
                ))}

              {features.rows.map((product, i) => (
                <Carousel.Item
                  style={{
                    background: "white",
                    borderTop: "3px solid #555"
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        borderBottom: "1px solid lightgray",
                        display: "flex"
                      }}
                    >
                      <th
                        className="text-center d-flex justify-content-between align-items-center p-3"
                        style={smallbold}
                      >
                        <button
                          onClick={() => index > 0 && setIndex(index - 1)}
                          style={{
                            border: "none",
                            background: "none",
                            outline: "none",
                            width: "10%"
                          }}
                        >
                          <img
                            className="w-100"
                            src={require("../../assets/img/left.svg").default}
                            alt="left-arrow"
                          />
                        </button>
                        <p
                          style={{
                            fontSize: "150%",
                            lineHeight: "initial",
                            maxWidth: "65%",
                            margin: 0
                          }}
                        >
                          {product[0].value}
                        </p>
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            outline: "none",
                            width: "10%"
                          }}
                          onClick={() =>
                            index === features.rows.length
                              ? setIndex(0)
                              : setIndex(index + 1)
                          }
                        >
                          <img
                            className="w-100"
                            src={require("../../assets/img/right.svg")}
                            alt="left-right"
                          />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Container fluid className="p-0">
                          <Row className="w-100 d-flex justify-content-around m-0">
                            {data.map((item, i) => (
                              <Col
                                className="text-center p-2 m-1"
                                style={{
                                  background: "#f7f6f5",
                                  width: data.length > 3 ? "50%" : "",
                                  minWidth: data.length > 3 ? "46%" : ""
                                }}
                              >
                                <b style={{ whiteSpace: "nowrap" }}>
                                  <p style={small} className="m-0">
                                    {item.item_preview.subtitle
                                      ? item.item_preview.subtitle
                                      : item.item_preview.title}
                                  </p>
                                </b>

                                <img
                                  style={
                                    width < 575
                                      ? { width: "100%" }
                                      : { width: "100%" }
                                  }
                                  src={isWebpSupported() ?
                                    process.env.PUBLIC_URL +
                                    `/img/product/${newImg(item.item_preview.image.path)}` :
                                    process.env.PUBLIC_URL +
                                    `/img/product/${item.item_preview.image.path}`
                                  }
                                  alt={item.item_preview.image.title}
                                />

                                <p
                                  style={small}
                                  className={
                                    item.product_reference
                                      ? "text-info  mt-2 mb-2"
                                      : " mt-2 mb-2"
                                  }
                                >
                                  {typeof product[i + 1].value == "string" &&
                                    product[i + 1].value}

                                  {product[i + 1].value === 0 && (
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                      <img
                                        style={{
                                          width:
                                            width < 575
                                              ? "5vw"
                                              : width > 574 && width < 769
                                                ? "2.5vw"
                                                : width < 1025 && width > 768
                                                  ? "1.4vw"
                                                  : "1.4vw",
                                          padding: "5%"
                                        }}
                                        src={hyphenNE}
                                        alt="-"
                                      />
                                    </div>
                                  )}
                                  {product[i + 1].value === 1 && (
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                      <img
                                        style={
                                          features.channels
                                            ? {
                                              width: "25px",
                                              padding: "5%"
                                            }
                                            : {
                                              width:
                                                data.length > 3
                                                  ? "25px"
                                                  : "25px",
                                              padding: "5%"
                                            }
                                        }
                                        src={checkNe}
                                        alt="check"
                                      />
                                    </div>
                                  )}
                                  {product[i + 1].value === 2 && (
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                      <img
                                        style={
                                          features.channels
                                            ? {
                                              width: "25px",
                                              padding: "5%"
                                            }
                                            : {
                                              width:
                                                data.length > 3
                                                  ? "25px"
                                                  : "25px",
                                              padding: "5%"
                                            }
                                        }
                                        src={checkNe}
                                        alt="check"
                                      />
                                      <img
                                        style={
                                          features.channels
                                            ? {
                                              width: "25px",
                                              padding: "5%"
                                            }
                                            : {
                                              width:
                                                data.length > 3
                                                  ? "25px"
                                                  : "25px",
                                              padding: "5%"
                                            }
                                        }
                                        src={checkNe}
                                        alt="check"
                                      />
                                    </div>
                                  )}
                                  {product[i + 1].value === 3 && (
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                      <img
                                        style={
                                          features.channels
                                            ? {
                                              width: "25px",
                                              padding: "5%"
                                            }
                                            : {
                                              width:
                                                data.length > 3
                                                  ? "25px"
                                                  : "25px",
                                              padding: "5%"
                                            }
                                        }
                                        src={checkNe}
                                        alt="check"
                                      />
                                      <img
                                        style={
                                          features.channels
                                            ? {
                                              width: "25px",
                                              padding: "5%"
                                            }
                                            : {
                                              width:
                                                data.length > 3
                                                  ? "25px"
                                                  : "25px",
                                              padding: "5%"
                                            }
                                        }
                                        src={checkNe}
                                        alt="check"
                                      />
                                      <img
                                        style={
                                          features.channels
                                            ? {
                                              width: "25px",
                                              padding: "5%"
                                            }
                                            : {
                                              width:
                                                data.length > 3
                                                  ? "25px"
                                                  : "25px",
                                              padding: "5%"
                                            }
                                        }
                                        src={checkNe}
                                        alt="check"
                                      />
                                    </div>
                                  )}
                                </p>

                                <div className="pt-2 pb-3">
                                  <Button
                                    text="+"
                                    url={`/solutions/${item.seo_url_family}/${item.seo_url_product}`}
                                    refe={data._id}
                                    font={small}
                                    padding="15px 20px"
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Container>
                      </td>
                    </tr>
                  </tbody>
                </Carousel.Item>
              ))}
            </Carousel>
          </table>
        )}

        <table className="mt-5 serviceTable" style={{ width: "100%" }}>
          <tbody>
            <p
              style={width < 575 ? title : smallbold}
              className={width < 575 && "p-3"}
            >
              {service.title}
            </p>
            {service.rows.map((item, index) => {
              return (
                <tr key={index}>
                  {item.map((item, index) => {
                    return (
                      <td
                        key={index}
                        style={{
                          position: "relative",
                          padding: "1%"
                        }}
                      >
                        <div
                          style={{ paddingTop: ".6vw", paddingBottom: ".6vw" }}
                        >
                          <p style={small} className="m-0">
                            {item.link ? (
                              <div>
                                <a href={item.link} className="textinfo">
                                  {item.value[0]}
                                </a>
                                {item.value[1] && item.value[1]}
                              </div>
                            ) : item.product_reference ? (
                              <Link
                                to={item.product_reference}
                                className="textinfo"
                              >
                                {item.value}
                              </Link>
                            ) : item.download ? (
                              <div
                                className="textinfo"
                                style={{ cursor: "pointer" }}
                                onClick={() => download(item.download)}
                              >
                                {item.value}
                              </div>
                            ) : (
                              item.value
                            )}
                          </p>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>

        <p className="w-100 p-2 pt-3 " style={small}>
          {comment}
        </p>
      </Container>
    </Cont>
  )
}

export default TableComparison
