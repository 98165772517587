import React, { useState } from "react"
import { Container, Row, Col, Carousel } from "react-bootstrap"
import {isWebpSupported} from 'react-image-webp/dist/utils';

const CarouselBox = ({ testimonies, width, interval }) => {
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }

  const newImg = (img) => img.split(".")[0]+".webp";

  return (
    <Row>
      <Col md={6} lg={6} className="p-0">
        <Carousel
          interval={interval && interval}
          className="w-100"
          controls={false}
          activeIndex={index}
          direction={direction}
          onSelect={() => handleSelect}
        >
          {testimonies.map((item, index) => (
            <Carousel.Item>
              <img
                style={{
                  objectFit: "cover",
                  objectPosition:
                    index > 1 && width < 769
                      ? "50% 65%"
                      : index > 1
                      ? "20% 65%"
                      : "20% 20%",
                  width: "100%",
                  height: width < 1025 ? "650px" : "30vw"
                }}
                className=""
                src={isWebpSupported() ? `/img/${newImg(item.image.path)}` : `/img/${item.image.path}`}
                alt={item.image.title}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
      <Col
        md={6}
        kg={6}
        className={width > 575 ? "" : "pt-3 pb-3 pl-1 pr-1"}
        style={{ background: "rgb(230, 239, 239)" }}
      >
        <div
          className={
            width > 575 ? "pt-4 pr-3 pb-4 pl-3" : "pt-2 pb-2 pl-3 pr-3"
          }
        >
          <h1
            style={
              width < 575
                ? {
                    fontWeight: 500,
                    fontSize: "8vw",
                    marginTop: 0
                  }
                : width > 574 && width < 769
                ? {
                    fontWeight: 500,
                    fontSize: "4vw"
                  }
                : width < 1025 && width > 768
                ? {
                    fontWeight: 500,
                    fontSize: "3vw"
                  }
                : width < 1441 && width > 1024
                ? {
                    fontWeight: 500,
                    fontSize: "2.3vw"
                  }
                : {
                    fontWeight: 500,
                    fontSize: "2vw"
                  }
            }
          >
            {testimonies[index].name}
          </h1>
          <h4
            style={
              width < 575
                ? {
                    fontWeight: 400,
                    fontSize: "4.7vw",
                    lineHeight: "initial",
                    marginTop: 0
                  }
                : width > 574 && width < 769
                ? {
                    fontWeight: 400,
                    fontSize: "2.8vw",
                    lineHeight: "initial"
                  }
                : width < 1025 && width > 768
                ? {
                    fontWeight: 400,
                    fontSize: "2vw",
                    lineHeight: "initial"
                  }
                : width < 1441 && width > 1024
                ? {
                    fontWeight: 400,
                    fontSize: "1.5vw",
                    lineHeight: "initial"
                  }
                : {
                    fontWeight: 400,
                    fontSize: "1.1vw",
                    lineHeight: "initial"
                  }
            }
          >
            {testimonies[index].position}
          </h4>
          <div>
            {testimonies[index].desc.map(item => (
              <p
                className="pt-4"
                style={
                  width < 575
                    ? {
                        fontWeight: 300,
                        fontSize: "4.7vw",
                        lineHeight: "initial",
                        marginTop: 0
                      }
                    : width > 574 && width < 769
                    ? {
                        fontWeight: 300,
                        fontSize: "2.8vw",
                        lineHeight: "initial"
                      }
                    : width < 1025 && width > 768
                    ? {
                        fontWeight: 300,
                        fontSize: "2vw",
                        lineHeight: "initial"
                      }
                    : width < 1441 && width > 1024
                    ? {
                        fontWeight: 300,
                        fontSize: "1.5vw",
                        lineHeight: "initial"
                      }
                    : {
                        fontWeight: 300,
                        fontSize: "1.1vw",
                        lineHeight: "initial"
                      }
                }
              >
                {item}
              </p>
            ))}
          </div>
          <div
            className="d-flex align-items-center"
            style={width > 575 ? { position: "absolute", bottom: "5%" } : {}}
          >
            <button
              onClick={() => index > 0 && setIndex(index - 1)}
              style={{
                border: "none",
                background: "none",
                outline: "none",
                width: "10%"
              }}
            >
              <img
                className="w-100"
                src={require("../../assets/img/left.svg").default}
                alt=""
              />
            </button>
            <h5 className="m-0">
              0{index + 1} / 0{testimonies.length}
            </h5>
            <button
              style={{
                border: "none",
                background: "none",
                outline: "none",
                width: "10%"
              }}
              onClick={() =>
                index + 1 < testimonies.length && setIndex(index + 1)
              }
            >
              <img
                className="w-100"
                src={require("../../assets/img/right.svg").default}
                alt=""
              />
            </button>
          </div>
        </div>
      </Col>

      <img
        style={{ height: "20px", background: "transparent" }}
        className="d-flex w-100"
        src={isWebpSupported() ? require("../../assets/img/neurocience_.webp") : require("../../assets/img/neurocience_.png")}
        alt="neuroscience_"
      />
    </Row>
  )
}
export default CarouselBox
