import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import HubspotForm from "react-hubspot-form";
import {isWebpSupported} from 'react-image-webp/dist/utils';

import webinar from "../../../contents/webinar";

import ActualPath from '../../../components/ActualPath'
import Banner from '../../../components/Banner'
import Loading from '../../../components/Loading'
import Button from '../../../components/Button'

import './webinar.css'

const newImg = (img) => img.split(".")[0]+".webp";

const Webinar = ({ history }) => {

  const data = webinar;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scroll(0, 0) 
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  
  const handleResize = () => setWidth(window.innerWidth) 

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '8vw',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: '4vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '3vw'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '2.3vw'
        }
      : {
          fontWeight: 500,
          fontSize: '2vw'
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '4.7vw',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2.8vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1.5vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1.1vw',
          lineHeight: 'initial'
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '1.3vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '16px',
          textDecoration: 'none'
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: 'none',
          fontSize: '2.2vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '1.5vw',
          textDecoration: 'none'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '1.2vw',
          textDecoration: 'none',
          lineHeight: 'initial'
        }
      : {
          fontSize: '1.2vw',
          fontWeight: 500,
          textDecoration: 'none',
          lineHeight: 'initial'
        }

        const colors = [
          "#e9d8e0",
          "#d5dce2",    
          "#e6efef",
          "#e9d8e0",
          "#d5dce2",    
          "#e6efef",
          "#e9d8e0",
          "#d5dce2",    
          "#e6efef",
          "#e9d8e0",
          "#d5dce2",    
          "#e6efef",   
        ]

  
  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>Join our upcoming webinars | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Join our upcoming webinars | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Join our upcoming webinars | Neuroelectrics"
          />

          <meta
            name="description"
            content="Join our upcoming webinars. Learn about our systems and how to use them from our experts!"
          />
          <meta
            name="twitter:description"
            content="Join our upcoming webinars. Learn about our systems and how to use them from our experts!"
          />
          <meta
            property="og:description"
            content="Join our upcoming webinars. Learn about our systems and how to use them from our experts!"
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/resources/webinar"
          />
          
        </Helmet>
      </>

      {/* Banner */}
      <>
        <Banner
          width={width}
          backgroundWhite={width < 426 && true}
          responsiveBannerBackgroundPaddingLeft={width < 426 && '16%'}
          heightResponsive={width > 425 && '25vw'}
          justifyDiv="center"
          paddingTop="3.6vw"
          backgroundPadding={
            width < 575
              ? 'center'
              : width > 574 && width < 769
              ? '-30vw'
              : width < 1025 && width > 768
              ? 'center'
              : width < 1441 && width > 1024
              ? 'center'
              : 'center'
          }
          textsPaddingRight={
            width < 575
              ? '0'
              : width > 574 && width < 769
              ? '32%'
              : width < 1025 && width > 768
              ? '22%'
              : width < 1441 && width > 1024
              ? '22%'
              : '22%'
          }
          title={data.banner.title}
          titleFontSize={
            width < 575
              ? '5.8vw'
              : width > 574 && width < 769
              ? '4vw'
              : width < 1025 && width > 768
              ? '2.5vw'
              : width < 1441 && width > 1024
              ? '2.5vw'
              : '2.5vw'
          }
          titleFontWeight="500"
          subtitle={data.banner.subtitle}
          subtitleFontSize={
            width < 575
              ? '4.1vw'
              : width > 574 && width < 769
              ? '2.6vw'
              : width < 1025 && width > 768
              ? '1.7vw'
              : width < 1441 && width > 1024
              ? '1.4vw'
              : '1.4vw'
          }
          subtitleFontWeight="300"
          img={isWebpSupported() ? `/img/banners/${newImg(data.banner.image.path)}` : `/img/banners/${data.banner.image.path}`}
        />
      </>

      {/* Actual Path */}
      <>
        <ActualPath
          resources
          levelOne={{ text: 'Home', url: '/' }}
          levelTwo={{ text: 'Resources', url: '/resources' }}
          actual={{ text: 'Webinar' }}
        />
      </>

      {/* Webinars */}
      <>
        <Container
          fluid
          style={
            width < 575 ? {} : { paddingLeft: '15vw', paddingRight: '15vw' }
          }
          className="pt-4 pb-4"
        >
          <Row>
            <Col >
              <h2 style={{ fontSize: '48px' }}>Upcoming webinars</h2>         
                            
              <Row>
                  {data.meetings.map((item, i) => (
                    <Col xs={12} sm={6} xs={6} className="mt-3 mb-3" key={i}>
                      
                      <Meeting
                        item={item}
                        width={width}
                        backgroundColor={colors[i]}                        
                      />
                    </Col>
                  ))}
                </Row>
                
                 {!data.meetings[0] && (
                  <div>
                    <h4 className="pt-4 pb-4 mb-0">Subscribe to our newsletter</h4>
                    <p>In order to keep up to date with our agenda, please subscribe to our newsletter</p>
                    <div className="getQuoteForm">
                      <HubspotForm
                        portalId="181943"
                        formId="38213054-8ce5-413d-b4fd-e5bfd2177ece"
                        loading={<div>Loading...</div>}
                      />
                    </div>
                  </div>  
                )}

              
              {data.meetings[0] && (
              <h3 className="mt-3" style={{ fontSize: '30px' }}>
                Speakers
              </h3>) && (
              

              data.team.items.map((item, i) => (
                <Item
                  width={width}
                  item={item}
                  key={i}
                  i={i}
                  small={small}
                  smallbold={smallbold}
                  subtitle={subtitle}
                />
              )))}
            </Col>            
          </Row>
        </Container>
      </>
    </div>
  ) : (
    <Loading />
  )
}


const Meeting = ({ item, backgroundColor }) => {  

  return (
    <Col
      style={{
        background: backgroundColor,
        height: '100%'
      }}
      className={ 'p-4 mt-2 mb-2 d-flex flex-column justify-content-between align-items-baseline' }
    >
      <h3 style={{ fontSize: '18px' }}>
        {item.title}
      </h3>
      <p>by {item.speaker}</p>
      
      <p style={{ fontSize: '14px' }}>
        <i className="fa fa-calendar"></i>
        <i className="fa fa-clock"></i>
        <i className="fa fa-redo-alt"></i> {item.date}
      </p>
      <Button text={"Register"} font={"16px"} padding={"10px 20px"} url={`/webinar/${item.id}?webinar_name=${item.id}`}/>
      
    </Col>
  )
}

const Item = ({ item, small, smallbold, subtitle, i }) => (
  <div>
  {item.active && (  
  <div>
    {item.guest && (
      <h3 className="mt-3" style={{ fontSize: '30px' }}>
        Guest Speaker
      </h3> 
    )}
    <Row className="mt-3">
    
      <Col
        md={3}
        sm={12}
        className="d-flex justify-content-center align-items-center"
      >
        <img
          style={{ borderRadius: '75px', width: '140px', height: '140px' }}
          src={isWebpSupported() ? `/img/team/${newImg(item.image.path)}` : `/img/team/${item.image.path}`}
          alt={item.image.title}
        />
      </Col>
      <Col>
        <h2 style={smallbold} className="mt-3">
          {item.name}
        </h2>
        <h6 style={subtitle}>
          <b>{item.position}</b>
        </h6>
        <p style={small}>{item.desc}</p>
      </Col>
    </Row>
  </div>
  )}
  </div>
)

export default Webinar;
