import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import HubspotForm from 'react-hubspot-form';
import {isWebpSupported} from 'react-image-webp/dist/utils';

import webinar from "../../../contents/webinar";

import ActualPath from '../../../components/ActualPath'
import Banner from '../../../components/Banner'
import Loading from '../../../components/Loading'

import './webinar.css'

const newImg = (img) => img.split(".")[0]+".webp";

const WebinarDetails = ({ match }) => {

  const id = match.params.id.split("?")[0]  
  const data = webinar.meetings.find(item => item.id === id);    
  const [width, setWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    window.scroll(0, 0)       
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '8vw',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: '4vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '3vw'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '2.3vw'
        }
      : {
          fontWeight: 500,
          fontSize: '2vw'
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '4.7vw',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2.8vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1.5vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1.1vw',
          lineHeight: 'initial'
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: 'initial',
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: '2vw',
          lineHeight: 'initial'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: '1.3vw',
          lineHeight: 'initial'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }
      : {
          fontWeight: 300,
          fontSize: '1vw',
          lineHeight: 'initial'
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: '16px',
          textDecoration: 'none'
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: 'none',
          fontSize: '2.2vw'
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: '1.5vw',
          textDecoration: 'none'
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: '1.2vw',
          textDecoration: 'none',
          lineHeight: 'initial'
        }
      : {
          fontSize: '1.2vw',
          fontWeight: 500,
          textDecoration: 'none',
          lineHeight: 'initial'
        }


  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>Join our upcoming webinars | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content="Join our upcoming webinars | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Join our upcoming webinars | Neuroelectrics"
          />

          <meta
            name="description"
            content="Join our upcoming webinars. Learn about our systems and how to use them from our experts!"
          />
          <meta
            name="twitter:description"
            content="Join our upcoming webinars. Learn about our systems and how to use them from our experts!"
          />
          <meta
            property="og:description"
            content="Join our upcoming webinars. Learn about our systems and how to use them from our experts!"
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/webinar"
          />
          
        </Helmet>
      </>

      {/* Banner */}
      <>
        <Banner
          width={width}
          backgroundWhite={width < 426 && true}
          responsiveBannerBackgroundPaddingLeft={width < 426 && '16%'}
          heightResponsive={width > 425 && '25vw'}
          justifyDiv="center"
          paddingTop="3.6vw"
          backgroundPadding={
            width < 575
              ? 'center'
              : width > 574 && width < 769
              ? '-30vw'
              : width < 1025 && width > 768
              ? 'center'
              : width < 1441 && width > 1024
              ? 'center'
              : 'center'
          }
          textsPaddingRight={
            width < 575
              ? '0'
              : width > 574 && width < 769
              ? '32%'
              : width < 1025 && width > 768
              ? '22%'
              : width < 1441 && width > 1024
              ? '22%'
              : '22%'
          }
          title={webinar.banner.title}
          titleFontSize={
            width < 575
              ? '5.8vw'
              : width > 574 && width < 769
              ? '4vw'
              : width < 1025 && width > 768
              ? '2.5vw'
              : width < 1441 && width > 1024
              ? '2.5vw'
              : '2.5vw'
          }
          titleFontWeight="500"
          subtitle={webinar.banner.subtitle}
          subtitleFontSize={
            width < 575
              ? '4.1vw'
              : width > 574 && width < 769
              ? '2.6vw'
              : width < 1025 && width > 768
              ? '1.7vw'
              : width < 1441 && width > 1024
              ? '1.4vw'
              : '1.4vw'
          }
          subtitleFontWeight="300"
          img={isWebpSupported() ? `/img/banners/${newImg(webinar.banner.image.path)}` : `/img/banners/${webinar.banner.image.path}`}
        />
      </>

      {/* Actual Path */}
      <>
        <ActualPath
          resources
          levelOne={{ text: 'Home', url: '/' }}
          levelTwo={{ text: 'Resources / Webinar', url: '/resources/webinar' }}
          actual={{ text: `${data.title}` }}
        />
      </>

      {/* Form */}
      <>
        <Container
          fluid
          style={
            width < 575 ? {} : { paddingLeft: '15vw', paddingRight: '15vw' }
          }
          className="pt-4 pb-4"
        >          
            
              <h2 style={{ fontSize: '48px', marginBottom: '10px' }}>{data.title}</h2>             

                <p style={{ fontSize: '24px' }}>by {data.speaker}</p>
                {data.subtitle !== "" && (
                <p>
                  {data.subtitle}
                </p>
                )}
                <p style={{  fontSize: '18px', marginBottom: '30px' }}>                  
                  <i className="fa fa-calendar"></i>
                  <i className="fa fa-clock"></i>
                  <i className="fa fa-redo-alt"></i> {data.date}
                </p>                  
             
              <h2 style={{ fontSize: '30px' }}>Register form</h2>

              <div className="getQuoteForm">
                <HubspotForm
                  portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                  formId={process.env.REACT_APP_WEBINAR_HBSPT_FORM_ID}
                  loading={<div>Loading...</div>}                  
                />
              </div>
           
          
        </Container>
      </>
    </div>
  ) : (
    <Loading />
  )
}

export default WebinarDetails
