import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import {isWebpSupported} from 'react-image-webp/dist/utils';

const newImg = (img) => img.split(".")[0]+".webp";

const RelatedItem = ({ item, index, width }) => {
  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  return (
    index < 4 &&
    (!item.error ? (
      <Col sm={width < 575 ? 12 : false} className="tile fade-in box p-0">
        <Link
          to={`/solution${
            item.item_preview.url_type ? "/" + item.item_preview.url_type : "s"
          }/${item.item_preview.url_param_ref}`}
          className={width < 575 ? "d-flex h-100" : ""}
        >
          <img
            src={isWebpSupported ? `/img/component/${newImg(item.item_preview.image.path)}` : `/img/component/${item.item_preview.image.path}`}
            alt={item.item_preview.image.path}
            style={{
              width: width < 575 ? "50%" : "100%",
              maxWidth: width < 575 ? "40%" : "",
              minHeight: width < 575 ? "100%" : "",
              objectFit: "cover",
              objectPosition: width < 575 ? "center" : "0 0",
              padding: "0"
            }}
          />
          <div className="p-3">
            <p style={smallbold}>{item.item_preview.title}</p>
            <p style={small} className={width < 575 && "m-0"}>
              {item.item_preview.desc}
            </p>
          </div>
        </Link>
      </Col>
    ) : (
      <Col
        className="tile fade-in box"
        style={{ maxWidth: "30%", background: "red" }}
      >
        <img
          src="https://image.flaticon.com/icons/svg/190/190406.svg"
          style={{
            padding: "25%"
          }}
        />
        <div className="p-3">
          <p
            style={{
              margin: 0,
              fontWeight: "bolder",
              color: "white"
            }}
          >
            {item.error}
          </p>
        </div>
      </Col>
    ))
  )
}

const PreviewItems = ({ data, title, titlee, id, width }) => (
  <Container
    fluid={width < 769 || (width > 1440 && true)}
    id={id}
    style={width > 1440 ? { paddingLeft: "13vw", paddingRight: "13vw" } : {}}
    className={width < 769 && "pr-4 pl-4"}
  >
    <h2 style={titlee}>{title}</h2>
    <Row className={width < 769 && "p-1"}>
      <section className="feed" id={data.category}>
        <div className="tiles" aria-live="polite">
          {data.map((item, index) => (
            <RelatedItem item={item} index={index} width={width} />
          ))}
        </div>
      </section>
    </Row>
  </Container>
)

export default PreviewItems
