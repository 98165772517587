import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Carousel } from "react-bootstrap"
import {isWebpSupported} from 'react-image-webp/dist/utils';

const newImg = (img) => img.split(".")[0]+".webp";

const Item = ({ item, render, smallbold, small, width }) => (
  <div className="tile-software fade-in box h-100">
    <Link
      to={
        item.item_preview.url_param_ref === "mrimultichannel" ||
        item.item_preview.url_param_ref === "mribipolar" ||
        item.item_preview.url_param_ref === "fnirs"
          ? {
              pathname: `/solution/starstim-integrations/${item.item_preview.url_param_ref}`
            }
          : {
              pathname: `/solution/software-integrations/${item.item_preview.url_param_ref}`
            }
      }
      style={width < 575 ? { display: "flex", height: "180px" } : {}}
    >
      {render}
      <img
        src={isWebpSupported() ? `/img/softwares/${newImg(item.item_preview.image.path)}` : `/img/softwares/${item.item_preview.image.path}`}
        alt={item.item_preview.image.path}
        style={
          width < 575
            ? {
                maxWidth: "50%",
                objectFit: "cover",
                padding: 0,
                minHeight: "100%",
                maxHeight: "100%"
              }
            : {
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "50% 0"
              }
        }
      />
      <div className="tile-text">
        <p className="m-0 p-1" style={smallbold}>
          {item.item_preview.title}
        </p>
        <p className="m-0 p-1" style={small}>
          {item.item_preview.desc}
        </p>
      </div>
    </Link>
  </div>
)

const ComponentsList = ({ data }) => {
  const [items, setItems] = useState()
  const [visible, setVisible] = useState(3)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    width > 992 && setVisible(4)
    width < 993 && width > 768 && setVisible(3)
    width < 767 && setVisible(2)

    window.addEventListener("resize", handleResize)

    setItems(data.list)
  }, [data])

  const handleResize = () => setWidth(width)

  const loadMore = () => setVisible(visible + 4)

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  if (items !== undefined && items.length > 0) {
    return (
      <section id={data.category} className={width < 575 && "pb-4"}>
        {items.length > 0 && (
          <h3 style={title} className="mt-3 ml-2 mr-0 mb-0">
            {data.category}
          </h3>
        )}
        <div className="tiles" aria-live="polite">
          {width < 575 ? (
            <Carousel className="componentsCarousel">
              {items
                .slice(0, width < 575 ? items.length : visible)
                .map((item, index) => (
                  <Carousel.Item key={index}>
                    <Item
                      item={item}
                      key={item._id}
                      smallbold={smallbold}
                      small={small}
                      width={width}
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          ) : (
            items
              .slice(0, visible)
              .map((item, index) => (
                <Item
                  item={item}
                  key={item._id}
                  smallbold={smallbold}
                  small={small}
                  width={width}
                />
              ))
          )}
        </div>
        {width > 575 && visible < items.length && (
          <button onClick={loadMore} type="button" className="load-more">
            Load more
          </button>
        )}
      </section>
    )
  } else {
    return ""
  }
}

export default ComponentsList
