import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isWebpSupported } from 'react-image-webp/dist/utils';
// import { getLegals } from "../../api/api";

import legals from "../../contents/legals";

import Loading from "../../components/Loading";

const Legals = () => {

  // let data;
  const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  const download = name =>
    (window.location.href = `${process.env.REACT_APP_API_URL}/downloads/${name}`)

  // let category;
  //   if (
  //     window.location.pathname.substring(
  //       window.location.pathname.length - 1
  //     ) === "/"
  //   ) {
  //     category = window.location.pathname
  //       .slice(0, -1)
  //       .split("/")
  //       .slice(-1)[0]
  //   } else {
  //     category = window.location.pathname.split("/").pop()
  //   }

  //   category === "terms-of-use" ? data = legals.terms_of_use 
  //   : category === "privacy-policy" ? data = legals.privacy_policy
  //   : category === "quality-policy" && download(legals.quality_policy.filename) 


  useEffect(() => {

    let category;
    if (
      window.location.pathname.substring(
        window.location.pathname.length - 1
      ) === "/"
    ) {
      category = window.location.pathname
        .slice(0, -1)
        .split("/")
        .slice(-1)[0]
    } else {
      category = window.location.pathname.split("/").pop()
    }

    // async function fetchMyAPI() {
    //   try {
    //     const newData = await getLegals();

    //     category === "terms-of-use"
    //       ? setData(newData.terms_of_use)
    //       : category === "privacy-policy"
    //       ? setData(newData.privacy_policy)
    //       : category === "quality-policy" &&
    //         download(newData.quality_policy.filename)

    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    category === "terms-of-use"
      ? setData(legals.terms_of_use)
      : category === "privacy-policy"
        ? setData(legals.privacy_policy)
        : category === "quality-policy" &&
        download(legals.quality_policy.filename)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.location.pathname]);



  const handleResize = () => setWidth(window.innerWidth)

  let a = [
    "If you have any questions regarding the use we make of your information, the first thing you should do is contact our Data Protection team by email gdpr@neuroelectrics.com. NEUROELECTRICS® BARCELONA, S.L.U. Europe Office Avinguda Tibidabo, 47 bis 08035 Barcelona, Spain",
    "US Office 1 Broadway, 14th Floor Cambridge 02142 Massachusetts, USA",
    "This privacy statement is in accordance with the EU General Data Protection Regulation. We reserve the right to update this privacy statement periodically. The latest version will be published on this page."
  ]

  const indent =
  width < 575
  ? {
    "a":{
      "text-indent": "-30px",
      
    },
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "initial",
    marginTop: 0
  }
  : width > 574 && width < 769
    ? {
      "a":{
        "text-indent": "-30px",
        
      },
      fontWeight: 300,
      fontSize: "2vw",
      lineHeight: "initial"
    }
    : width < 1025 && width > 768
      ? {
        "a":{
          "text-indent": "-30px",
          
        },
        fontWeight: 300,
        fontSize: "1.3vw",
        lineHeight: "initial"
      }
      : width < 1441 && width > 1024
        ? {
          "a":{
            "text-indent": "-30px",
            
          },
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
        : {    
          "a":{
          "text-indent": "-30px",
          
        },
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "1.3vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1vw",
              lineHeight: "initial"
            }

  const smallbold =
    width < 575
      ? {
        fontWeight: 600,
        color: "#333",
        fontSize: "16px",
        textDecoration: "none"
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 600,
          color: "#333",
          textDecoration: "none",
          fontSize: "2.2vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 600,
            color: "#333",
            fontSize: "1.5vw",
            textDecoration: "none"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 600,
              color: "#333",
              fontSize: "1.2vw",
              textDecoration: "none",
              lineHeight: "initial"
            }
            : {
              fontSize: "1.2vw",
              fontWeight: 600,
              color: "#333",
              textDecoration: "none",
              lineHeight: "initial"
            }

  return data != undefined ? (
    <div className="pt60px">
      {/* Helmet */}
      <>
        <Helmet>
          <title>{data[0].title} | Neuroelectrics</title>
          <meta
            name="twitter:title"
            content={`${data[0].title} | Neuroelectrics`}
          />
          <meta
            property="og:title"
            content={`${data[0].title} | Neuroelectrics`}
          />

          <meta name="description" content="Join our upcoming webinar" />
          <meta
            name="twitter:description"
            content="Join our upcoming webinar"
          />
          <meta property="og:description" content="Join our upcoming webinar" />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/resources/webinar"
          />
        </Helmet>
      </>
      <Container>
        <Row
          style={{ background: "white", margin: "5%", position: "relative" }}
        >
          <img
            style={{ position: "absolute", top: 0, left: 0 }}
            src={isWebpSupported() ? require("../../assets/img/neurocience_.webp") : require("../../assets/img/neurocience_.png")}
            alt="neuroscience_"
            className="w-100"
          />
          {data.map((item, i) => (
            <div
              key={item.title}
              style={{
                borderBottom: "1px solid lightgrey",
                padding: "5%",
                width: "100%"
              }}
            >
              {i === 0 ? (
                <h1 style={smallbold}>{item.title}</h1>
              ) : (
                <h2 style={smallbold}>{item.title}</h2>
              )}
              {item.text &&
              item.text.map(item => item==="<br/>" ? <br/> : (<p style={small} key={item}>{item}</p>))}
              <ul>      
                {
                  item.items &&
                  item.items.map(item=> item==="<br/>" ? <br/> : (/^<ind>/i.test(item) ? <a style={indent}>{item.substring(5)}<br/></a>: <li style={small}>{item}</li>))
                }
              </ul>
              {item.text2 &&
              item.text2.map(item => item==="<br/>" ? <br/> : (<p style={small} key={item}>{item}</p>))}
              <ul>
                {item.items2 &&
                  item.items2.map(item=> item==="<br/>" ? <br/> : (/^<ind>/i.test(item) ? <a style={indent}>{item.substring(5)}<br/></a>: <li style={small}>{item}</li>))
                }
              </ul>
              {item.text3 && 
              item.text3.map(item => item==="<br/>" ? <br/> : (<p style={small} key={item}>{item}</p>))}
              <ul>
                {item.items3 &&
                  item.items3.map(item=> item==="<br/>" ? <br/> : (/^<ind>/i.test(item) ? <a style={indent}>{item.substring(5)}<br/></a>: <li style={small}>{item}</li>))}
              </ul>
              {item.text4 && 
              item.text4.map(item => item==="<br/>" ? <br/> : (<p style={small} key={item}>{item}</p>))}
              <ul>
                {item.items4 &&
                  item.items4.map(item=> item==="<br/>" ? <br/> : (/^<ind>/i.test(item) ? <a style={indent}>{item.substring(5)}<br/></a>: <li style={small}>{item}</li>))}
              </ul>
              {item.text5 && 
              item.text5.map(item => item==="<br/>" ? <br/> : (<p style={small} key={item}>{item}</p>))}
              <ul>
                {item.items5 &&
                  item.items5.map(item=> item==="<br/>" ? <br/> : (/^<ind>/i.test(item) ? <a style={indent}>{item.substring(5)}<br/></a>: <li style={small}>{item}</li>))}
              </ul>
              {item.text6 && 
              item.text6.map(item => item==="<br/>" ? <br/> : (<p style={small} key={item}>{item}</p>))}
              <ul>
                {item.items6 &&
                  item.items6.map(item=> item==="<br/>" ? <br/> : (/^<ind>/i.test(item) ? <a style={indent}>{item.substring(5)}<br/></a>: <li style={small}>{item}</li>))}
              </ul>                                          
            </div>
          ))}
        </Row>
      </Container>
    </div>
  ) : (
    <Loading />
  )
}

export default Legals
