import React, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isWebpSupported } from 'react-image-webp/dist/utils';

import Banner from "../../components/Banner";
import Loading from "../../components/Loading";
import Button from "../../components/Button";

import "./home.css";
import newhome from "../../contents/home";

const newImg = (img) => img.split(".")[0]+".webp";

const Home = () => {
  
  const data = newhome;
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(true)  

  useEffect(() => {
    setLoading(false);
    console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  
  function handleResize() {
    setWidth(window.innerWidth);
  }

  const sectionColors = [
    {back: "#afcacc", title: "white", text: "white", button: ["white", "#7c97b1"] },
    {back: "white", title: "black", text: "black", button: ["#7c97b1", "white"]},
    {back: "#7c97b1", title: "white", text: "white", button: [] },
    {back: "white", title: "#7c97b1", text: "black", button: ["#7c97b1", "white"]},
    {back: "#c499b2", title: "white", text: "white", button: ["white", "#c499b2"]}
  ]

  const HomeButton = ({ i, item }) => (
    <div className="home-button">
      {i === 3 || i === 4 ? (
        <Button
          text={item.cta.button}
          js={item.cta.url}
          background={sectionColors[i].button[0]}
          color={sectionColors[i].button[1]}
          refe={data._id}
          marginHorizontal="0"
          margin={width < 576 ? "0 auto" : ""}
          padding={width > 1441 && "15px 30px"}
          radius={width > 1441 ? "50px" : width < 1440 && width > 768 && "75px"}
          fontWeight={"bold"}
          font={
            width < 576
              ? "4.2vw"
              : width > 575 && width < 769
              ? "3vw"
              : width < 1025 && width > 768
              ? "2vw"
              : width < 1441 && width > 1024
              ? "1.2vw"
              : "1.1vw"
          }
          whiteSpace={width < 1025 ? "normal" : "nowrap"}
          shadow
        />
      ) : (
        <Button
          text={item.cta.button}
          url={item.cta.url}
          background={sectionColors[i].button[0]}
          color={sectionColors[i].button[1]}
          refe={data._id}
          marginHorizontal="0"
          padding={width < 576 ? "15px 120px" : "15px 60px"}
          radius={width > 1441 ? "50px" : width < 1440 && width > 768 && "75px"}
          fontWeight={"bold"}
          font={
            width < 576
              ? "4.2vw"
              : width > 575 && width < 769
              ? "3vw"
              : width < 1025 && width > 768
              ? "2vw"
              : width < 1441 && width > 1024
              ? "1.2vw"
              : "1.1vw"
          }
          whiteSpace={width < 1025 ? "normal" : "nowrap"}
          shadow
        />
      )}
    </div>
  );

  const HomeImg = ({ item }) => (
    <img
      src={
        width < 576
          ? isWebpSupported()
            ? `/img/${newImg(item.image.mobile)}`
            : `/img/${item.image.mobile}`
          : isWebpSupported()
          ? `/img/${newImg(item.image.path)}`
          : `/img/${item.image.path}`
      }
      alt={item.image.title}
      className="w-100"      
    />
  );


  return !loading ? (
    <div>
      <Helmet>
        <title>Revolutionizing Brain Therapy | Neuroelectrics</title>
        <meta name="twitter:title" content="Revolutionizing Brain Therapy" />
        <meta property="og:title" content="Revolutionizing Brain Therapy" />

        <meta
          name="description"
          content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
        />
        <meta
          name="twitter:description"
          content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
        />
        <meta
          property="og:description"
          content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
        />
      </Helmet>
      <Carousel>
        {data.banner.map((item, i) => (
          <Carousel.Item key={i}>
            {/* Banner */}
            <>
              <Banner
                paddingTop="3.4vw"
                width={width}
                backgroundWhite={width < 426 && true}
                responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
                heightResponsive={width > 425 && "25vw"}
                justifyDiv="center"
                backgroundPadding={
                  width < 575
                    ? "center"
                    : width > 574 && width < 769
                    ? "0"
                    : width < 1025 && width > 768
                    ? "center"
                    : width < 1441 && width > 1024
                    ? "center"
                    : "center"
                }
                textsPaddingRight={
                  width < 575
                    ? "0"
                    : width > 574 && width < 769
                    ? "30%"
                    : width < 1025 && width > 768
                    ? "22%"
                    : width < 1441 && width > 1024
                    ? "22%"
                    : "22%"
                }
                title={item.title}
                titleFontSize={
                  width < 575
                    ? "5.8vw"
                    : width > 574 && width < 769
                    ? "4vw"
                    : width < 1025 && width > 768
                    ? "2.5vw"
                    : width < 1441 && width > 1024
                    ? "2.5vw"
                    : "2.5vw"
                }
                titleFontWeight="500"
                subtitle={
                  item.link !== "" 
                  ? <>{item.subtitle} <a href={item.link}>Discover More</a></>
                  : <>{item.subtitle}</>
                }
                subtitle1={item.subtitle1}
                subtitleFontSize={
                  width < 575
                    ? "4.1vw"
                    : width > 574 && width < 769
                    ? "2.6vw"
                    : width < 1025 && width > 768
                    ? "1.7vw"
                    : width < 1441 && width > 1024
                    ? "1.4vw"
                    : "1.4vw"
                }
                subtitleFontWeight="300"
                img={
                  isWebpSupported()
                    ? `/img/banners/${newImg(item.image.path)}`
                    : `/img/banners/${item.image.path}`
                }
                button={item.button}
              />
              
            </>
          </Carousel.Item>
        ))}
      </Carousel>

      <Container fluid>
        {data.sections.map((item, i) => (
          <Row
            key={i}
            className="justify-content-md-center home-section"
            style={{ background: sectionColors[i].back }}
          >
            {i !== 1 ? (
              <>
                <Col md={4}>
                  <h2 className="section-title" style={{ color: sectionColors[i].title }}>{item.title}</h2>
                  {(i === 3 || i === 4) && width > 576 ? (
                    <div className="d-flex pt-4 pb-4">
                      <HomeButton i={i} item={item} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={4}>
                  {i === 3 ? (
                    item.text.map((el, z) => (
                      <p key={z} className="section-text" style={{ color: sectionColors[i].text }}>{el}</p>
                    ))
                  ) : (
                    <p className="section-text" style={{ color: sectionColors[i].text }}>{item.text}</p>
                  )}

                  {i === 0 || ((i === 3 || i === 4) && width < 576) ? (
                    <div className="d-flex pt-4 pb-4">
                      <HomeButton i={i} item={item} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                {i === 2 ? (
                  <Row className="justify-content-md-center">
                    <Col md={8}>
                      <HomeImg item={item}/>                      
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Col md={8}>
                <h2 className="section-title" style={{ color: sectionColors[i].title }}>{item.title}</h2>
                <h3 className="section-subtitle" style={{ color: sectionColors[i].title }}>{item.subtitle}</h3>
                {width < 576 ? (<>
                  <div className="d-flex pt-4 pb-4">
                    <HomeButton i={i} item={item} />
                  </div>
                  <HomeImg item={item}/> 
                </>) : (<>
                  <HomeImg item={item}/>                
                  <p className="section-text" style={{ color: sectionColors[i].text }}>{item.text}</p>
                  <div className="d-flex pt-4 pb-4">
                    <HomeButton i={i} item={item} />
                  </div>
                </>)}
                
              </Col>
            )}
          </Row>
        ))}
      </Container>
    </div>
  ) : (
    <Loading />
  );
}

export default Home;
