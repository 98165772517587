import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import HttpsRedirect from "react-https-redirect"; // Not in use
import { Helmet } from "react-helmet";
import { YMInitializer } from "react-yandex-metrika";

import { menuSections } from "./data";

import "../node_modules/normalize.css/normalize.css";

import Footer from "./components/Footer";
import Menu from "./components/Menu";
import ScrollToTop from "./components/ScrollToTop";

// Screens Navigation
import Home from "./navigation/Home";
import Neuroscience from "./navigation/Neuroscience";
import Clinical from "./navigation/Clinical";
import Whoweare from "./navigation/Neuroelectrics/Whoweare";
import Team from "./navigation/Neuroelectrics/Team";
import Careers from "./navigation/Neuroelectrics/Careers";
import CareersThankyou from "./navigation/Neuroelectrics/Careers/CareersThankyou";
import Collaborators from "./navigation/Neuroelectrics/Collaborators";
import NewsEvents from "./navigation/Neuroelectrics/NewsEvents";
import FamilyStarstim from "./navigation/FamilyStarstim";
import FamilyEnobio from "./navigation/FamilyEnobio";
import Contact from "./navigation/Contact";
import TargetEditor from "./navigation/TargetEditor";
import TargetEditorEpilepsy from "./navigation/TargetEditorEpilepsy";
import Resources from "./navigation/Resources";
import Videos from "./navigation/Resources/Videos";
import Live from "./navigation/Resources/Live";
import Pdfs from "./navigation/Resources/Pdfs";
import ManualsMenu from "./navigation/Resources/ProductManuals/ManualsMenu";
import ProductManuals from "./navigation/Resources/ProductManuals";
import Software from "./navigation/Resources/Software";
import Support from "./navigation/Support";
import Product from "./navigation/Product";
import Product_enobio_dx from "./navigation/Product/Product_enobio_dx";
import Components from "./navigation/Components";
import Component from "./navigation/Components/Component.jsx";
import SoftwareIntegrations from "./navigation/SoftwareIntegrations";
import SoftwareIntegration from "./navigation/SoftwareIntegrations/SoftwareIntegration";
import ModelingServices from "./navigation/ModelingServices";
import Webinar from "./navigation/Resources/Webinar";
import WebinarDetails from "./navigation/Resources/Webinar/WebinarDetails";
import WebinarThankyou from "./navigation/Resources/Webinar/WebinarThankyou";
import Inbound from "./navigation/Inbound";
import Thankyou from "./navigation/Inbound/Thankyou";
import CookiesPolicy from "./navigation/CookiesPolicy";
import Legals from "./navigation/Legals";
import Payments from "./navigation/Payments";
import Quote from "./navigation/Forms/Quote";
import ManualsRequest from "./navigation/Forms/ManualsRequest";
import ManualsRequestThanks from "./navigation/Forms/ManualsRequest/ManualRequestThanks";
import QuoteThankyou from "./navigation/Forms/Quote/QuoteThankyou";
import Admin from "./navigation/Admin";
import NibsLanding from "./navigation/NibsLanding";

import "./main.css";

const notFound = {
  height: "720px",
  background: "#e9e9e9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#888888",
  fontWeight: "bolder",
  fontSize: "5em",
  fontFamily: "Open Sans",
};

const NotFound = () => (
  <div style={notFound}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>404 - Page Not Found | Neuroelectrics</title>

      <meta name="prerender-status-code" content="404"></meta>

      <link
        rel="icon"
        href="https://www.neuroelectrics.com/img/logo_neuroelectrics.svg"
      />
    </Helmet>
    404 - Page Not Found
  </div>
);

const Fonts = () => (
  <div className="fonts">
    <div>
      <p className="classes">Banner</p>
      <div className="classes-children">
        <h1 className="responsiveBannerTitle">Reinventing brain health</h1>

        <h2 className="responsiveBannerSubtitle">
          Personalized technologies for brain monitoring and stimulation
        </h2>
      </div>
    </div>

    <div>
      <p className="classes">Sections</p>
      <div className="classes-children">
        <h3 className="responsiveTitle">Brain Monitoring & Stimulation</h3>

        <h4 className="responsiveSubtitle">
          Our unique technology: personalised brain therapy research
        </h4>
      </div>
    </div>

    <div>
      <p className="classes">Items</p>
      <div className="classes-children">
        <h5 className="responsiveSmallTitle">Diagnostics and biomarkers</h5>

        <p className="responsiveSmallSubtitle">EEG Enobio</p>
      </div>
    </div>

    <div>
      <p className="classes">Buttons</p>
      <div className="classes-children">
        <button className="responsiveSmallSubtitle fontsButton">
          Go to action
        </button>
      </div>
    </div>
  </div>
);

const App = () => {
  return (
    <>
      <YMInitializer
        accounts={[69165310]}
        options={{ webvisor: true }}
        version="2"
      />
      <Router>
        <ScrollToTop>
          <Helmet>
            <meta charSet="utf-8" />
            {/* <meta http-equiv="Cache-control" content="max-age=31536000, public"></meta> */}
            <title>Neuroelectrics</title>
            <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
              integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
              crossorigin="anonymous"
            />
            <link
              rel="icon"
              href="https://www.neuroelectrics.com/img/logo_neuroelectrics.svg"
            />
          </Helmet>
          
          <Menu menuSections={menuSections} />

          <div
            style={{
              background: "#F7F6F5",
            }}
          >
            {window.location.pathname === "/" && <Redirect to="/" />}
            <Switch>
              <Route
                exact
                path="/menuforblog"
                component={() => <Menu menuSections={menuSections} />}
              />
              <Route
                exact
                path="/footerforblog"
                component={() => <Menu menuSections={menuSections} />}
              />

              <Route exact path="/" component={Home} />

              <Route exact path="/fonts" component={Fonts} />

              <Route path="/neuroscience/eeg" component={Neuroscience} />
              <Route
                path="/neuroscience/brainstimulation"
                component={Neuroscience}
              />
              <Route
                path="/neuroscience/home-therapy"
                component={Neuroscience}
              />
              <Route
                path="/neuroscience/research-applications"
                component={Neuroscience}
              />
              <Route path="/neuroscience/clinical" component={Clinical} />

              <Route
                exact
                path="/neuroelectrics/news-events/fda-greenlights-neuroelectrics-to-treat-patients-with-major-depression-at-home"
                component={() => {
                  window.location.href =
                    "/blog/2020/05/15/fda-greenlights-neuroelectrics-to-treat-patients-with-major-depression-at-home/";
                  return null;
                }}
              />

              <Route path="/neuroelectrics/whoweare" component={Whoweare} />
              <Route path="/neuroelectrics/team" component={Team} />
              <Route path="/neuroelectrics/careers" component={Careers} />
              <Route path="/careers-thanks" component={CareersThankyou} />
              <Route
                path="/neuroelectrics/news-events"
                component={NewsEvents}
              />
              <Route
                path="/neuroelectrics/distributors"
                component={Collaborators}
              />

              <Route exact path="/contact" component={Contact} />

              <Route exact path="/resources" component={Resources} />
              <Route path="/resources/videos" component={Videos} />
              <Route path="/resources/live" component={Live} />
              <Route path="/resources/library" component={Pdfs} />
              <Route exact path="/resources/manuals" component={ManualsMenu} />
              <Route path="/resources/manuals/:id" component={ProductManuals} />
              <Route path="/resources/software" component={Software} />
              <Route path="/ifu">
                <Redirect to="/resources/manuals" />
              </Route>
              <Route path="/downloads">
                <Redirect to="/resources/software" />
              </Route>

              <Route exact path="/documentation/paper-request">
                <Redirect to="/manualsrequest" />
              </Route>
              <Route exact path="/documentation">
                <Redirect to="/resources/manuals" />
              </Route>

              <Route exact path="/support" component={Support} />

              <Route exact path="/resources/webinar" component={Webinar} />
              <Route exact path="/webinar/:id" component={WebinarDetails} />
              <Route path="/webinar-thanks" component={WebinarThankyou} />

              <Route
                exact
                path="/resources/sdk-documentation"
                component={() => {
                  window.location.href =
                    "https://www.neuroelectrics.com/sdk-documentation";
                  return null;
                }}
              />

              <Route path="/get-quote/:id?" component={Quote} />
              <Route path="/quote-thank-you" component={QuoteThankyou} />
              <Route path="/resource/library/:id?" component={Inbound} />
              <Route path="/thankyou" component={Thankyou} />
              <Route path="/admin" component={Admin} />

              <Route path="/legals/:id" component={Legals} />
              <Route path="/cookiespolicy" component={CookiesPolicy} />
              <Route path="/payments" component={Payments} />

              <Route
                exact
                path="/solutions/starstim"
                component={FamilyStarstim}
              />

              <Route exact path="/solutions/enobio" component={FamilyEnobio} />
              <Route exact path="/solutions/enobiodx" component={Product_enobio_dx} />

              <Route exact path="/solutions/:family/:id" component={Product} />

              <Route
                exact
                path="/solutions/spareparts-consumables"
                component={Components}
              />

              {(window.location.pathname ===
                "/solution/spareparts-consumables/solidgel" ||
                window.location.pathname ===
                  "/solution/spareparts-consumables/glycerin" ||
                window.location.pathname ===
                  "/solution/spareparts-consumables/capcover") && (
                <Redirect to="/solutions/spareparts-consumables" />
              )}

              <Route
                exact
                path="/solution/spareparts-consumables/:id"
                component={Component}
              />

              <Route
                exact
                path="/solutions/software-integrations"
                component={SoftwareIntegrations}
              />

              <Route
                exact
                path="/solution/software-integrations/:id"
                component={SoftwareIntegration}
              />

              <Route
                exact
                path="/solutions/starstim-integrations"
                component={SoftwareIntegrations}
              />

              <Route
                exact
                path="/solution/starstim-integrations/:id"
                component={SoftwareIntegration}
              />

              {window.location.pathname ===
                "/solution/starstim-integrations/mribipolar" && (
                <Redirect to="/solution/starstim-integrations/mrimultichannel" />
              )}

              <Route
                exact
                path="/solutions/modeling-services"
                component={ModelingServices}
              />

              <Route
                excat
                path="/solutions/target-editor"
                component={TargetEditor}
              />

              <Route
                exact
                path="/solutions/target-editor-epilepsy"
                component={TargetEditorEpilepsy}
              />

              <Route path="/manualsrequest" component={ManualsRequest} />
              <Route
                path="/manualsrequest-thanks"
                component={ManualsRequestThanks}
              />

              <Route path="/NIBS2020" component={NibsLanding} />

              <Route path="*" component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
