import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getSupport } from "../../api/api";

import support from "../../contents/support";

import Loading from "../../components/Loading"
import ActualPath from "../../components/ActualPath"
import Form from "../../components/Form"

import "./support.sass"

const newImg = (img) => img.split(".")[0]+".webp";

const Support = () => {

  const data = support;
  // const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {

    // async function fetchMyAPI() {
    //   try {
    //     const support = await getSupport();
    //     setData(support);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    
    // setData(support);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.1vw",
          lineHeight: "initial"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  let boxHeight
  width < 575
    ? (boxHeight = "44.5vw")
    : width > 574 && width < 769
    ? (boxHeight = "40vw")
    : width > 768 && width < 1025
    ? (boxHeight = "25vw")
    : (boxHeight = "15vw")

  if (data !== undefined) {
    return (
      <div style={width > 768 ? { paddingTop: "3.6vw" } : {}}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>Welcome to Support | Neuroelectrics</title>
            <meta
              name="twitter:title"
              content="Welcome to Support | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Welcome to Support | Neuroelectrics"
            />

            <meta
              name="description"
              content="We offer a personalized lifetime support, with a response to your issues within 24 hours."
            />
            <meta
              name="twitter:description"
              content="We offer a personalized lifetime support, with a response to your issues within 24 hours."
            />
            <meta
              property="og:description"
              content="We offer a personalized lifetime support, with a response to your issues within 24 hours."
            />
            <link rel="canonical" href="https://neuroelectrics.com/support" />
          </Helmet>
        </>

        <div
          style={
            width > 575
              ? {
                  background: "linear-gradient(to bottom, #f7f6f5, #ffffff)",
                  padding: "2%"
                }
              : {
                  background: "linear-gradient(to bottom, #f7f6f5, #ffffff)",
                  paddingTop: "2%",
                  paddingBottom: 0
                }
          }
        >
          <div className={width < 575 ? "pt-2 pb-2" : ""}>
            <h2 className="text-center responsiveTitle">{data.banner.title}</h2>
            <h3 className="text-center responsiveSubtitle">
              {data.tabs.title}
            </h3>
          </div>
          <Container>
            <Row>
              {data.banner.help.map(item => (
                <Item
                  data={item}
                  smallbold={smallbold}
                  boxHeight={boxHeight}
                  width={width}
                />
              ))}
            </Row>
          </Container>
        </div>
        <div style={{ background: "white" }}>
          <ActualPath
            levelOne={{ text: "Home", url: "/" }}
            actual={{ text: "Support" }}
          />
        </div>
        <div
          style={
            width < 575
              ? { background: "#f5f5f5" }
              : { background: "#f5f5f5", padding: "2%" }
          }
        >
          <Container className={width < 575 ? "p-0" : ""}>
            <div className={width < 575 ? "p-3" : ""}>
              <h1 style={title}>Support Query Form</h1>
              <p style={smallbold}>
                Please use this form only for technical questions or for issues
                regarding your Enobio or Starstim system.
              </p>
              <p style={small}>
                In order to better assist you, please provide the Serial Number
                of your device and the NIC software version* you are using as
                well as the operating system of your laptop/computer.
              </p>
              <p style={small}>*Where your software version can be found:</p>
              <ul>
                <li style={small}>
                  Using NIC1.4: Go to SETTINGS and you will see the software
                  (NIC) version
                </li>
                <li style={small}>
                  Using NIC2.0 it is displayed in the left corner of the window
                </li>
              </ul>
            </div>

            <div
              className="getQuoteForm"
              style={width < 575 ? { margin: "5%", marginTop: 0 } : {}}
            >
              <Form
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_SUPPORT_HBSPT_FORM_ID}
                type="support"
              />
            </div>
          </Container>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

const Item = ({ data, width, smallbold, boxHeight }) => (
  <Col md={6} lg={3} sm={6} xs={6} className="p-1 resourcesItem h-100 w-100">
    {data.title === "Papers" ||
    data.title === "Blog" ||
    data.title === "Wiki" ? (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        className="w-100 h-100"
      >
        <div
          className={width < 575 ? "" : "m-1"}
          style={{
            backgroundImage: isWebpSupported() ? `url(/img/${newImg(data.image.path)})` : `url(/img/${data.image.path})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: boxHeight
          }}
        ></div>
      </a>
    ) : (
      <Link
        to={{
          pathname: `${data.url}`,
          state: {
            data: data,
            videos: data.videos,
            pdf: data.pdf,
            param: data.param && data.param
          }
        }}
        className="w-100 h-100"
      >
        <div
          className={width < 575 ? "" : "m-1"}
          style={{
            backgroundImage: isWebpSupported() ? `url(/img/${newImg(data.image.path)})` : `url(/img/${data.image.path})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: boxHeight
          }}
        ></div>
        <h5 className="m-3" style={smallbold}>
          {data.subtitle}
        </h5>
      </Link>
    )}
  </Col>
)

export default Support
