import { Component } from 'react';
import ReactGA from 'react-ga';

import { withRouter } from 'react-router-dom';

let _hsq = window._hsq = window._hsq || [];

class ScrollToTop extends Component {

  componentWillUpdate() {
    let nav = document.getElementById('desktopNav')
    nav !== null && (nav.style.pointerEvents = 'none')
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)

      const sendPageView = (location) => {
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
      }

      sendPageView(this.props.location);
    }

    const initializeReactGA = () => {
      ReactGA.initialize('UA-100557389-2')
      ReactGA.pageview(window.location.pathname)
    }

    initializeReactGA()

    setTimeout(() => {
      let nav = document.getElementById('desktopNav')
      nav !== null && (nav.style.pointerEvents = 'inherit')
    }, 200)
  }
  render() {
    return this.props.children    
  }
}

export default withRouter(ScrollToTop)



