import React, { Component } from "react"
import axios from "axios"
import "./customform.scss"

import { Redirect } from "react-router-dom"

export default class CustomForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      serialInput: "",
      email: "",
      paper: false,
      electrode: false,
      devices: false,
      nic2: false,
      nic2drop: "2.0.11",
      address: "",
      serialKit: "Wrong serial number",
      formErrorsSerialKit: null,
      formErrorsEmail: null,
      formErrorsAddress: null,
      formErrorsSerialKitBorder: "grey",
      formErrorsEmailBorder: "grey",
      formErrorsAddressBorder: "grey"
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleInputChange2 = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleInputChange3 = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleInputChange4 = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  validate = str => {
    // E20180601-22

    var letter = str.charAt(0)
    var year = str.charAt(1) + str.charAt(2) + str.charAt(3) + str.charAt(4)
    var month = str.charAt(5) + str.charAt(6)
    var day = str.charAt(7) + str.charAt(8)
    var dash = str.charAt(9)
    var nn = str.charAt(10) + str.charAt(11)

    var isValid = false

    // --------------------------------------------- //

    // TO DO: IMPROVE DASH AND NN VALIDATION

    // DASH
    if (dash === "-" && isValid == true) {
      isValid = true
    } else {
      isValid = false
    }

    // NN
    var nnString = typeof nn
    if (nnString === "string" && isValid == true) {
      isValid = true
    } else {
      isValid = false
    }

    // --------------------------------------------- //

    // FIRST LETTER
    if (letter === "E" || letter === "e") {
      isValid = true
    } else {
      isValid = false
    }

    // YEAR
    var yearNumber = Number(year)
    if (yearNumber > 2010 && yearNumber < 2030 && isValid == true) {
      isValid = true
    } else {
      isValid = false
    }

    // MONTH
    var monthNumber = Number(month)
    if (monthNumber > 0 && monthNumber <= 12 && isValid) {
      isValid = true
    } else {
      isValid = false
    }

    // DAY
    var dayNumber = Number(day)
    if (dayNumber > 0 && dayNumber <= 31 && isValid) {
      isValid = true
    } else {
      isValid = false
    }

    // FINAL
    if (isValid) {
      var finalStr = letter + year + month + day + dash + nn
      if (finalStr.length < 14) {
        this.setState(
          {
            serialKit: finalStr,
            formErrorsSerialKit: false,
            formErrorsSerialKitBorder: "grey"
          },
          () => this.sendPost()
        )
      }
    } else {
      this.setState({
        serialKit: "Wrong",
        formErrorsSerialKit: true,
        formErrorsSerialKitBorder: "red"
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    this.validate(this.state.serialInput)
  }

  sendPost = () => {
    if (this.state.email != "") {
      this.setState({ formErrorsEmail: false, formErrorsEmailBorder: "grey" })
    } else {
      this.setState({ formErrorsEmail: true, formErrorsEmailBorder: "red" })
    }

    if (this.state.address != "") {
      this.setState({
        formErrorsAddress: false,
        formErrorsAddressBorder: "grey"
      })
    } else {
      this.setState({
        formErrorsAddress: true,
        formErrorsAddressBorder: "red"
      })
    }

    setTimeout(
      function() {
        if (
          this.state.formErrorsAddress == false &&
          this.state.formErrorsEmail == false &&
          this.state.formErrorsSerialKit == false
        ) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/sendManualRequest`, {
              email: this.state.email,
              serial: this.state.serialKit,
              paper: this.state.paper,
              electrode: this.state.electrode,
              devices: this.state.devices,
              nic2: this.state.nic2,
              nic2drop: this.state.nic2drop,
              address: this.state.address
            })
            .then(function(response) {
              this.setState({
                email: "",
                serialKit: "",
                paper: false,
                electrode: false,
                devices: false,
                nic2: false,
                nic2drop: "",
                address: ""
              })
            })
            .catch(function(error) {
              console.log(error)
            })
          window.location.pathname = "/manualsrequest-thanks"
        }
      }.bind(this),
      100
    )
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="manuals">
        <label>
          <h1 className="manualsFormLabelTitle">Device serial number (SN):</h1>
          <input
            style={{ borderColor: this.state.formErrorsSerialKitBorder }}
            className="w-100"
            type="text"
            name="serialInput"
            value={this.state.serialInput}
            onChange={event =>
              this.setState({ serialInput: event.target.value })
            }
          />
          {this.state.formErrorsSerialKit ? (
            <p className="manualsError">Device serial number is not correct</p>
          ) : (
            <p />
          )}
        </label>
        <label>
          <h1 className="manualsFormLabelTitle">Email:</h1>
          <input
            style={{ borderColor: this.state.formErrorsEmailBorder }}
            className="w-100"
            type="email"
            name="email"
            value={this.state.email}
            onChange={event => this.setState({ email: event.target.value })}
          />
          {this.state.formErrorsEmail ? (
            <p className="manualsError">Email is not correct</p>
          ) : (
            <p />
          )}
        </label>
        <br />

        <label className="d-flex align-items-center">
          <input
            className="mr-2"
            name="paper"
            type="checkbox"
            checked={this.state.paper}
            onChange={this.handleInputChange}
          />
          <h1 className="manualsFormLabelTitle">
            Is Part 0. Getting Started paper manual requested?
          </h1>
        </label>
        <label className="d-flex align-items-center">
          <input
            className="mr-2"
            name="devices"
            type="checkbox"
            checked={this.state.devices}
            onChange={this.handleInputChange4}
          />
          <h1 className="manualsFormLabelTitle">
            Is Part 1. Devices paper manual requested?
          </h1>
        </label>
        <label className="d-flex align-items-center">
          <input
            className="mr-2"
            name="electrode"
            type="checkbox"
            checked={this.state.electrode}
            onChange={this.handleInputChange2}
          />
          <h1 className="manualsFormLabelTitle">
            Is Part 2. Electrode paper manual requested?
          </h1>
        </label>
        <label>
          <div className="d-flex align-items-center">
            <input
              className="mr-2"
              name="nic2"
              type="checkbox"
              checked={this.state.nic2}
              onChange={this.handleInputChange3}
            />
            <h1 className="manualsFormLabelTitle">
              Is Part 3. NIC2 paper manual requested?
            </h1>
          </div>
          {this.state.nic2 ? (
            <div>
              <label>
                <h1 className="manualsFormLabelTitle">
                  What is the NIC2 version the customer currently uses?
                </h1>
                <select
                  value={this.state.nic2drop}
                  onChange={event =>
                    this.setState({ value: event.target.nic2drop })
                  }
                >
                  <option value="2.0.11">2.0.11</option>
                </select>
              </label>
            </div>
          ) : (
            <p style={{ display: "none" }} />
          )}
        </label>
        <label>
          <h1 className="manualsFormLabelTitle">Delivery address:</h1>
          <textarea
            style={{ borderColor: this.state.formErrorsAddressBorder }}
            rows="5"
            cols="50"
            className="w-100"
            type="street-address"
            name="address"
            value={this.state.address}
            onChange={event => this.setState({ address: event.target.value })}
          />
          {this.state.formErrorsAddress ? (
            <p className="manualsError">Address is not correct</p>
          ) : (
            <p />
          )}
        </label>
        <input type="submit" className="manualsBtn" value="Submit" />
      </form>
    )
  }
}
