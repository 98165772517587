import React, { useEffect, useState, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getModelingServices } from "../../api/api";

import modelingservices from "../../contents/solutions_modelingservices";
import ebooks from "../../contents/ebooks";

import Banner from "../../components/Banner";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import ActualPath from "../../components/ActualPath";
import Resources from "../../components/Resources";
import downloadNE from "../../assets/img/download.png"

import "./modelingservices.css";

const content = () => {

  const ref = modelingservices.resources.reference_ids;
  let resources = [];
  ebooks.map(item => {
    const i = item;
    ref.map(item => {
      const ii = item;
      ii === i._id && resources.push(i);
    });
  });

  modelingservices.resources.resources_items = resources;

  return modelingservices;
}

const newImg = (img) => img.split(".")[0]+".webp";

const ModelingServices = () => {

  const data = content()
  // const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const newData = await getModelingServices();
    //     setData(newData);          
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    // setData(content);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth)

  const download = name =>
    window.open(
      `${process.env.REACT_APP_API_URL}/downloadmodelingservices/${name}`
    )

  if (data !== undefined) {
    let responsiveWidth = "85% !important"
    width > 1324
      ? (responsiveWidth = "55% !important")
      : (responsiveWidth = "85% !important")

    var responsiveBackground = "cover"
    let responsiveMargin = "15%"
    if (width < 1024 && width > 992) {
    } else if (width < 992 && width > 768) {
      responsiveBackground = "cover"
    } else if (width < 769 && width > 576) {
      responsiveBackground = "cover"
    } else if (width < 575) {
      responsiveBackground = "cover"
      responsiveMargin = "5%"
    }
    return (
      <Fragment>
        {/* Helmet */}
        <>
          <Helmet>
            <title>
              Modeling Services. Exclusive model-driven tES protocol
              optimizations
            </title>
            <meta
              name="twitter:title"
              content="Modeling Services. Exclusive model-driven tES protocol optimizations"
            />
            <meta
              property="og:title"
              content="Modeling Services. Exclusive model-driven tES protocol optimizations"
            />

            <meta
              name="description"
              content="Computational head models are essential tools for maximizing the effects of stimulation and accurately..."
            />
            <meta
              name="twitter:description"
              content="Computational head models are essential tools for maximizing the effects of stimulation and accurately..."
            />
            <meta
              property="og:description"
              content="Computational head models are essential tools for maximizing the effects of stimulation and accurately..."
            />
            <link
              rel="canonical"
              href="https://neuroelectrics.com/solutions/modeling-services"
            />
          </Helmet>
        </>

        {/* Banner */}
        <>
          <Banner
            width={width}
            backgroundWhite={width < 426 && true}
            responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
            heightResponsive={width > 425 && "25vw"}
            paddingTop="3.4vw"
            justifyDiv="center"
            backgroundPadding={
              width < 575
                ? "center"
                : width > 574 && width < 769
                ? "-30vw"
                : width < 1025 && width > 768
                ? "center"
                : width < 1441 && width > 1024
                ? "center"
                : "center"
            }
            textsPaddingRight={
              width < 575
                ? "0"
                : width > 574 && width < 769
                ? "22%"
                : width < 1025 && width > 768
                ? "22%"
                : width < 1441 && width > 1024
                ? "22%"
                : "22%"
            }
            title={data.banner.title}
            titleFontSize={
              width < 575
                ? "5.8vw"
                : width > 574 && width < 769
                ? "4vw"
                : width < 1025 && width > 768
                ? "2.5vw"
                : width < 1441 && width > 1024
                ? "2.5vw"
                : "2.5vw"
            }
            titleFontWeight="500"
            subtitle={data.banner.subtitle}
            subtitleFontSize={
              width < 575
                ? "4.1vw"
                : width > 574 && width < 769
                ? "2.6vw"
                : width < 1025 && width > 768
                ? "1.7vw"
                : width < 1441 && width > 1024
                ? "1.4vw"
                : "1.4vw"
            }
            subtitleFontWeight="300"
            img={isWebpSupported() ? `/img/banners/${newImg(data.banner.image.path)}` : `/img/banners/${data.banner.image.path}`}
          />
        </>

        {/* Actual Path */}
        <>
          <ActualPath
            levelOne={{ text: "Home", url: "/" }}
            actual={{ text: "Solutions / Modeling Services" }}
          />
        </>

        {/* Why is it important? */}
        <>
          <div
            style={
              width < 575
                ? {
                    background: "linear-gradient(to bottom, #f7f6f5, #ffffff)",
                    padding: "0 4%"
                  }
                : width > 574 && width < 769
                ? {
                    background: "linear-gradient(to bottom, #f7f6f5, #ffffff)"
                  }
                : width < 1025 && width > 768
                ? {
                    background: "linear-gradient(to bottom, #f7f6f5, #ffffff)"
                  }
                : width < 1441 && width > 1024
                ? {
                    background: "linear-gradient(to bottom, #f7f6f5, #ffffff)"
                  }
                : {
                    background: "linear-gradient(to bottom, #f7f6f5, #ffffff)"
                  }
            }
          >
            <div
              style={
                width < 575
                  ? { padding: "5%" }
                  : width > 574 && width < 769
                  ? {
                      display: "flex",
                      flexDirection: "column-reverse"
                    }
                  : width < 1025 && width > 768
                  ? {
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
                  : width < 1441 && width > 1024
                  ? {
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
                  : {
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
              }
            >
              <div
                style={
                  width < 575
                    ? {}
                    : width > 574 && width < 769
                    ? { padding: "2%" }
                    : width < 1025 && width > 768
                    ? {}
                    : width < 1441 && width > 1024
                    ? {}
                    : {}
                }
              >
                <h1
                  style={
                    width < 575
                      ? {
                          fontWeight: 500,
                          fontSize: "8vw",
                          marginTop: 0
                        }
                      : width > 574 && width < 769
                      ? {
                          fontWeight: 500,
                          fontSize: "4vw"
                        }
                      : width < 1025 && width > 768
                      ? {
                          fontWeight: 500,
                          fontSize: "3vw",
                          paddingTop: "1vw"
                        }
                      : width < 1441 && width > 1024
                      ? {
                          fontWeight: 500,
                          fontSize: "2.3vw",
                          paddingTop: "1vw"
                        }
                      : {
                          fontWeight: 500,
                          fontSize: "2vw",
                          paddingTop: "1vw"
                        }
                  }
                >
                  {data.concept.title}
                </h1>
                <p
                  className="mt-0"
                  style={
                    width < 575
                      ? {
                          fontWeight: 500,
                          fontSize: "4.7vw",
                          lineHeight: "initial",
                          marginTop: 0
                        }
                      : width > 574 && width < 769
                      ? {
                          fontWeight: 500,
                          fontSize: "2.8vw",
                          lineHeight: "initial"
                        }
                      : width < 1025 && width > 768
                      ? {
                          fontWeight: 500,
                          fontSize: "2vw",
                          lineHeight: "initial"
                        }
                      : width < 1441 && width > 1024
                      ? {
                          fontWeight: 500,
                          fontSize: "1.5vw",
                          lineHeight: "initial"
                        }
                      : {
                          fontWeight: 500,
                          fontSize: "1.2vw",
                          lineHeight: "initial"
                        }
                  }
                >
                  {data.concept.subtitle}
                </p>
                <p
                  style={
                    width < 575
                      ? {
                          fontWeight: 300,
                          fontSize: "4.7vw",
                          lineHeight: "initial",
                          marginTop: 0
                        }
                      : width > 574 && width < 769
                      ? {
                          fontWeight: 300,
                          fontSize: "2.8vw",
                          lineHeight: "initial"
                        }
                      : width < 1025 && width > 768
                      ? {
                          fontWeight: 300,
                          fontSize: "2vw",
                          lineHeight: "initial"
                        }
                      : width < 1441 && width > 1024
                      ? {
                          fontWeight: 300,
                          fontSize: "1.5vw",
                          lineHeight: "initial"
                        }
                      : {
                          fontWeight: 300,
                          fontSize: "1.1vw",
                          lineHeight: "initial"
                        }
                  }
                >
                  {data.concept.application}
                </p>
              </div>

              {width > 575 && (
                <div className="d-flex justify-content-center p-5">
                  <img
                    className="w-100"
                    src={isWebpSupported() ? `/img/${newImg(data.concept.image.path)}` : `/img/${data.concept.image.path}`}
                    alt={data.concept.image.title}
                  />
                </div>
              )}
            </div>
          </div>
        </>

        {/* 4 Boxes */}
        <>
          <div
            style={
              width < 575
                ? {
                    background: "#f7f6f5",
                    padding: "0 3%"
                  }
                : width > 574 && width < 769
                ? { background: "#f7f6f5", padding: "4%" }
                : width < 1025 && width > 768
                ? { background: "#f7f6f5", padding: "5% 1%" }
                : width < 1441 && width > 1024
                ? { background: "#f7f6f5", padding: "5% 1%" }
                : { background: "#f7f6f5", padding: "5% 5%" }
            }
          >
            <Container>
              <Row>
                {data.features.map(item => (
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    style={
                      width < 575
                        ? {
                            margin: "3.5% 0 0 0",
                            padding: "1%"
                          }
                        : width > 574 && width < 769
                        ? {
                            margin: "0 0",
                            padding: "1%"
                          }
                        : width < 1025 && width > 768
                        ? {
                            padding: "1%"
                          }
                        : width < 1441 && width > 1024
                        ? {
                            padding: "1%"
                          }
                        : {
                            padding: "1%",
                            display: "flex"
                          }
                    }
                  >
                    <div
                      className="p-5 h-100"
                      style={{
                        background: "#ffffff",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignContent: "space-between"
                      }}
                    >
                      <p
                        style={
                          width < 575
                            ? {
                                fontWeight: 500,
                                fontSize: "4.7vw",
                                textAlign: "center",
                                lineHeight: "initial"
                              }
                            : width > 574 && width < 769
                            ? {
                                fontWeight: 500,
                                fontSize: "2.8vw",
                                lineHeight: "initial"
                              }
                            : width < 1025 && width > 768
                            ? {
                                fontWeight: 500,
                                fontSize: "2vw",
                                lineHeight: "initial"
                              }
                            : width < 1441 && width > 1024
                            ? {
                                fontWeight: 500,
                                fontSize: "1.6vw",
                                lineHeight: "initial"
                              }
                            : {
                                fontWeight: 500,
                                fontSize: "1.3vw",
                                lineHeight: "initial"
                              }
                        }
                      >
                        {item.title}
                      </p>

                      <p
                        style={
                          width < 575
                            ? {
                                fontWeight: 300,
                                fontSize: "4.3vw",
                                paddingTop: "1vw",
                                textAlign: "center",
                                lineHeight: "initial"
                              }
                            : width > 574 && width < 769
                            ? {
                                fontWeight: 300,
                                fontSize: "2vw"
                              }
                            : width < 1025 && width > 768
                            ? {
                                fontWeight: 300,
                                fontSize: "1.55vw",
                                lineHeight: "initial"
                              }
                            : width < 1441 && width > 1024
                            ? {
                                fontWeight: 300,
                                fontSize: "1.25vw",
                                lineHeight: "initial"
                              }
                            : {
                                fontWeight: 300,
                                fontSize: "1vw",
                                lineHeight: "initial"
                              }
                        }
                      >
                        {item.desc}
                      </p>
                      <img
                        className="w-100"
                        style={
                          width < 575
                            ? { padding: "0" }
                            : width > 574 && width < 769
                            ? { padding: "0" }
                            : width < 1025 && width > 768
                            ? { padding: "0" }
                            : width < 1441 && width > 1024
                            ? { padding: "0" }
                            : { padding: "0" }
                        }
                        src={isWebpSupported() ? `/img/${newImg(item.image.path)}` : `/img/${item.image.path}`}
                        alt={item.image.title}
                      />
                    </div>
                  </Col>
                ))}

                <div
                  className={
                    width < 769
                      ? "h-100 w-100 d-flex align-items-center justify-content-center mt-4 mb-4"
                      : "h-100 w-100 d-flex align-items-center justify-content-center mt-5 pt-5"
                  }
                >
                  <Button
                    text="Get quote"
                    url="/get-quote"
                    refe="modelingservices"
                    marginHorizontal="0"
                    padding={width > 1441 && "15px 27px"}
                    radius={width > 1441 && "50px"}
                    font={
                      width < 575
                        ? "3.8vw"
                        : width > 574 && width < 769
                        ? "2vw"
                        : width < 1025 && width > 768
                        ? "1.6vw"
                        : width < 1441 && width > 1024
                        ? "1.2vw"
                        : "1.1vw"
                    }
                    shadow
                  />
                </div>
              </Row>
            </Container>
          </div>
        </>

        {/* Table - In mobile is hidden! */}
        <>
          {width > 755 && (
            <Container
              fluid={true}
              style={
                width < 575
                  ? { background: "#f7f6f5", padding: "5%" }
                  : width > 574 && width < 769
                  ? {
                      background: "#f7f6f5",
                      display: "flex",
                      flexDirection: "column-reverse"
                    }
                  : width < 1025 && width > 768
                  ? {
                      background: "#f7f6f5",
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
                  : width < 1441 && width > 1024
                  ? {
                      background: "#f7f6f5",
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
                  : {
                      background: "#f7f6f5",
                      paddingLeft: "15vw",
                      paddingRight: "15vw"
                    }
              }
              className="pt-4 pb-4 mb-4"
            >
              <Row className="modelingServicesTableRow">
                <div className="w-100" style={{ paddingBottom: "3%" }}>
                  <h2
                    style={
                      width < 575
                        ? {
                            fontWeight: 500,
                            fontSize: "8vw",
                            marginTop: 0
                          }
                        : width > 574 && width < 769
                        ? {
                            fontWeight: 500,
                            fontSize: "4vw"
                          }
                        : width < 1025 && width > 768
                        ? {
                            fontWeight: 500,
                            fontSize: "3vw",
                            paddingTop: "1vw"
                          }
                        : width < 1441 && width > 1024
                        ? {
                            fontWeight: 500,
                            fontSize: "2.3vw",
                            paddingTop: "1vw"
                          }
                        : {
                            fontWeight: 500,
                            fontSize: "2vw",
                            paddingTop: "1vw"
                          }
                    }
                  >
                    {data.table.title}
                  </h2>
                  <h2
                    style={
                      width < 575
                        ? {
                            fontWeight: 400,
                            fontSize: "4.7vw",
                            lineHeight: "initial",
                            marginTop: 0
                          }
                        : width > 574 && width < 769
                        ? {
                            fontWeight: 300,
                            fontSize: "2.8vw",
                            lineHeight: "initial"
                          }
                        : width < 1025 && width > 768
                        ? {
                            fontWeight: 400,
                            fontSize: "2.3vw",
                            lineHeight: "initial",
                            paddingTop: "1vw"
                          }
                        : width < 1441 && width > 1024
                        ? {
                            fontWeight: 400,
                            fontSize: "1.7vw",
                            lineHeight: "initial",
                            paddingTop: "1vw"
                          }
                        : {
                            fontWeight: 400,
                            fontSize: "1.3vw",
                            lineHeight: "initial",
                            paddingTop: "1vw"
                          }
                    }
                  >
                    {data.table.subtitle}
                  </h2>
                </div>
                <table className="modelingServicesTable">
                  <tbody>
                    <tr>
                      {data.table.rows[0].map((item, index) => {
                        return item.value === "download" ? (
                          <th key={index}>
                            <img
                              onClick={() => download(item.name)}
                              style={{ cursor: "pointer" }}
                              className="w-25"
                              src={downloadNE}
                              alt="downloadModelingService"
                            />
                          </th>
                        ) : (
                          <th key={index}>{item.value}</th>
                        )
                      })}
                    </tr>
                    <tr>
                      {data.table.rows[1].map((item, index) => {
                        return item.value === "download" ? (
                          <th key={index}>
                            <img
                              onClick={() => download(item.name)}
                              style={{ cursor: "pointer" }}
                              className="w-25"
                              src={downloadNE}
                              alt="downloadModelingService"
                            />
                          </th>
                        ) : (
                          <td key={index}>{item.value}</td>
                        )
                      })}
                    </tr>
                    <tr>
                      {data.table.rows[2].map((item, index) => {
                        return item.value === "download" ? (
                          <th key={index}>
                            <img
                              onClick={() => download(item.name)}
                              style={{ cursor: "pointer" }}
                              className="w-25"
                              src={downloadNE}
                              alt="downloadModelingService"
                            />
                          </th>
                        ) : (
                          <td key={index}>{item.value}</td>
                        )
                      })}
                    </tr>
                    <tr>
                      {data.table.rows[3].map((item, index) => {
                        return item.value === "download" ? (
                          <th key={index}>
                            <img
                              onClick={() => download(item.name)}
                              style={{ cursor: "pointer" }}
                              className="w-25"
                              src={downloadNE}
                              alt="downloadModelingService"
                            />
                          </th>
                        ) : (
                          <td key={index}>{item.value}</td>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              </Row>
            </Container>
          )}
        </>

        {/* Resources */}
        <>
          {data.resources.resources_items.length > 0 && (
            <Resources
              data={data.resources.resources_items}
              title={data.resources.title}
            />
          )}
        </>

        {/* Download */}
        <>
          <div
            style={{
              backgroundImage: isWebpSupported() ? "url(/img/components_support.webp)" : "url(/img/components_support.jpg)",
              backgroundPosition: "center",
              backgroundSize: responsiveBackground,
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <Row
              style={{
                padding: "2%",
                marginTop: "5%",
                marginBottom: "5%",
                marginLeft: responsiveMargin,
                marginRight: responsiveMargin,
                background: "white",
                width: responsiveWidth
              }}
            >
              <Col md={7} className="">
                <h2
                  style={
                    width < 575
                      ? {
                          fontWeight: 500,
                          fontSize: "5vw",
                          lineHeight: "initial",
                          marginTop: 0
                        }
                      : width > 574 && width < 769
                      ? {
                          fontWeight: 500,
                          fontSize: "2.8vw",
                          lineHeight: "initial"
                        }
                      : width < 1025 && width > 768
                      ? {
                          fontWeight: 500,
                          fontSize: "2.3vw",
                          lineHeight: "initial",
                          margin: 0
                        }
                      : width < 1441 && width > 1024
                      ? {
                          fontWeight: 500,
                          fontSize: "1.7vw",
                          lineHeight: "initial",
                          margin: 0
                        }
                      : {
                          fontWeight: 500,
                          fontSize: "1.7vw",
                          lineHeight: "initial",
                          margin: 0
                        }
                  }
                >
                  {data.download.title}
                </h2>
                <p
                  style={
                    width < 575
                      ? {
                          fontWeight: 400,
                          fontSize: "4.7vw",
                          lineHeight: "initial",
                          marginTop: 0
                        }
                      : width > 574 && width < 769
                      ? {
                          fontWeight: 300,
                          fontSize: "2.8vw",
                          lineHeight: "initial"
                        }
                      : width < 1025 && width > 768
                      ? {
                          fontWeight: 400,
                          fontSize: "2vw",
                          lineHeight: "initial",
                          paddingTop: "1vw"
                        }
                      : width < 1441 && width > 1024
                      ? {
                          fontWeight: 300,
                          fontSize: "1.5vw",
                          lineHeight: "initial"
                        }
                      : {
                          fontWeight: 300,
                          fontSize: "1.5vw",
                          lineHeight: "initial"
                        }
                  }
                >
                  {data.download.intro}
                </p>
              </Col>
              <Col md={5} className="p-2">
                <a
                  className="componentsSupportLink"
                  href={`${process.env.REACT_APP_API_URL}/downloads/StimTargeter_Guide.pdf`}
                  style={{
                    borderBottom: "1px solid lightgrey",
                    marginBottom: "22% !important",
                    width: "100%",
                    padding: "4%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img
                    style={{ width: "2.2vw" }}
                    src={isWebpSupported() ? "/img/head-cap-icon.webp" : "/img/head-cap-icon.png"}
                    alt="/img/head-cap-icon.png"
                  />
                  <h3
                    style={{
                      fontSize: "1vw",
                      paddingLeft: ".8vw",
                      height: "100%",
                      margin: 0,
                      color: "#555555"
                    }}
                  >
                    Stimtargeter Guide
                  </h3>
                  <img
                    style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                    src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                    alt="/img/arrow.png"
                  />
                </a>

                <Link
                  className="componentsSupportLink"
                  to="/solutions/target-editor"
                  style={{
                    marginBottom: "22% !important",
                    width: "100%",
                    padding: "5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1vw",
                      height: "100%",
                      margin: 0,
                      color: "#555555"
                    }}
                  >
                    or visit our <u>target editor</u>{" "}
                  </h3>

                  <img
                    style={{ width: "1.5vw", paddingLeft: ".5vw" }}
                    src={isWebpSupported() ? "/img/arrow.webp" : "/img/arrow.png"}
                    alt="/img/arrow.png"
                  />
                </Link>
              </Col>
            </Row>
          </div>
        </>
      </Fragment>
    )
  } else {
    return <Loading />
  }
}

export default ModelingServices
