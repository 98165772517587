import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getNews } from "../../../api/api";

import neuroelectrics_newsandevents from "../../../contents/neuroelectrics_newsandevents";

import ActualPath from "../../../components/ActualPath";
import Loading from "../../../components/Loading";
import Banner from "../../../components/Banner";

const NewsEvents = () => {

  const data = neuroelectrics_newsandevents;
  // const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scroll(0, 0);

    // async function fetchMyAPI() {
    //   try {
    //     const news = await getNews();
    //     setData(news);             
    //   }  catch (error) {
    //     console.log(error);
    //  }        
    // }    
    // fetchMyAPI();

    // setData(neuroelectrics_newsandevents);

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth)

  const newImg = (img) => img.split(".")[0]+".webp";

  var colors = [
    "#7a92a4",
    "#ffffff",
    "#555555",
    "#ffffff",
    "#ffffff",
    "#7a92a4",
    "#ffffff",
    "#333333"
  ]

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          lineHeight: "initial"
        }

  return data !== undefined ? (
    <div>
      {/* Helmet */}
      <>
        <Helmet>
          <title>News & Events | Neuroelectrics</title>
          <meta name="twitter:title" content="News & Events | Neuroelectrics" />
          <meta property="og:title" content="News & Events | Neuroelectrics" />

          <meta
            name="description"
            content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
          />
          <meta
            name="twitter:description"
            content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
          />
          <meta
            property="og:description"
            content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/neuroelectrics/news&events"
          />
        </Helmet>
      </>

      {/* Banner */}
      <>
        {width > 575 && (
          <div
            style={
              width < 575
                ? {
                    background:
                      "linear-gradient(to bottom right, #f7f6f5, #eee)"
                  }
                : {}
            }
          >
            <Banner
              width={width}
              backgroundWhite={width < 426 && true}
              responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
              heightResponsive={width > 425 && "15vw"}
              justifyDiv="center"
              paddingTop="3.4vw"
              textsPaddingRight={
                width < 575
                  ? "0"
                  : width > 574 && width < 769
                  ? "22%"
                  : width < 1025 && width > 768
                  ? "22%"
                  : width < 1441 && width > 1024
                  ? "22%"
                  : "22%"
              }
              title={data.banner.title}
              titleFontSize={
                width < 575
                  ? "5.8vw"
                  : width > 574 && width < 769
                  ? "4vw"
                  : width < 1025 && width > 768
                  ? "2.5vw"
                  : width < 1441 && width > 1024
                  ? "2.5vw"
                  : "2.5vw"
              }
              titleFontWeight="400"
            />
          </div>
        )}
      </>

      <ActualPath
        levelOne={{ text: "Home", url: "/" }}
        actual={{ text: "Neuroelectrics / Come & meet us" }}
      />

      <div className={width < 575 ? "pb-4 mb-2" : "pt-4 pb-4 mb-2"}>
        <div className="p-3">
          <Container>
            <Row>
              <h1 style={title}>Neuroelectrics® news</h1>
            </Row>
            <Row>
              {data.news.map((item, i) => (
                <Col
                  key={i}
                  style={{
                    padding: "3%",
                    margin: "1%",
                    background: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={isWebpSupported() ? `/img/${newImg(item.image.path)}` : `/img/${item.image.path}`}
                    alt={item.image.title}
                  />
                  <h2 style={smallbold} className="pt-3">
                    {item.title}
                  </h2>
                  {!item.placeholder && (
                    <div>
                      {false ? (
                        <Link
                          style={smallbold}
                          to={item.src}
                          style={{ color: "inherit" }}
                        >
                          Read more
                          <span
                            style={{
                              color: "#7a92a4",
                              fontWeight: "400",
                              marginLeft: "2.2%"
                            }}
                          >
                            ›
                          </span>
                        </Link>
                      ) : (
                        <a
                          style={smallbold}
                          href={item.src}
                          style={{ color: "inherit" }}
                        >
                          Read more
                          <span
                            style={{
                              color: "#7a92a4",
                              fontWeight: "400",
                              marginLeft: "2.2%"
                            }}
                          >
                            ›
                          </span>
                        </a>
                      )}
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="p-3">
          <Container>
            <Row>
              <h4 style={title}>Come and Meet us at conferences</h4>
            </Row>
            <Row>
              {data.events.map((item, i) => (
                <Col key={i} sm={3} className="mb-3">
                  <div
                    style={{
                      background: colors[i],
                      padding: "5%",
                      height: "100%",
                      color:
                        i === 0 || i === 2 || i === 5 || i === 7
                          ? "#fff"
                          : "#333"
                    }}
                  >
                    <p
                      style={small}
                      style={{
                        color:
                          i === 0 || i === 2 || i === 5 || i === 7
                            ? "#fff"
                            : "#333"
                      }}
                    >
                      {item.date}, {item.place}
                    </p>
                    <h6 style={small}>{item.title}</h6>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="p-3">
          <Container>
            <Row>
              <h4 style={title}>{data.videos.title}</h4>
            </Row>
            <Row>
              {data.videos.videos.map((item, i) => (
                <Col
                  md={6}
                  key={i}
                  style={{ padding: "2%", background: "white" }}
                >
                  <iframe
                    title={`news-videos-${i}`}
                    className="w-100"
                    style={{ height: "35vh" }}
                    src={`https://www.youtube.com/embed/${item.src}?loop=1&playlist=${item.src}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                    allowfullscreen
                  ></iframe>
                  <h5 style={smallbold}>
                    <b> {item.title}</b>
                  </h5>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="p-3">
          <Container>
            <Row>
              <h4 style={title}>Prizes we won</h4>
            </Row>
            <Row>
              {data.prices.prices.map((item, i) => (
                <Col key={i} xs={6} sm={6} md={3} style={{ height: "100%" }}>
                  <div
                    style={{
                      height: width < 575 ? "170px" : "270px",
                      display: "flex",
                      alignItems: "center",
                      background: "#fff",
                      padding: "5%"
                    }}
                  >
                    <img
                      className="w-100"
                      src={isWebpSupported() ? `/img/prizes/${newImg(item.image.path)}`: `/img/prizes/${item.image.path}`}
                      alt={item.image.title}
                    />
                  </div>

                  <h5 className="p-3" style={small}>
                    {item.text}
                  </h5>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default NewsEvents
