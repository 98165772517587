import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getInbound } from "../../api/api";

import ebooks from "../../contents/ebooks";
import inbound from "../../contents/inbound";

import axios from "axios";

import HubspotForm from "react-hubspot-form";
import Loading from "../../components/Loading";

import "./inbound.css";

const content = (id) => {
  let file = {};
  ebooks.map(item => item._id === id && (file = item));
  inbound.preview = file;
  return inbound;
};

const newImg = (img) => img.split(".")[0]+".webp";

const Inbound = ({ match, history }) => {

  // const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  const { id } = match.params;
  const data = content(id);

  useEffect(() => {

    // async function fetchMyAPI() {
    //   try {
    //     const resources = await getInbound(id);
    //     setData(resources);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // fetchMyAPI();

    // setData(content(id));

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const submit = form => {
    let country
    form.map(item => {
      return item.name === "country" && (country = item.value)
    })
    axios
      .post(`${process.env.REACT_APP_API_URL}/inbound/${id}`, {
        country,
        form: form,
        file: data.preview,
        type: "download"
      })
      .then(res =>
        history.push({
          pathname: "/thankyou",
          state: { id: data.preview.inbound_page.title }
        })
      )
      .catch(err => console.log(err))
  }

  return (
    data != undefined && (
      <div
        className={width > 575 && "pt60px pb-5"}
        style={{
          background: isWebpSupported() ? `url(/img/inbound2.webp) no-repeat` : `url(/img/inbound2.png) no-repeat`,
          backgroundSize: "contain"
        }}
      >
        {/* Helmet */}
        <>
          <Helmet>
            <title>Download Ebooks and Whitepapers | Neuroelectrics</title>
            <meta
              name="twitter:title"
              content="Download Ebooks and Whitepapers | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Find out here our Ebooks, Whitepapers & Case Studies | Neuroelectrics"
            />

            <meta
              name="description"
              content={data.preview.inbound_page.desc.map(item => item)}
            />
            <meta
              name="twitter:description"
              content={data.preview.inbound_page.desc.map(item => item)}
            />
            <meta
              property="og:description"
              content={data.preview.inbound_page.desc.map(item => item)}
            />
            <link rel="canonical" href="https://neuroelectrics.com/inbound" />

            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <Container>
          <Row>
            <Col sm={12} md={6} className={width < 575 && "p-3"}>
              <h1 className="responsiveTitle">
                {data.preview.inbound_page.title}
              </h1>
              <h2 className="responsiveSmallTitle pb-4">
                {data.preview.inbound_page.subtitle}
              </h2>
              {data.preview.inbound_page.desc.map(item => (
                <p className="mb-2">{item}</p>
              ))}

              <div>
                <img
                  className="w-100"
                  style={{ background: "#fff", padding: "2%" }}
                  src={isWebpSupported() ? `/img/ebooks/${newImg(data.preview.image.path)}`: `/img/ebooks/${data.preview.image.path}`}
                  alt={data.preview.image.title}
                />
              </div>
            </Col>
            <Col
              className={
                width > 768
                  ? "pl-5 ml-5 getQuoteForm downloadForm"
                  : "p-4 getQuoteForm downloadForm"
              }
            >
              <h3>Download</h3>
              <div className="getQuoteForm downloadForm">
                <HubspotForm
                  portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                  formId={process.env.REACT_APP_INBOUND_HBSPT_FORM_ID}
                  loading={<Loading />}
                  onSubmit={(form) => submit(form)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};

export default withRouter(Inbound);
