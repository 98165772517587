import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import {isWebpSupported} from 'react-image-webp/dist/utils';

import axios from "axios"

const Thankyou = ({ location }) => {
  const [data, setData] = useState()
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.scroll(0, 0)

    axios
      .get(`/`)
      .then(res => setData(res.data))
      .catch(err => console.log(err))

    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const download = name => {
    window.open(`${process.env.REACT_APP_API_URL}/downloads/${name}`)
  }

  return (
    data != undefined && (
      <div
        className={width > 575 && "pt60px"}
        style={{
          background: isWebpSupported() ? `url(/img/workshop.webp) no-repeat right` : `url(/img/workshop.jpg) no-repeat right`,
          backgroundSize: "cover",
          width: "100%",
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          style={{
            background: "white",
            padding: "3%",
            textAlign: "center",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            style={{
              height: "20px",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%"
            }}
            src={isWebpSupported() ? require("../../assets/img/neurocience_.webp") : require("../../assets/img/neurocience_.png")}
            alt="neuroscience_"
          />
          <h1>Thank you!</h1>
          <p
            style={{
              fontWeight: 400,
              color: "#555",
              width: "45%"
            }}
          >
            A copy of our PDF "{location.state.id}" writen by experts in
            Neuroelectrics® is on its way to your inbox. We hope you will enjoy
            it. Please check out our Resources section for more information!
          </p>

          <img
            style={{
              height: "20px",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%"
            }}
            src={isWebpSupported() ? require("../../assets/img/neurocience_.webp") : require("../../assets/img/neurocience_.png")}
            alt="neuroscience_"
          />
        </div>

        <Link
          to="/resources"
          className="downloadThanksButton"
          style={{ padding: ".8vw 2vw", margin: 0, outline: "none" }}
        >
          Read more
        </Link>
      </div>
    )
  )
}

export default Thankyou
