import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { Helmet } from "react-helmet"

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

import "./targeteditor.css"

import axios from "axios"

var country_list = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao, People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia (Slovak Republic)",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe"
]

const TargetEditor = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const [date, setDate] = useState(new Date())

  const [fileUpload, setFileUpload] = useState()

  const [fileEmpty, setFileEmpty] = useState(true)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => setWidth(window.innerWidth)

  const handleSubmit = e => {
    e.preventDefault()

    const files = new FormData()

    files.append("file", fileUpload.files[0])

    var application = {
      name: e.target[0].value,
      lastname: e.target[1].value,
      email: e.target[2].value,
      phone: e.target[3].value,
      institution: e.target[4].value,
      country: e.target[5].value,
      file: e.target[6].value,
      device_info: e.target[7].value,
      cap: e.target[8].value,
      nic: e.target[9].value,
      stimulation: e.target[10].value,
      intensity_current: e.target[11].value,
      intensity_total: e.target[12].value,
      electrodes: e.target[13].value,
      message: e.target[14].value,
      patient: e.target[15].value,
      date: e.target[16].value
    }

    for (var key in application) {
      files.append(key, application[key])
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/neuroelectrics/targeteditor`,
        files
      )
      .then(res => window.location.reload() && window.scrollTo(0, 0))
      .catch(err => console.log(err))
  }

  const title =
    width < 575
      ? {
        fontWeight: 500,
        fontFamily: "Roboto",
        fontSize: "8vw",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "4vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontFamily: "Roboto",
            fontSize: "3vw"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontFamily: "Roboto",
              fontSize: "2.3vw"
            }
            : {
              fontWeight: 500,
              fontFamily: "Roboto",
              fontSize: "2vw"
            }

  const small =
    width < 575
      ? {
        fontWeight: 300,
        fontSize: "4.7vw",
        lineHeight: "initial",
        marginTop: 0
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 300,
              fontSize: "1.5vw",
              lineHeight: "initial"
            }
            : {
              fontWeight: 300,
              fontSize: "1.1vw",
              lineHeight: "initial"
            }

  const smallbold =
    width < 575
      ? {
        fontWeight: 500,
        fontSize: "16px",
        textDecoration: "none"
      }
      : width > 574 && width < 769
        ? {
          fontWeight: 500,
          textDecoration: "none",
          fontSize: "2.2vw"
        }
        : width < 1025 && width > 768
          ? {
            fontWeight: 500,
            fontSize: "1.5vw",
            textDecoration: "none"
          }
          : width < 1441 && width > 1024
            ? {
              fontWeight: 500,
              fontSize: "1.2vw",
              textDecoration: "none",
              lineHeight: "initial"
            }
            : {
              fontSize: "1.2vw",
              fontWeight: 500,
              textDecoration: "none",
              lineHeight: "initial"
            }

  return (
    <Fragment>
      {/* Helmet */}
      <>
        <Helmet>
          <title>
            Towards personalized model-driven neuromodulation | Neuroelectrics
          </title>
          <meta
            name="twitter:title"
            content="Towards personalized model-driven neuromodulation | Neuroelectrics"
          />
          <meta
            property="og:title"
            content="Towards personalized model-driven neuromodulation | Neuroelectrics"
          />

          <meta
            name="description"
            content="Computational head models are essential tools for maximizing the effects of stimulation and accurately interpreting the results of your study. The electrical current distribution (or equivalent electric field) in tES determines its effects on neurons."
          />
          <meta
            name="twitter:description"
            content="Computational head models are essential tools for maximizing the effects of stimulation and accurately interpreting the results of your study. The electrical current distribution (or equivalent electric field) in tES determines its effects on neurons."
          />
          <meta
            property="og:description"
            content="Computational head models are essential tools for maximizing the effects of stimulation and accurately interpreting the results of your study. The electrical current distribution (or equivalent electric field) in tES determines its effects on neurons."
          />
          <link
            rel="canonical"
            href="https://neuroelectrics.com/solutions/target-editor"
          />
        </Helmet>
      </>

      <Container style={{ paddingTop: "5.6vw", background: "transparent" }}>
        <h1 style={title}>Neuroelectrics® Stimtargeter</h1>
      </Container>
      {/* Target Editor */}
      <>
        <iframe
          title="target editor program"
          src={process.env.REACT_APP_LOCAL_UNITY_URL}
          frameBorder="0"
          scrolling="np"
          style={{ width: "100%", height: "45vw", overflow: "hidden" }}
        ></iframe>
      </>

      {/* Form */}
      <>
        <div className={width > 575 ? "p-5" : "p-4"}>
          <Container className="pb-5">
            <Row>
              <Form
                className="w-100 targetEditorForm getQuoteForm"
                onSubmit={handleSubmit}
                style={{ padding: "3%" }}
              >
                <Row>
                  <Col xs={12}>
                    <h2 style={title}>Customer information</h2>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="name">
                      <Form.Label className="formTitle" style={small}>
                        First name*
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter name"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="lastname">
                      <Form.Label className="formTitle" style={small}>
                        Last name*
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter last name"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="email">
                      <Form.Label className="formTitle" style={small}>
                        Email address*
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter email"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="phone">
                      <Form.Label className="formTitle" style={small}>
                        Phone
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="+34 93 254 03 66"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="institution">
                      <Form.Label className="formTitle" style={small}>
                        Institution
                      </Form.Label>
                      <Form.Control type="text" placeholder="Institution" />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="country">
                      <Form.Label className="formTitle" style={small}>
                        Country
                      </Form.Label>
                      <Form.Control as="select">
                        <option value="Proactive" style={small}>
                          Please select your country
                        </option>

                        {country_list.map(item => (
                          <option value={item}>{item}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xs={12} className="pt-4 pb-2">
                    <h2 style={title}>Stimulation Parameters</h2>
                    <p style={smallbold} className="m-0">
                      Target data
                    </p>
                    <span
                      style={
                        fileEmpty
                          ? {
                            backgroundColor: "#d9534f",
                            display: "inline",
                            padding: ".2em .6em .3em",
                            fontSize: "75%",
                            fontWeight: 500,
                            lineHeight: 1,
                            color: "#fff",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            borderRadius: ".25em"
                          }
                          : {
                            backgroundColor: "#5cb85c",
                            display: "inline",
                            padding: ".2em .6em .3em",
                            fontSize: "75%",
                            fontWeight: 500,
                            lineHeight: 1,
                            color: "#fff",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            borderRadius: ".25em"
                          }
                      }
                    >
                      {fileEmpty ? "Missing" : "Successfully added"}
                    </span>
                    <h5 className="formTitle small mt-1">
                      Use the "Save" button in the Target Editor to download the
                      file and attach it.
                    </h5>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="files">
                      <Form.Label className="p-0" style={smallbold}>
                        Attach your file*
                      </Form.Label>
                      <Form.Control
                        required
                        className="inputfile"
                        style={{ border: "none" }}
                        ref={ref => setFileUpload(ref)}
                        onChange={e => setFileEmpty(false)}
                        label="Upload"
                        accept=".txt"
                        type="file"
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Device info*
                      </Form.Label>
                      <Form.Control as="select" required>
                        <option value="starstim8">Starstim 8</option>
                        <option value="starstim20">Starstim 20</option>
                        <option value="starstim32">Starstim 32</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Stimulation Cap Used*
                      </Form.Label>
                      <Form.Control as="select" required>
                        <option value="Standard Headcap (39 CH)">
                          Standard Headcap (39 CH)
                        </option>
                        <option value="Headcap Pro (64 CH)">
                          Headcap Pro (64 CH)
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        NIC Version*
                      </Form.Label>
                      <Form.Control as="select" required>
                        <option value="NIC 1">NIC 1</option>{" "}
                        <option value="NIC 2">NIC 2</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Type of stimulation*
                      </Form.Label>
                      <Form.Control as="select" required>
                        <option value="tDCS">tDCS</option>
                        <option value="tACS">tACS</option>
                        <option value="tRNS">tRNS</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Maximum current intensity at any electrode*:
                      </Form.Label>
                      <Form.Control type="text" placeholder="" />
                      <p className="small">
                        Please specify the maximum current intensity for any
                        electrode in mA (≤2 mA)
                      </p>
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Maximum Total Injected Current at any time*:
                      </Form.Label>
                      <Form.Control type="text" placeholder="" />
                      <p className="small">
                        Please specify the maximum total injected current
                        intensity in mA (≤4 mA)
                      </p>
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Desired number of electrodes*:
                      </Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Other considerations:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Write your message here"
                      />
                      <p className="small">
                        Describe in your own words the optimization problem.
                        E.g., are certain areas to be avoided? Is this a
                        multi-target problem? tDCS or tACS/tRNS? Is there a
                        restriction in the number of electrodes to use?
                      </p>
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pt-4 pb-2">
                    <h2 style={title}>Patient Information</h2>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="p-0" style={smallbold}>
                        Patient Identification Code*:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the Patient Identification Code"
                      />
                      <p className="small">
                        Please introduce the patient identification code. This
                        should be a label that uniquely identifies each patient
                        in your study. If you are requesting a single
                        optimization for the whole study/project, please insert
                        a label that identifies it.
                      </p>
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail" className="mb-0">
                      <Form.Label className="p-0" style={smallbold}>
                        Stimulation Due Date*:
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group controlId="formBasicEmail" className="mb-0">
                      <DatePicker
                        style={{
                          borderRadius: ".25rem",
                          width: "100%",
                          minWidth: "100%"
                        }}
                        borderRadius="125rem"
                        selected={date}
                        onChange={date => setDate(date)}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={12} className="pb-2">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="mb-3 mt-3"
                    >
                      <p className="small">
                        NEUROELECTRICS BARCELONA, S.L.U. collects and processes
                        personal data in accordance with the EU General Data
                        Protection Regulation (GDPR).
                      </p>
                    </Form.Group>
                  </Col>

                  <Col sm={6}>
                    <button type="submit" className="careersButton">
                      Send
                    </button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </div>
      </>
    </Fragment>
  )
}

export default TargetEditor
