import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import { getResources } from "../../../api/api";

import resourcesHome from "../../../contents/solutions_resources";
import ebooks from "../../../contents/ebooks";

import ActualPath from "../../../components/ActualPath";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import Banner from "../../../components/Banner";

const content = () => {
  let pdfsStack = [];
  resourcesHome.sections.map(item => {
    const i = item;
    item.pdfs &&
      item.pdfs.map(item => {
        const ref = item;
        ebooks.map(item => {
          ref === item._id && pdfsStack.push(item);
        });
      });
    item.pdfs && (item.pdf = pdfsStack);
  });
  return resourcesHome;
};

const Pdfs = ({ location }) => {

  const data = content();
  // const [data, setData] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {

    // async function fetchMyAPI() {
    //   try {
    //     const resources = await getResources();
    //     setData(resources);          
    //   }  catch (error) {
    //     console.log(error);
    //   }        
    // }    
    // fetchMyAPI();


    // setData(content);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => setWidth(window.innerWidth);

  const newImg = (img) => img.split(".")[0]+".webp";

  if (data != undefined) {
    const title =
      width < 575
        ? {
            fontWeight: 500,
            fontSize: "8vw",
            marginTop: 0
          }
        : width > 574 && width < 769
        ? {
            fontWeight: 500,
            fontSize: "4vw"
          }
        : width < 1025 && width > 768
        ? {
            fontWeight: 500,
            fontSize: "3vw"
          }
        : width < 1441 && width > 1024
        ? {
            fontWeight: 500,
            fontSize: "2.3vw"
          }
        : {
            fontWeight: 500,
            fontSize: "2vw"
          }

    const subtitle =
      width < 575
        ? {
            fontWeight: 300,
            fontSize: "4.7vw",
            lineHeight: "initial",
            marginTop: 0
          }
        : width > 574 && width < 769
        ? {
            fontWeight: 300,
            fontSize: "2.8vw",
            lineHeight: "initial"
          }
        : width < 1025 && width > 768
        ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial"
          }
        : width < 1441 && width > 1024
        ? {
            fontWeight: 300,
            fontSize: "1.5vw",
            lineHeight: "initial"
          }
        : {
            fontWeight: 300,
            fontSize: "1.1vw",
            lineHeight: "initial"
          }

    const small =
      width < 575
        ? {
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "initial",
            marginTop: 0
          }
        : width > 574 && width < 769
        ? {
            fontWeight: 300,
            fontSize: "2vw",
            lineHeight: "initial"
          }
        : width < 1025 && width > 768
        ? {
            fontWeight: 300,
            fontSize: "1.3vw",
            lineHeight: "initial"
          }
        : width < 1441 && width > 1024
        ? {
            fontWeight: 300,
            fontSize: "1vw",
            lineHeight: "initial"
          }
        : {
            fontWeight: 300,
            fontSize: "1vw",
            lineHeight: "initial"
          }

    const smallbold =
      width < 575
        ? {
            fontWeight: 500,
            fontSize: "16px",
            textDecoration: "none"
          }
        : width > 574 && width < 769
        ? {
            fontWeight: 500,
            textDecoration: "none",
            fontSize: "2.2vw"
          }
        : width < 1025 && width > 768
        ? {
            fontWeight: 500,
            fontSize: "1.5vw",
            textDecoration: "none"
          }
        : width < 1441 && width > 1024
        ? {
            fontWeight: 500,
            fontSize: "1.2vw",
            textDecoration: "none",
            lineHeight: "initial"
          }
        : {
            fontSize: "1.2vw",
            fontWeight: 500,
            textDecoration: "none",
            lineHeight: "initial"
          }

    return (
      <div style={{ paddingTop: width < 769 ? "" : "3.56vw" }}>
        {/* Helmet */}
        <>
          <Helmet>
            <title>Find out here our Resources | Neuroelectrics"</title>
            <meta
              name="twitter:title"
              content="Find out here our Resources | Neuroelectrics"
            />
            <meta
              property="og:title"
              content="Find out here our Resources | Neuroelectrics"
            />

            <meta
              name="description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              name="twitter:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <meta
              property="og:description"
              content="Download all our ebooks and whitepapers, read about our compelling success stories from our customers, browse our latest posts, discover our products via..."
            />
            <link
              rel="canonical"
              href="https://neuroelectrics.com/resources/library"
            />
          </Helmet>
        </>

        {/* Banner */}
        <>
          <Banner
            paddingTop="0"
            mobilePadding="0vw 10vw"
            width={width}
            backgroundWhite={width < 426 && true}
            responsiveBannerBackgroundPaddingLeft={width < 426 && "16%"}
            heightResponsive={width > 425 && "25vw"}
            justifyDiv="center"
            backgroundPadding={
              width < 575
                ? "center"
                : width > 574 && width < 769
                ? "-30vw"
                : width < 1025 && width > 768
                ? "center"
                : width < 1441 && width > 1024
                ? "center"
                : "center"
            }
            textsPaddingRight={
              width < 575
                ? "0"
                : width > 574 && width < 769
                ? "32%"
                : width < 1025 && width > 768
                ? "22%"
                : width < 1441 && width > 1024
                ? "22%"
                : "22%"
            }
            title={data.banner.title}
            titleFontSize={
              width < 575
                ? "5.8vw"
                : width > 574 && width < 769
                ? "4vw"
                : width < 1025 && width > 768
                ? "2.5vw"
                : width < 1441 && width > 1024
                ? "2.5vw"
                : "2.5vw"
            }
            titleFontWeight="500"
            subtitle={data.banner.subtitle}
            subtitleFontSize={
              width < 575
                ? "4.1vw"
                : width > 574 && width < 769
                ? "2.6vw"
                : width < 1025 && width > 768
                ? "1.7vw"
                : width < 1441 && width > 1024
                ? "1.4vw"
                : "1.4vw"
            }
            subtitleFontWeight="300"
            img={isWebpSupported() ? `/img/banners/resources.webp` : `/img/banners/resources.jpg`}
          />
        </>

        {/* Actual Path */}
        <>
          <div
            style={
              width < 575
                ? {
                    paddingLeft: "1vw"
                  }
                : {}
            }
          >
            <ActualPath
              resources
              levelOne={{ text: "Home", url: "/" }}
              levelTwo={{ text: "Resources", url: "/resources" }}
              actual={{ text: "Library" }}
            />
          </div>
        </>
        {/* Items */}
        <>
          <Container
            fluid
            style={
              width > 575 ? { paddingLeft: "15vw", paddingRight: "15vw" } : {}
            }
          >
            <h1 style={title} className="pt-4 pb-4 pl-3">
              Find out here our Ebooks, Whitepapers & Case Studies
            </h1>

            <Row className="pl-4 pr-4">
              {data.sections[1].pdf.map((item, index) => (
                <Col
                  xl={3}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={6}
                  className={width < 575 ? "mb-4" : "m-0"}
                  style={{
                    padding:
                      width > 575 && width < 1024
                        ? ".7%"
                        : width < 1441 && width > 1023
                        ? "1%"
                        : "1%"
                  }}
                >
                  <div
                    className="d-flex flex-column align-items-start "
                    style={{
                      background: "#fff",
                      border: "1px solid #fff",
                      height: "100%"
                    }}
                  >
                    <img
                      className="w-100"
                      src={isWebpSupported() ? `/img/ebooks/${newImg(item.image.path)}` : `/img/ebooks/${item.image.path}`}
                      alt={item.image.path}
                    />
                    <div className="d-flex flex-column align-items-start justify-content-between h-100">
                      <p style={smallbold} className="pl-3 pr-3 pt-3">
                        {item.title}
                      </p>
                      <p style={small} className="pl-3 pr-3">
                        {item.subtitle}
                      </p>

                      <div
                        className="d-flex flex-column align-items-center justify-content-end w-100 border-0 pb-3"
                        style={width > 575 ? { height: "100%" } : {}}
                      >
                        <Button
                          width="10vw"
                          marginHorizontal="2vw"
                          marginVertical="2%"
                          padding={
                            width > 1441
                              ? "10px 15px"
                              : width < 575
                              ? "10px 20px"
                              : "15px 25px"
                          }
                          radius={width > 1441 && "50px"}
                          font={
                            width < 575
                              ? "3.8vw"
                              : width > 574 && width < 769
                              ? "2vw"
                              : width < 1025 && width > 768
                              ? "1.6vw"
                              : width < 1441 && width > 1024
                              ? "1.2vw"
                              : "1.1vw"
                          }
                          shadow
                          url={item.url + item._id}
                          refe={item._id}
                          text="Read more"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </>
        {/* Featured */}
        <>
          <div style={{ background: "white" }}>
            <Container
              fluid
              style={
                width > 575
                  ? {
                      paddingTop: "2%",
                      paddingBottom: "2%",
                      paddingLeft: "16vw",
                      paddingRight: "16vw"
                    }
                  : {
                      paddingTop: "5%",
                      paddingBottom: "2%"
                    }
              }
            >
              <Row>
                {data.featured.map((item, index) => {
                  var i = index
                  return (
                    item.articles.length > 0 && (
                      <Col md={4} lg={4}>
                        <h4>{item.title}</h4>
                        {item.articles.map((item, index) =>
                          i < 2 ? (
                            <div className="mb-4">
                              >
                              <a
                                target="_blank"
                                href={item.url}
                                rel="noopener noreferrer"
                                style={{
                                  color: "#333",
                                  textDecoration: "underline"
                                }}
                              >
                                {item.title}
                              </a>
                            </div>
                          ) : (
                            <div className="mb-4">
                              <p>{item.title}</p>
                              <iframe
                                title={`resource-${index}`}
                                style={{ width: "90%", height: "100%" }}
                                src={item.url}
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </div>
                          )
                        )}
                      </Col>
                    )
                  )
                })}
              </Row>
            </Container>
          </div>
        </>
      </div>
    )
  } else {
    return <Loading />
  }
}

export default Pdfs
