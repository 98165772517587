import React, { useState } from "react"
import HubspotForm from "react-hubspot-form"
import axios from "axios"

import "./form.css"

let hsSent = false;
const submitted = () => {
  hsSent = true;
}

const Form = ({ type, portalId, formId, className }) => {
  const [thanks, setThanks] = useState(false)
  const [fileUpload, setFileUpload] = useState()


  let submitForm = {};

  const send = form => {
    let country
    form.map(item => {
      return item.name === "country" && (country = item.value)
    })

    // Support attach file
    const files = new FormData()
    files.append("file", fileUpload)

    type === "support"
      ? axios
        .post(
          `${process.env.REACT_APP_API_URL}/formsupport/${form[0].value}/${form[1].value}/${form[2].value}/${form[3].value}/${form[4].value}/${form[5].value}/${form[6].value}/${form[7].value}/${form[8].value}/${form[9].value}/${form[10].value}/${form[11].value}/${form[12].value}`,
          files
        )
        .then(res => setThanks(true))
        .catch(err => console.log(err))
      : axios
        .post(`${process.env.REACT_APP_API_URL}/form`, {
          country,
          form: form,
          type: type
        })
        .then(res => setThanks(true))
        .catch(err => console.log(err))

    setTimeout(() => {
      if (!hsSent) {
        axios.post(`${process.env.REACT_APP_API_URL}/send-emails`, {
          form: form,
          type: type
        }).then(res => res.data);
      }
    }, 20000)

  }

  const onReady = () => {
    let a = document.getElementById(
      `attachments-${process.env.REACT_APP_SUPPORT_HBSPT_FORM_ID}`
    )

    if (a !== null && a !== undefined) {
      a.onchange = function (e) {
        setFileUpload(a.files[0])
      }
    }
  }

  return (
    <div className={className} style={{ width: "100%", padding: 0 }}>
      <HubspotForm
        portalId={portalId}
        formId={formId}
        loading={<div>Loading...</div>}
        onFormSubmitted={() => submitted()}
        onSubmit={form => {
          submitForm = form
          send(submitForm)
        }}
        onReady={() => onReady()}
      />
      {thanks && (
        <div className="alert alert-success">
          <button type="button" className="close" data-dismiss="alert">
            <span className="sr-only">Close</span>
          </button>
          Thank you! Neuroelectrics® team will get in contact with you.
        </div>
      )}
    </div>
  )
}

export default Form
