import React, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {isWebpSupported} from 'react-image-webp/dist/utils';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { downloadManuals } from "../../api/api";

import Banner from "../../components/Banner";
import Loading from "../../components/Loading";
import Button from "../../components/Button";

import "./NibsLanding.css";
import nibs_landing from "../../contents/nibs_landing";
import ebooks from "../../contents/ebooks";
import resources from "../../contents/resources";

const ButtonStyled = styled.button`
  font-family: Open Sans;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  padding: 3%;
  margin: 1%;
  border-bottom: 1px solid grey;
  border-left: none;
  border-top: none;
  border-right: none;
  transition: ease-in-out opacity 0.2s;

  &:hover {
    opacity: 0.4;
  }

  &:focus {
    opacity: 0.6;
    outline: none;
  }
`

const newImg = (img) => img.split(".")[0]+".webp";

const content = () => {    
  let resources = [];
  ebooks.map(item => {    
    nibs_landing.ebooks.reference_ids.map(el => {      
      el === item._id && resources.push(item);
      return el;
    });  
    return item;  
  });
  nibs_landing.ebooks.ebooks_items = resources;  
  return nibs_landing;
}

const NibsLanding = () => {
  
  const data = content();  
  const [width, setWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(false)  
  
  useEffect(() => {   

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  let oneItemsWidth
  width > 1024
    ? (oneItemsWidth = "18%")
    : width < 1025 && width > 991
    ? (oneItemsWidth = "21%")
    : width < 992 && width > 575
    ? (oneItemsWidth = "34%")
    : width < 575 && width > 526
    ? (oneItemsWidth = "46.9%")
    : width < 527 && width > 510
    ? (oneItemsWidth = "46.8%")
    : width < 509 && width > 356
    ? (oneItemsWidth = "45%")
    : width < 350 && (oneItemsWidth = "40%")

  const title =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "8vw",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          fontSize: "4vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "3vw"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "2.3vw"
        }
      : {
          fontWeight: 500,
          fontSize: "2vw"
        }

  const subtitle =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "4.7vw",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2.8vw",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1.5vw",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1.3vw",
          lineHeight: "initial",
          wordBreak: "break-word"
        }

  const small =
    width < 575
      ? {
          fontWeight: 300,
          fontSize: "14px",
          color: "#333",
          lineHeight: "initial",
          marginTop: 0
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 300,
          fontSize: "2vw",
          color: "#333",
          lineHeight: "initial"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 300,
          fontSize: "1.3vw",
          color: "#333",
          lineHeight: "initial"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 300,
          fontSize: "1vw",
          color: "#333",
          lineHeight: "initial"
        }
      : {
          fontWeight: 300,
          fontSize: "1vw",
          color: "#333",
          lineHeight: "initial"
        }

  const smallbold =
    width < 575
      ? {
          fontWeight: 500,
          fontSize: "16px",
          color: "#333",
          textDecoration: "none"
        }
      : width > 574 && width < 769
      ? {
          fontWeight: 500,
          textDecoration: "none",
          color: "#333",
          fontSize: "2.2vw"
        }
      : width < 1025 && width > 768
      ? {
          fontWeight: 500,
          fontSize: "1.5vw",
          color: "#333",
          textDecoration: "none"
        }
      : width < 1441 && width > 1024
      ? {
          fontWeight: 500,
          fontSize: "1.2vw",
          textDecoration: "none",
          color: "#333",
          lineHeight: "initial"
        }
      : {
          fontSize: "1.2vw",
          fontWeight: 500,
          textDecoration: "none",
          color: "#333",
          lineHeight: "initial"
        }

  let boxHeight
  width < 575
    ? (boxHeight = "51vw")
    : width > 574 && width < 769
    ? (boxHeight = "40vw")
    : width > 768 && width < 1025
    ? (boxHeight = "30vw")
    : width > 1024 && width < 1441
    ? (boxHeight = "25vw")
    : width > 1440 && width < 2200
    ? (boxHeight = "18vw")
    : width > 2200 && width < 2400
    ? (boxHeight = "16vw")
    : (boxHeight = "14vw")

  let videoStyle;
  width > 575 && width < 850 
  ? (videoStyle = { height: "70vh", margin: "0 30px 0 0", padding: "2%" })
  : (videoStyle = { height: "35vh", marginTop: "30px", paddingLeft: "2%" })

  const video = 
    <iframe
      title="banner_video"
      className="w-100"
      style={videoStyle}                                
      src={data.banner.youtube}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
      allowFullScreen={true}
    />   
  

  return !loading ? (
    <div>
      <Helmet>
        <title>Neuroelectrics stand at the 7th International Conference on Non-invasive Brain Stimulation | Neuroelectrics</title>
        <meta name="twitter:title" content="Reinventing brain health" />
        <meta property="og:title" content="Reinventing brain health" />

        <meta
          name="description"
          content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
        />
        <meta
          name="twitter:description"
          content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
        />
        <meta
          property="og:description"
          content="Neuroelectrics® manufacturers the StarStim a neuro-stimulator using the Transcranial Direct Current Stimulation (tDCS) for the treatment of pain and post-st..."
        />            
        <script src="//code.jivosite.com/widget/X5npNbOcsf" async></script>
      </Helmet>
     <div>
      <Banner
          paddingTop="3.6vw"
          mobilePadding="3% 2% 6% 2%"
          width={width}
          backgroundWhite={width < 426 && true}
          responsiveBannerBackgroundPaddingLeft={width < 426 ? "16%" : "5%"}
          heightResponsive={width > 1100 && "35vw"}
          justifyDiv="center"
          backgroundPadding={
            width < 575
              ? "center"
              : width > 574 && width < 769
              ? "0"
              : width < 1025 && width > 768
              ? "center"
              : width < 1441 && width > 1024
              ? "center"
              : "center"
          }
          textsPaddingRight={
            width < 575
              ? "0"
              : width > 574 && width < 769
              ? "0"
              : width < 1025 && width > 768
              ? "0"
              : width < 1441 && width > 1024
              ? "0"
              : "0"
          }
          divWidth="95%"
          title={data.banner.title}
          titleFontSize={
            width < 575
              ? "5.8vw"
              : width > 574 && width < 769
              ? "4vw"
              : width < 1025 && width > 768
              ? "2vw"
              : width < 1441 && width > 1024
              ? "2vw"
              : "2vw"
          }
          titleFontWeight="500"
          landingSubtitle={data.banner.subtitle}
          subtitleFontSize={
            width < 575
              ? {
                  fontWeight: 300,
                  fontSize: "4.7vw",
                  lineHeight: "initial",
                  marginTop: 0
                }
              : width > 574 && width < 769
              ? {
                  fontWeight: 300,
                  fontSize: "2.8vw",
                  lineHeight: "initial"
                }
              : width < 1025 && width > 768
              ? {
                  fontWeight: 300,
                  fontSize: "2vw",
                  lineHeight: "initial"
                }
              : width < 1441 && width > 1024
              ? {
                  fontWeight: 300,
                  fontSize: "1.5vw",
                  lineHeight: "initial"
                }
              : {
                  fontWeight: 300,
                  fontSize: "1vw",
                  lineHeight: "initial"
                }
          }
          subtitleFontWeight="400"
          landing
          landingImage={data.banner.image}
          video={data.banner.youtube}
          landingButton={data.banner.button}
          team={data.banner.team}          
        />
      </div>
      
      <div
        className={width < 575 ? "p-0" : "p-5"}
        style={{ background: "#fff"}}
      >
        {width > 575 && width < 850 && 
          <div>
            {video}            
          </div> }
        {width < 575 && 
          <div style={{ marginTop: "-20px", padding: "5%" }}>    
            <h2 className="responsiveBannerSubtitle">{data.banner.subtitle}</h2>
            {video}            
            <div style={{display: "block", margin: "0 auto", padding: "7vw 17vw"}}>            
              <Button
                text={data.banner.button.text}
                js={data.banner.button.js}                      
                width="15vw"                      
                font="5vw"
                fontWeight="500"                  
              />    
            </div>                      
                  {data.banner.team.map((item, index) => 
                    <div key={index} style={{display: "flex", flexDirection: "row", marginLeft: "20px"}}>                      
                      <img
                        style={{ borderRadius: '30px', width: '60px', height: '60px', margin: '15px' }}                        
                        src={isWebpSupported() ? `/img/team/${newImg(item.img.path)}` : `/img/team/${item.img.path}`}
                        alt={item.img.title}
                      />                      
                      <p style={{margin: "30px", textAlign: "left", fontSize: "5vw"}}>{item.name}</p>                 
                    </div>                  
                  )}
            </div>}
        <Container>
          <Row>
            <div className={width < 575 ? "p-4" : "p-2"} style={{ zIndex: "10" }}>
              <h2 style={title}>Discover our solutions and services</h2>
              <h5 style={subtitle}>
                Our unique technology: personalised brain therapy research
              </h5>
            </div>
          </Row>
          <Row className="row-eq-height">
            {data.buttons.items.map((item, i) => (
              <Col key={i} sm={12} xs={12} md={6} lg={3} className="p-0">
                {width < 575 && i === 0 && (
                  <div
                    style={{
                      borderBottom: "1px solid lightgray",
                      bordertop: i === 0 && "1px solid lightgray",
                      height: "1px"
                    }}
                  ></div>
                )}
                <Link
                  to={item.to}
                  className={
                    width < 575
                      ? `${i % 2 == 1 && "flex-row-reverse"}`
                      : "box h-100 m-2"
                  }
                  style={{
                    background: "#f7f6f5",
                    border: "none !important",
                    height: "100%",
                    display: width < 575 ? "flex" : ""
                  }}
                >
                  <img
                    src={isWebpSupported() ?  `/img/${newImg(item.image.path)}` : `/img/${item.image.path}`}
                    alt={item.image.title}
                    className={width < 575 ? "w-50 h-100" : "w-100"}
                  />
                  <div style={{ background: "#fff", height: "5px" }}></div>
                  <div
                    className="mt-2 pl-3 pr-3 pt-0 pb-0 d-flex flex-column"
                    style={{
                      minHeight: "9vw",
                      width: width < 575 ? "100%" : ""
                    }}
                  >
                    <h5 style={subtitle} className="mt-0 d-flex flex-column">
                      <span style={{ color: "#555", fontSize: "95%" }}>
                        <b>{item.title}</b>
                      </span>
                    </h5>

                    <p style={{ fontWeight: 300 }} className="pt-2 pb-4">
                      {item.subtitle && item.subtitle}
                    </p>
                    <div
                      className="d-flex align-items-center"
                      style={
                        width < 575
                          ? {}
                          : {
                              position: "absolute",
                              bottom: 0,
                              left: "6%",
                              right: 0
                            }
                      }
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          margin: 0
                        }}
                      >
                        Get info{"    "}
                      </p>
                      <span
                        style={{
                          color: "#7a92a4",
                          fontWeight: "400",
                          fontSize: "2em",
                          marginBottom: "2%",
                          marginLeft: "2.2%"
                        }}
                      >
                        ›
                      </span>
                    </div>
                  </div>
                </Link>
                {width < 575 && (
                  <div
                    style={{
                      borderBottom: "1px solid lightgray",
                      bordertop: i === 0 && "1px solid lightgray",
                      height: "1px"
                    }}
                  ></div>
                )}
              </Col>
            ))}
          </Row>
        </Container>        
      
        <Container
            fluid
            style={
              width < 575
                ? { padding: 0 }
                : { paddingLeft: '12vw', paddingRight: '12vw', marginTop: '3vw' }
            }
          >        
              <div                
                style={{
                  background: '#fff',
                  padding: width < 575 ? '3%' : '3%',
                  position: 'relative',                  
                }}
              >
                {width < 575 && (
                  <Container
                    className="p-0"
                    fluid
                    style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
                  >
                    {width > 575 ? (
                      <img
                        style={{ height: '20px' }}
                        className="d-flex w-100"
                        src={isWebpSupported() ? require('../../assets/img/neurocience_.webp') : require('../../assets/img/neurocience_.png')}
                        alt="neuroelectrics-asset-bar"
                      />
                    ) : (
                      <div
                        style={{
                          borderBottom: '3px solid #555',
                          height: '1px'
                        }}
                      ></div>
                    )}
                  </Container>
                )}

                <div
                  className={`d-flex ${width < 575 &&
                    'justify-content-between'}`}
                >
                  <h2
                    style={{
                      color: '#333',
                      fontWeight: '600',
                      fontSize: width > 575 ? '2.1em' : '1.5em',
                      paddingBottom: '1%',
                      paddingTop: width < 575 && '2%'
                    }}
                    id={resources.manuals.categories[5].title}
                  >
                    {resources.manuals.categories[5].title}
                  </h2>

                  {resources.manuals.categories[5].date && (
                    <a
                      data-tip={resources.manuals.categories[5].date}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: '1%',
                        paddingTop: width < 575 && '2%',
                        marginBottom: '.3rem',
                        paddingLeft: '1%'
                      }}
                    >
                      <img src={isWebpSupported() ? "/img/information.webp" : "/img/information.png"} 
                      width={20} height={20} />
                      <ReactTooltip place="bottom" type="dark" effect="float" />
                    </a>
                  )}
                </div>

                <Row className={width > 575 ? '' : ''}>
                  {resources.manuals.categories[5].items.map((item, index) => (
                    index > 0 && (
                      <Col
                        key={index}
                        xl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={width < 575 ? { padding: 0 } : { padding: 0 }}
                      >
                                            
                      <ButtonStyled
                        onClick={() => downloadManuals(item.file)}
                        style={
                          width < 575
                            ? {
                                width: '100%',
                                borderTop: '2px solid #f7f6f5',
                                borderBottom: 'none'
                              }
                            : {
                                width: '80%',
                                borderBottom: 'none',
                                paddingTop: 0,
                                paddingBottom: 0
                              }
                        }
                      >
                        <img
                          src={require('../../assets/img/ico_download.svg').default}
                          alt="resources-user-manuals"
                          style={{
                            padding: '1%',
                            width: width < 575 ? '20%' : '90px',
                            height: width < 575 ? '20%' : '90px'
                          }}
                        />
                        <div className="d-flex flex-column align-items-center justify-content-start text-start">
                          <p
                            style={width < 575 ? smallbold : subtitle}
                            className="w-100 text-left m-0 pl-2"
                          >
                            {item.title}
                          </p>
                          <p style={small} className="w-100 text-left m-0 pl-2">
                            {width > 575 ? (
                              <b>Neuroelectrics®</b>
                            ) : (
                              'Neuroelectrics®'
                            )}
                          </p>
                        </div>
                      </ButtonStyled>
                    </Col>)
                  ))}
                </Row>
              </div>
        </Container>
      </div> 

      <div
        className={width < 575 ? "pt-4 pb-4" : "p-5"}
        style={{ background: "#f7f6f5" }}
      >
        
        <Container
            fluid
            style={
              width > 575 ? { paddingLeft: "15vw", paddingRight: "15vw" } : {}
            }
          >
            <h2 style={title} className="pt-4 pb-4 pl-3">
              {data.ebooks.title}
            </h2>

            <Row className="pl-4 pr-4">
              {data.ebooks.ebooks_items.map((item, index) => (
                <Col
                  key={index}
                  xl={3}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={6}
                  className={width < 575 ? "mb-4" : "m-0"}
                  style={{
                    padding:
                      width > 575 && width < 1024
                        ? ".7%"
                        : width < 1441 && width > 1023
                        ? "1%"
                        : "1%"
                  }}
                >
                  <div
                    className="d-flex flex-column align-items-start "
                    style={{
                      background: "#fff",
                      border: "1px solid #fff",
                      height: "100%"
                    }}
                  >
                    <img
                      className="w-100"
                      src={isWebpSupported() ? `/img/ebooks/${newImg(item.image.path)}` : `/img/ebooks/${item.image.path}`}
                      alt={item.image.path}
                    />
                    <div className="d-flex flex-column align-items-start justify-content-between h-100">
                      <p style={smallbold} className="pl-3 pr-3 pt-3">
                        {item.title}
                      </p>
                      <p style={small} className="pl-3 pr-3">
                        {item.subtitle}
                      </p>

                      <div
                        className="d-flex flex-column align-items-center justify-content-end w-100 border-0 pb-3"
                        style={width > 575 ? { height: "100%" } : {}}
                      >
                        <Button
                          width="10vw"
                          marginHorizontal="2vw"
                          marginVertical="2%"
                          padding={
                            width > 1441
                              ? "10px 15px"
                              : width < 575
                              ? "10px 20px"
                              : "15px 25px"
                          }
                          radius={width > 1441 && "50px"}
                          font={
                            width < 575
                              ? "3.8vw"
                              : width > 574 && width < 769
                              ? "2vw"
                              : width < 1025 && width > 768
                              ? "1.6vw"
                              : width < 1441 && width > 1024
                              ? "1.2vw"
                              : "1.1vw"
                          }
                          shadow
                          url={item.url + item._id}
                          refe={item._id}
                          text="Read more"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div
          className={width < 575 ? "pt-4 pb-4" : "p-5"}
          style={{ background: "#fff" }}
        >  
        <Container>
          <Row>
            {data.images.map((item, index) => (
              <Col
                key={index}
                lg={3}
                md={3}
                sm={6}
                xs={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div                  
                  className={
                    width < 575
                      ? "d-flex justify-content-center align-items-center"
                      : ""
                  }
                >
                  <img
                    className={width < 575 ? "w-75" : "w-100 p-5"}
                    src={isWebpSupported() ? `/img/homeschools/${newImg(item.src)}` : `/img/homeschools/${item.src}`}
                    alt={item.alt}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default NibsLanding
